import React from 'react';
import BetLocked from "../BetLocked/BetLocked";

export default function Card(props) {
    const { Data, num, handleBackOpen, posArray, section1Ref } = props;
    return (
        <div className="w-full">
            <div className="relative flex justify-center items-center">
                <div onClick={() => handleBackOpen(Data, section1Ref)} className="h-[75px] w-14 cursor-pointer">
                    <img src={`/casino-images/${num}.jpg`} alt="aaaaaa" />
                </div>
                {Data.gstatus === "1" ? null : <BetLocked />}
            </div>
            <p className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Data.sid] ?? 0.00}</p>
        </div>
    );
}