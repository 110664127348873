import React, { useEffect, useState } from "react";

const FreeGames = () => {
    const [isTvScreen, setIsTvScreen] = useState(false);
    const [domainSettingByDomainName, setdomainSettingByDomainName] = useState()
    const handleTvScreen = () => {
        setIsTvScreen(!isTvScreen);
    }
    useEffect(() => {
        const getDomainSettingByDomainName = () => {
          try {
            let domainSetting = localStorage.getItem('domainSettingByDomainName');
            if (domainSetting) {
              const parsedDomainSetting = JSON.parse(domainSetting);
              setdomainSettingByDomainName(parsedDomainSetting);
            } else {
              setdomainSettingByDomainName(null);
            }
          } catch {
            console.error('Error parsing domainSettingByDomainName:');
            setdomainSettingByDomainName(null);
          }
        };
    
        getDomainSettingByDomainName();
      }, []);

    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    return (
        <div className={` overflow-auto h-screen ${domainSettingByDomainName && domainSettingByDomainName.clientNotification  ? "md:pt-[105px] pt-[100px]" : "md:pt-[82px] pt-[80px]"}`}>
            <div className="flex justify-center items-center">
                <button onClick={handleTvScreen} className="flex justify-center items-center"><img src="/images/free-game.jpg" alt="Free Games" className="md:w-[50%] w-[100%] md:h-[550px] h-full" /></button>

            </div>
            {isTvScreen ?
                <div className="pb-20">
                    <iframe src="https://doodlecricket.github.io/#/" title=" " loading='lazy' className="lg:w-[50%] mx-auto w-[100%] lg:h-[400px] h-[300px]" />
                </div> : null
            }
        </div>
    );
};

export default FreeGames;
