import React, {useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTimes } from 'react-icons/fa';

const RulesModelBmx = (props) => {
    const { setRulesModal, PageTitle, ruleImage } = props;
    const handleCloseModal = () => {
        setRulesModal(false)
    }

    useEffect(() => {
        document.addEventListener('click', handleCloseModal);
        return () => {
            document.removeEventListener('click', handleCloseModal);
        };
    }, []);

    return (

        <div className={`w-full z-50 fixed px-2 h-screen bg-black/30 top-0 left-0 overflow-y-auto `} onClick={() => handleCloseModal()}>
            <div className="w-full lg:w-1/4 bg-white z-50 mx-auto top-4" onClick={(e) => e.stopPropagation()}>
                <div className="w-full h-full flex justify-between detail-header p-2 items-center">
                    <h2 className="text-white text-[16px] font-medium">
                        {PageTitle} Result
                    </h2>
                    <div className='text-center' onClick={() => setRulesModal()}>
                        <FaTimes className='text-white cursor-pointer' size={20} />
                    </div>
                </div>
                <div className='w-full'>
                    <img src={ruleImage} alt={PageTitle} className='' />
                </div>

            </div>
        </div>
    );
}

export default RulesModelBmx;