import React, { Component } from "react";
import moment from 'moment';
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";

class LegderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLedgerDetails: {},
            currentPage: 1,
            itemsPerPage: 10,
            errorType: "",
            showAlert: false,
            resMessage: "",
            isFetch: false,
            domainSettingByDomainName: null,
        }
    }


    componentDidMount() {
        this.getUserLedger();
        this.getDomainSettingByDomainName();
    }

    getDomainSettingByDomainName = () => {
        try {
          let domainSetting = localStorage.getItem('domainSettingByDomainName');
          if (domainSetting) {
            const parsedDomainSetting = JSON.parse(domainSetting);
            this.setState({ domainSettingByDomainName: parsedDomainSetting });
          } else {
            this.setState({ domainSettingByDomainName: null });
          }
        } catch {
          console.error('Error parsing domainSettingByDomainName:');
          this.setState({ domainSettingByDomainName: null });
        }
      };

    getUserLedger = async () => {
        let reqData = {
            marketId: this.props.match.params.marketId + ""
        }
        this.setState({ isFetch: true })
        let userLedgerDetails = await httpPost('user/completeLedgerDetails', reqData);
        if (userLedgerDetails) {
            this.setState({ userLedgerDetails: userLedgerDetails.data });
        } else {
            this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
        }
        this.setState({ isFetch: false });
        setTimeout(() => this.setState({ showAlert: false }), 2000);
    }

    onClickMenu = (url) => {
        this.props.history.push(url);
    }

    render() {
        let { userLedgerDetails, resMessage, errorType, showAlert, isFetch ,domainSettingByDomainName} = this.state;
        let { completeData, oddsBetsData, sessionBetsData, sessionTurnOver } = userLedgerDetails;
        let { clientOddsAmount, clientOddsComm, clientSessionAmount, clientSessionComm, isDeclare } = completeData ? completeData : {};
        // let clientNotification = JSON.parse(localStorage.getItem('notification'))
        return (
            <div className={` overflow-auto h-screen md:w-full mx-auto lg:pb-0 pb-24 ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[97px] pt-[97px]" : "md:pt-[72px] pt-[72px]"}`}>
                {isFetch === true ?
                    <Loader />
                    : null}
                {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}


                <div className="lg:w-[61%] w-full  mx-auto">
                    {
                        (oddsBetsData && oddsBetsData.length > 0) && isDeclare ?
                            <>
                                <div className='bg-[#7d5c0e] mb-2 text-sm white-text text-center py-1 font-extrabold'>
                                    <span>MATCH BETS</span>
                                </div>
                                <div className='w-full overflow-x-auto scrollbar-hide'>
                                    <table className="w-full text-sm">
                                        <thead className="rounded-t background-color">
                                            <tr className='white-text text-center font-bold '>
                                                <th className="p-0.5 w-[30%]">TEAM</th>
                                                <th className="p-0.5 w-[25%]">DATE</th>
                                                <th className="p-0.5 px-2 w-[10%]">RATE</th>
                                                <th className="p-0.5 w-[15%]">AMOUNT</th>
                                                <th className="p-0.5 w-[10%]">MODE</th>
                                                <th className="p-0.5 w-[10%]">P&L</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {oddsBetsData && oddsBetsData ?
                                                <>
                                                    {oddsBetsData.map((element, index) =>
                                                        <tr key={index} className=" background-white text-[14px] font-medium text-[#49494A]">
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-start w-[30%]">{element.teamName}</td>
                                                            <td className="truncate text-start border-[1px] border-[#3d8282]  w-[25%] ">
                                                                {element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A") : ""}

                                                                {/* {moment(element.createdAt).format('DD-MM-YYYY :mm A')} */}
                                                            </td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282]  w-[10%] px-2">{element.odds}</td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[15%]">{Number.parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}
                                                            </td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[10%]">{element.type === "L" ? "LAGAI" : "KHAI"}
                                                            </td>
                                                            <td className={`whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[10%] ${element.profitLoss > 0 ? "text-[#5199E6]" : "text-[#DC143C]"}`} >
                                                                {Number.parseFloat(element.profitLoss).toFixed(2).replace(/\.?0+$/, '')}
                                                            </td>
                                                            <marquee className="w-full font-bold red-text  border-[#3D8282]">
                                                                {element.deletedRemark}
                                                            </marquee>
                                                        </tr>)}
                                                </> : null
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </> : null
                    }
                    {
                        sessionBetsData && sessionBetsData.length > 0 ?
                            <>
                                <div className='bg-[#DC143C] text-sm white-text text-center py-1 font-extrabold flex justify-between items-center'>
                                    <span className="w-full"></span>
                                    <span className="w-full">FANCY BETS</span>
                                    <span className="text-xs w-full text-right">(Fancy Turnover:  {Number.parseFloat(sessionTurnOver).toFixed(2).replace(/\.?0+$/, '')})</span>
                                </div>
                                <div className='w-full overflow-x-auto scrollbar-hide'>
                                    <table className="w-full text-sm">
                                        <thead className="rounded-t background-color">
                                            <tr className='white-text text-center font-bold '>
                                                <th className="p-0.5 w-[30%]">RUNNER</th>
                                                <th className="p-0.5 w-[26%]">DATE</th>
                                                <th className="p-0.5 px-2 w-[8%]">RATE</th>
                                                <th className="p-0.5 px-2 w-[8%]">RUN</th>
                                                <th className="p-0.5 w-[8%]">RESULT</th>
                                                <th className="p-0.5 w-[12%]">AMOUNT</th>
                                                <th className="p-0.5 w-[8%]">MODE</th>
                                                <th className="p-0.5 w-[8%]">P&L</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {sessionBetsData && sessionBetsData ?
                                                <>
                                                    {sessionBetsData.map((element, index) =>
                                                        <tr key={index} className={`${element.isDeleted === 0 ? "background-white text-[#49494A]" : "change-password-button text-white"}  text-[14px] font-medium `}>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-start w-[30%]">{element.sessionName}</td>
                                                            <td className="truncate text-start border-[1px] border-[#3d8282]  w-[26%]" >
                                                                {element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD MMM hh:mm:ss A") : ""} </td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282]  w-[8%] px-2">{element.odds}</td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282]  w-[8%] px-2">{element.run}</td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[8%]">{element.decisionRun}
                                                            </td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[12%]">
                                                                {Number.parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}
                                                            </td>
                                                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[8%]">{element.type === "N" ? "NOT" : "YES"}
                                                            </td>
                                                            <td className={`whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[8%] ${element.profitLoss > 0 ? "text-[#5199E6]" : "text-[#DC143C]"}`} >
                                                                {Number.parseFloat(element.profitLoss).toFixed(2).replace(/\.?0+$/, '')}
                                                            </td>
                                                            <marquee className="w-full font-bold red-text  border-[#3D8282]">
                                                                {element.deletedRemark}
                                                            </marquee>
                                                        </tr>)}
                                                </> : null
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </> : null
                    }

                    <div className="pt-5">
                        <div className=' background-color text-sm white-text text-center p-1 font-bold'>
                            <span>Match Plus Minus</span>
                        </div>

                        {clientOddsAmount > 0 ?
                            <div className='background-white text-[#5199E6] text-sm text-center py-1 font-semibold'>
                                <span>You Won {clientOddsAmount ? Number.parseFloat(clientOddsAmount).toFixed(2).replace(/\.?0+$/, '') : 0}/- Coins.</span>
                            </div> :
                            <div className='background-white text-[#DC143C] text-sm text-center py-1 font-semibold'>
                                <span>You Lost {clientOddsAmount ? Number.parseFloat(clientOddsAmount).toFixed(2).replace(/\.?0+$/, '') : 0}/- Coins.</span>
                            </div>
                        }
                    </div>
                    <div className="">
                        <div className=' background-color text-sm white-text text-center p-1 font-bold'>
                            <span>Fancy Plus Minus</span>
                        </div>
                        {clientSessionAmount > 0 ?
                            <div className='background-white text-[#5199E6] text-sm text-center py-1 font-semibold'>
                                <span>You Won {clientSessionAmount ? Number.parseFloat(clientSessionAmount).toFixed(2).replace(/\.?0+$/, '') : 0}/- Coins.</span>
                            </div> :
                            <div className='background-white text-[#DC143C] text-sm text-center py-1 font-semibold'>
                                <span>You Lost {clientSessionAmount ? Number.parseFloat(clientSessionAmount).toFixed(2).replace(/\.?0+$/, '') : 0}/- Coins.</span>
                            </div>
                        }
                    </div>
                    <div className="">
                        <div className=' background-color text-sm white-text text-center p-1 font-bold'>
                            <span>Total Commission</span>
                        </div>
                        {(clientOddsComm + clientSessionComm) > 0 ?
                            <div className='background-white text-[#5199E6] text-sm text-center py-1 font-semibold'>
                                <span>You Won {Number.parseFloat((clientOddsComm ?? 0) + (clientSessionComm ?? 0)).toFixed(2).replace(/\.?0+$/, '')}/- Coins.</span>
                            </div> :
                            <div className='background-white text-[#DC143C] text-sm text-center py-1 font-semibold'>
                                <span>You Lost {Number.parseFloat((clientOddsComm ?? 0) + (clientSessionComm ?? 0)).toFixed(2).replace(/\.?0+$/, '')}/- Coins.</span>
                            </div>
                        }
                    </div>
                    <div className="">
                        <div className=' background-color text-sm white-text text-center p-1 font-bold'>
                            <span>Mob. App. Charges</span>
                        </div>
                        <div className='background-white text-[#DC143C] text-sm text-center py-1 font-semibold'>
                            <span>You Lost 0/- Coins.</span>
                        </div>
                    </div>
                    <div className="">
                        <div className=' background-color text-sm white-text text-center p-1 font-bold'>
                            <span>Net Plus Minus</span>
                        </div>
                        {(clientOddsComm + clientSessionComm + clientSessionAmount + clientOddsAmount) > 0 ?
                            <div className='background-white text-[#5199E6] text-sm text-center py-1 font-semibold'>
                                <span>You Won {Number.parseFloat((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0)).toFixed(2).replace(/\.?0+$/, '')} /- Coins.</span>
                            </div> :
                            <div className='background-white text-[#DC143C] text-sm text-center py-1 font-semibold'>
                                <span>You Lost {Number.parseFloat((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0)).toFixed(2).replace(/\.?0+$/, '')} /- Coins.</span>
                            </div>
                        }
                    </div>
                    <div>
                        <button onClick={() => this.onClickMenu('/app/TotalLedger')} className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text text-[13px] tracking-widest">BACK TO LIST</button>
                    </div>
                </div >
            </div>

        );
    }
}

export default LegderDetails;
