import React from "react";

const IframeCasino = (props) => {

    return (
        <div className=" overflow-auto h-screen">


            <div className=" h-full">
                <iframe src={props.url.url} title=" " loading='lazy' className=" mx-auto w-[100%] h-full" />
            </div>

        </div>
    );
};

export default IframeCasino;
