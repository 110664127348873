import React from 'react';
import moment from 'moment';

export default function BetListTableBmx(props) {
    const { betList } = props;
    return (

        <div className="overflow-x-auto w-full ">
            <table className="min-w-full capitalize border border-[#3D8282]">
                <tr className="w-full table-head-color text-white text-sm font-semibold text-center rounded-b">
                    <th colSpan={4} className="px-3 py-1 whitespace-nowrap text-center">Team</th>
                    {/* <th className="px-3 py-1 whitespace-nowrap">Odds</th> */}
                    <th className="px-3 py-1 border-[#3D8282] border-[1px] whitespace-nowrap">Mode</th>
                    <th className="px-3 py-1 border-[#3D8282] border-[1px] whitespace-nowrap">Rate</th>
                    <th className="px-3 py-1 border-[#3D8282] border-[1px] whitespace-nowrap">Amount</th>
                    <th className="px-3 py-1 border-[#3D8282] border-[1px] whitespace-nowrap">Result</th>
                    <th className="px-3 py-1 border-[#3D8282] border-[1px] whitespace-nowrap">Date & Time</th>
                </tr>
                {betList && betList.length > 0 ? betList.map((tempData) => (
                    <tr className="w-full text-black bg-white text-sm font-semibold text-center rounded-b">
                        <td colSpan={4} className="px-1.5 py-1 border-[#3D8282] border-[1px] whitespace-nowrap text-center">{tempData.playerName} <br />({tempData.roundId})</td>
                        {/* <td className="px-3 py-1 whitespace-nowrap">{tempData.amount}</td> */}
                        <td className="px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px]">{tempData.betType}</td>
                        <td className="px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px]">{tempData.odds}</td>
                        <td className="px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px]">{tempData.amount}</td>
                        <td className={`px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px] ${tempData.profitLoss < 0 ? "text-red-500" : tempData.profitLoss > 0 ? "text-green-800" : "text-black"}`}>{tempData.profitLoss}
                            <br />
                            {tempData.isDeclare === 1 ?
                                <small>({tempData.showResult})</small> : null
                            }
                        </td>
                        {/* <td className={`px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px]`}>
                            {tempData.isDeclare === 1 ?
                                <>{tempData.showResult}</> :
                                "Not Declare"
                            }
                        </td> */}
                        <td className="px-3 py-1 whitespace-nowrap border-[#3D8282] border-[1px]"> {tempData && tempData.createdAt ? moment(tempData.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss") : ''}</td>


                    </tr>)) : "Not found"
                }
            </table>
        </div>

    );
}