import React from 'react';

export default function BgSkyCardTheme2(props) {
    const { ele } = props;

    return (
        <div className="even-background w-full h-10 text-center text-base py-3 font-semibold leading-4 text-white uppercase shadow-md shadow-gray-500 rounded cursor-pointer">
            {ele}
        </div>
    );
}

