import React from 'react';
import { connect } from 'react-redux';
// import { DomainName } from '../../_config/index';
// import "./Matches.css";
import { GoDotFill } from "react-icons/go";
import "./Matches.css";
import { httpPost } from '../../_helpers/http';


class Matches extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      matchlistItems: [],
      matchOddsPos: [],
      domainSettingByDomainName: null,
    }
  }
  componentDidMount() {
    this.localStorageData();
    this.getInplayData();
    this.getDomainSettingByDomainName();
  }

  getDomainSettingByDomainName = () => {
    try {
      let domainSetting = localStorage.getItem('domainSettingByDomainName');
      if (domainSetting) {
        const parsedDomainSetting = JSON.parse(domainSetting);
        this.setState({ domainSettingByDomainName: parsedDomainSetting });
      } else {
        this.setState({ domainSettingByDomainName: null });
      }
    } catch {
      console.error('Error parsing domainSettingByDomainName:');
      this.setState({ domainSettingByDomainName: null });
    }
  };

  localStorageData = async () => {
    try {
      let adminMatchList = [];
      adminMatchList = localStorage.getItem('matchList');

      if (adminMatchList) {
        const parsedAdminMatchListData = JSON.parse(adminMatchList)
        if (parsedAdminMatchListData.length > 0) {
          this.setState({ matchlistItems: parsedAdminMatchListData });
        }
      } else {
        let matchlistItems = await httpPost('sports/matchList');
        if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
          localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
          this.setState({ matchlistItems: matchlistItems.data });
        } else {
          this.setState({ matchlistItems: [] });
        }
      }

    } catch (error) {
      console.error('Error in localStorageData:', error);
    }
  }

  getInplayData = async () => {
    try {
      let matchlistItems = await httpPost('sports/matchList');
      if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
        this.setState({ matchlistItems: matchlistItems.data });
        localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
      } else {
        this.setState({ matchlistItems: [] });
      }
    } catch (error) {
      console.error('Error in getInplayData:', error);
    }
  }

  handleResponseGame = (data) => {
    this.props.history.push('/app/ViewMatchis/' + data.marketId + "/" + data.eventId)
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    const { matchlistItems,domainSettingByDomainName } = this.state;
    let sortedMatches = []
    if (matchlistItems) {
      sortedMatches = [...matchlistItems];
      sortedMatches.sort((a, b) => a.priority - b.priority);
    }
    // let clientNotification = JSON.parse(localStorage.getItem('notification'))

    return (

      <>

        <div className={`main-body h-screen overflow-y-auto lg:pb-0 pb-20 ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[96px] pt-[96px]" : "md:pt-[70px] pt-[70px]"}`}>
          <div className="slider-main-div">
            <div>
              <div className="cricket-icon-mai">
                {matchlistItems && matchlistItems.length > 0 && sortedMatches.filter((element) => element.status === 'INPLAY' && element.sportId === 4).length > 0 ? sortedMatches.filter((element) => element.status === 'INPLAY' && element.sportId === 4).map((element, index) => (
                  <>
                    <div key={index} className="about-matches">
                      <div className='mobileflex '>
                        <div className="match-name ">
                          <div className='w-full text-start pt-2 bg-white space-y-0 leading-5'>
                            <p className='matchdate d-flex md:space-x-3 space-x-1 pl-5'>
                              <div className='button-color p-1 h-5 rounded-md'>
                                <img src="/images/cricket-icon.svg" alt='cricket' className='w-3 h-3' />
                              </div>
                              <div className='button-color rounded-[3px] w-8 h-5 flex justify-center items-center'>
                                <div className='bg-[#60BA1E] rounded-[4px] w-1/2 h-full'></div>
                                <div className='w-1/2 flex justify-center items-center'>
                                  <img src="/images/f-icon.svg" alt='fancy' className='w-3 h-3' />
                                </div>
                              </div>
                              <div className='button-color rounded-[3px] w-8 h-5 flex justify-center items-center'>
                                <div className='bg-[#60BA1E] rounded-[4px] w-1/2 h-full'></div>
                                <div className='w-1/2 flex justify-center items-center'>
                                  <img src="/images/b-icon.svg" alt='bookmaker' className='w-3 h-3' />
                                </div>
                              </div>
                              <div className='md:text-[20px] text-[18px] font-extrabold'>
                                {element.matchDate}
                                {/* {moment(element.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMMM hh:mm A')} */}
                              </div>
                            </p>
                            <span className='md:text-[20px] text-[18px] flex justify-start items-center space-x-1 font-bold text-[#5C5F71] cursor-pointer' onClick={() => this.handleResponseGame(element)}> <GoDotFill className='text-green-800 blinking' size={24} /><span className='truncate'>{element.matchName} </span></span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </>)) : null}
                {matchlistItems && matchlistItems && matchlistItems.length > 0
                  //  && matchlistItems.data.filter((element) => (element.sportId === 0 || 4)).length > 0 
                  ? sortedMatches.filter((element) => element.status !== 'INPLAY' && element.sportId === 4).map((element, index) => (
                    <>
                      <div key={index} className="about-matches">
                        <div className='mobileflex '>
                          <div className="match-name ">
                            <div className='w-full text-start pt-2 bg-white space-y-0 leading-5'>
                              <p className='matchdate d-flex md:space-x-3 space-x-1 pl-5'>
                                <div className='button-color p-1 h-5 rounded-md'>
                                  <img src="/images/cricket-icon.svg" alt='cricket' className='w-3 h-3' />
                                </div>
                                <div className='button-color rounded-[3px] w-8 h-5 flex justify-center items-center'>
                                  <div className='bg-[#60BA1E] rounded-[4px] w-1/2 h-full'></div>
                                  <div className='w-1/2 flex justify-center items-center'>
                                    <img src="/images/f-icon.svg" alt='fancy' className='w-3 h-3' />
                                  </div>
                                </div>
                                <div className='button-color rounded-[3px] w-8 h-5 flex justify-center items-center'>
                                  <div className='bg-[#60BA1E] rounded-[4px] w-1/2 h-full'></div>
                                  <div className='w-1/2 flex justify-center items-center'>
                                    <img src="/images/b-icon.svg" alt='bookmaker' className='w-3 h-3' />
                                  </div>
                                </div>
                                <div className='md:text-[20px] text-[18px] font-extrabold'>
                                  {element.matchDate}
                                  {/* {moment(element.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMMM hh:mm A')} */}
                                </div>
                              </p>
                              <span className='md:text-[20px] text-[18px] flex justify-start items-center space-x-1 font-bold text-[#5C5F71] cursor-pointer' onClick={() => this.handleResponseGame(element)}> <span className='pr-6'></span><span className='truncate'>{element.matchName} </span></span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </>)) : null}
              </div>
            </div>
          </div>
        </div>


      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Matches);
