export const headerConstent = {
    WELCOME_TO: 'WELCOME TO THE VANKY.COM',
    Main: 'Main: ',
    Exposure: 'Exposure :',
    Change_Password: 'Change Password',
    Log_out: 'Log out',
    Dashoard: 'Home',
    In_play: 'Profile',
    All_Market_Book: 'In Play',
    Profile: 'Free Games',
    Password: 'Rules',
    Rules: 'Ledger',
    My_Commision: 'password',
    Report: 'Report',
    Account_Statement: 'Account Statement',
    Total_Ledger: 'Total Ledger',
    Profit_Loss: 'Profit & Loss',
    Bet_History: 'Bet History',
    Live_Bet_History: 'Live Bet History',
    Live_Games: 'Live Games',
    TeenPatti_T20: 'TeenPatti T20',
    TeenPatti_1Day: 'TeenPatti 1Day',
    AndarBahar: 'Andar Bahar',
    Dragon_Tiger: 'Dragon Tiger',
    Cards_32: '32 Cards',
    Amar_Akbar_Anthony: 'Amar Akbar Anthony',
    Search_Result: 'Search Result',
    Edit_Stake: 'Casino',
    Aviator: 'Aviator',
    Matka: 'Matka',
    IPL_Winner: 'Setting',
    Logout: 'Logout',
}