import React, { useState } from 'react';
import { CASINOPRICE } from '../../_config';
// import { httpPost } from "../../_helpers/http";
import CommingSoon from '../CommingSoon/CommingSoon';
import Loader from "../../components/Loader/Loader";
import IframeCasino from '../IframeCasino/IframeCasino';
import { useHistory } from 'react-router-dom';



export default function CasinoCheckModal(props) {
    const { handleClose, betSlipData } = props;
    const [loading, setLoading] = useState(false);
    const [casinoData, setCasinoData] = useState(null);
    const [iframeCasino, setIframeCasinoData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    // const getCasinoData = async (url) => {
    //     setLoading(true);

    //     try {
    //         let casinoLogin = {
    //             "gameId": betSlipData.gameId,
    //             "platformId": "mobile",
    //             "redirectUrl": "localhost:3000/dashboard",
    //             // "providerName": "diamond"
    //         };

    //         let casinoLoginResponse = await httpPost('user/casinoLoginUrl', casinoLogin);
    //         if (casinoLoginResponse) {
    //             setCasinoData(casinoLoginResponse && casinoLoginResponse.data ? casinoLoginResponse.data : {});
    //             // setIframeCasinoData(true);
    //             props.history.push(url)
    //         } 

    //     } catch (error) {
    //         console.error("Error fetching casino data:", error);
    //         // Handle error, show a user-friendly message, etc.
    //         setShowModal(true); // Set showModal to true in case of an error
    //     } finally {
    //         setLoading(false);
    //         // handleClose();
    //     }

    // };

    const getCasinoData = () => {
        history.push('/app/iframe-aviator')
    }


    const closeModal = () => {
        setShowModal(false);
    };

    if (casinoData !== null) {
        // Assuming you have a way to redirect, replace the placeholder URL with the actual URL
        const redirectUrl = (casinoData.url);
        window.open(redirectUrl, '_blank');
    }

    // Check if casinoData is not null, redirect if true, otherwise show the modal
    // console.log("casinoDatacasinoDatacasinoData", casinoData);

    return (
        <>
            {iframeCasino ?
                <IframeCasino
                    url={casinoData}
                />
                : <div className='fixed w-full h-full top-0 z-20 mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1 right-0'>
                    {loading === true ?
                        <Loader />
                        : null}
                    <div className=" bg-white xl:w-[45rem]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto xl:mt-24 mt-6 top-2">
                        {/*modal header*/}
                        <div className=''>
                            <div className="flex items-center justify-center p-6 py-2 ">
                                <img src={betSlipData.image} alt="Casino" className="h-44" />
                            </div>

                            <div className='text-center space-y-2'>
                                <div className='text-base  text-[#0088CC] font-bold '>
                                    Please Note
                                </div>
                                <div className='md:text-2xl text-lg text-black '>
                                    (1 Points = ₹{CASINOPRICE.PRICE})
                                </div>
                                <div className=' md:text-lg text-base text-black px-4 '>
                                    <span className='text-[#0088CC]'>For Example:</span> If you place ₹{CASINOPRICE.AMOUNT} your bet will be ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} Win or Loss according to the above calculation. If your balance is ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} then it will show ₹{CASINOPRICE.AMOUNT} in the {betSlipData.name} and your bet of ₹{CASINOPRICE.AMOUNT} rupee in the {betSlipData.name} will be considered as ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} rupees.<br />
                                    यदि आप ₹{CASINOPRICE.AMOUNT} लगाते हैं तो उपरोक्त गणना के अनुसार आपकी शर्त जीत या हार ₹ {CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} होगी। यदि आपका बैलेंस ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} है तो {betSlipData.nameHindi} में यह ₹{CASINOPRICE.AMOUNT} दिखाएगा और {betSlipData.nameHindi} में आपकी ₹{CASINOPRICE.AMOUNT} रुपये की शर्त ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} रुपये मानी जाएगी।
                                </div>
                            </div>
                            <div className='flex justify-center items-center space-x-2 p-3'>
                                <button onClick={() => getCasinoData('/app/iframe-aviator')} disabled={loading} className="w-full text-white px-3 py-1.5 flex justify-center items-center cursor-pointer bg-[#0E1C64] font-semibold rounded-none text-base" type="submit">
                                    <span> Ok I Agree</span>
                                </button>
                                <button onClick={handleClose} className="w-full text-white px-3 py-1.5 flex justify-center items-center cursor-pointer  bg-[#951D1B] font-semibold rounded-none text-base" type="submit">
                                    <span>No, I Don't Agree</span>
                                </button>
                                {showModal && <CommingSoon handleClose={closeModal}
                                    message="You Are Not Authorized for the Play Casino Games"
                                    image="/images/OPPS.jpeg" />}
                            </div>
                        </div>

                    </div>
                </div >}

        </>
    );
}

