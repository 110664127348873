
import "./TotalLegder.css";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import moment from "moment";

class TotalLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsTransction: {},
            errors: {},
            ledgerType: "",
            userLedger: {},
            currentPage: 1,
            itemsPerPage: 10,
            errorType: "",
            showAlert: false,
            resMessage: "",
            isFetch: false,
            domainSettingByDomainName: null,
        }
    }

    componentDidMount() {
        this.getUserLedger();
        this.getDomainSettingByDomainName();
    }

    getDomainSettingByDomainName = () => {
        try {
          let domainSetting = localStorage.getItem('domainSettingByDomainName');
          if (domainSetting) {
            const parsedDomainSetting = JSON.parse(domainSetting);
            this.setState({ domainSettingByDomainName: parsedDomainSetting });
          } else {
            this.setState({ domainSettingByDomainName: null });
          }
        } catch {
          console.error('Error parsing domainSettingByDomainName:');
          this.setState({ domainSettingByDomainName: null });
        }
      };

    getUserLedger = async () => {
        let localData = JSON.parse(localStorage.getItem('spuser'));
        let userData = localData.data;
        let reqData = {
            "downlineUserId": userData.userId,
            "fromDate": this.state.startDate,
            "toDate": this.state.endDate,
            "ledgerType": this.state.fieldsTransction.ledgerType
        }
        this.setState({ isFetch: true })
        let userLedger = await httpPost('user/userLedger', reqData);

        if (userLedger) {
            this.setState({ userLedger: userLedger.data });
        } else {
            this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
        }
        this.setState({ isFetch: false });
        setTimeout(() => this.setState({ showAlert: false }), 2000);
    }
    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };
    onClickMenu = (url) => {
        this.props.history.push(url)
    }

    handleResponseGame = (data) => {
        if (data.ledgerType === "diamondCasino" || data.ledgerType === "internationalCasino") {
            this.props.history.push('/app/legder-details-casino/' + data.marketId + "/" + data.ledgerType + "/" + data.date)
        } else {
            this.props.history.push('/app/legder-details/' + data.marketId)
        }
    }

    render() {
        let { userLedger, resMessage, errorType, showAlert, isFetch ,domainSettingByDomainName} = this.state;
        let { totalCoins, creditAmount, debitAmount, ledgerData } = userLedger;
        const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
        const endIndex = startIndex + this.state.itemsPerPage;
        const currentItems = Array.isArray(ledgerData) ? ledgerData.slice(startIndex, endIndex) : [];
        // let clientNotification = JSON.parse(localStorage.getItem('notification'))
        return (
            <div className={`main-bodyTotal overflow-auto h-screen lg:pb-0 pb-24 ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[97px] pt-[90px]" : "md:pt-[72px] pt-[72px]"}`}>
                {isFetch === true ?
                    <Loader />
                    : null}
                {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}

                <div className="slider-main-divTotal lg:w-[60%] w-full mx-auto">
                    <div className='pb-2'>
                        <div className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text md:text-[13px] text-[11px] text-center tracking-widest">MY LEDGER</div>
                    </div>
                    <div>
                        <div className="grid grid-cols-3  xl:px-0 md:px-2 px-0 md:gap-2 gap-1">
                            <div className="score-lena flex justify-center items-center">
                                <span className="lena md:text-base text-xs">Lena : </span>
                                <span className="black-text font-[900] md:text-lg text-xs">{Math.abs(creditAmount ? creditAmount : "0")}</span>
                            </div>
                            <div className="score-dena flex justify-center items-center">
                                <span className="dena md:text-base text-xs">Dena : </span>
                                <span className="red-text font-[900] md:text-lg text-xs">{Math.abs(debitAmount ? debitAmount : "0")}</span>
                            </div>
                            <div className="score-balance flex justify-center items-center">

                                <span className="balance md:text-base text-xs ">Balance : </span>
                                <span className={`${totalCoins > 0 ? "text-[#088000]" : "text-[#FF0000]"}  text-center font-[900] md:text-lg text-xs md:py-0.5  whitespace-nowrap`}>
                                    {totalCoins > 0 ? (`${totalCoins && totalCoins ? Math.abs(totalCoins ? totalCoins : "0") : 0} `) : (`${totalCoins ? Math.abs(totalCoins ? totalCoins : "0") : "0"} `)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='w-full overflow-x-auto scrollbar-hide py-3'>
                        <table className="w-full border-2 border-[#E9E9E9] text-[13px]">
                            <thead className="rounded-t table-head-color ">
                                <tr className='white-text font-bold divide-x-6 divide-[#E9E9E9]'>
                                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] w-1/2">Description</th>
                                    <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">WON BY</th>
                                    <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">WON</th>
                                    <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">LOST</th>
                                    <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">HISAB</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentItems && currentItems ?
                                    <>
                                        {currentItems.map((element, index) =>
                                            <tr key={index} className=" background-white text-[12px] font-medium text-[#49494A]">
                                                <td className="truncate text-start text-[#007BFF] border-4 border-[#E9E9E9] py-[13px] w-1/2 cursor-pointer" onClick={() => this.handleResponseGame(element)}>{element.eventName}( {moment(element.createdAt).format('DD-MMM-YYYY')})</td>
                                                <td className=" whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element.remark}</td>
                                                {element && element.amount > 0 ?
                                                    <td className=" whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] text-center text-green-800 w-1/8">{Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, '')}</td> : <td className=" whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] text-center w-1/8">
                                                        0
                                                    </td>
                                                }
                                                {element && element.amount < 0 ?
                                                    <td className=" whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] text-center red-text w-1/8">{Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, '')}</td> : <td className=" whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] text-center w-1/8">
                                                        0
                                                    </td>
                                                }
                                                <td className={`${(element.balance < 0) ? "text-red-500" : "text-green-800"} hitespace-nowrap border-4 border-[#E9E9E9] py-[13px] text-center w-1/8"`}>
                                                    {element.balance ? Number.parseFloat(Math.abs(element.balance)).toFixed(2).replace(/\.?0+$/, '') : 0}
                                                </td>
                                            </tr>)}
                                    </> :
                                    <>
                                        <tr className="bg-transparent black-text w-full ">
                                            <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-full">No Data Found</td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                            {/* <tbody className=" border-2 border-[#E9E9E9] py-[13px]">
                                {userAccountDetails && userAccountDetails.length > 0 ? userAccountDetails.map((element, index) => (
                                    <tr className="bg-transparent black-text divide-x-6 divide-[#E9E9E9] ">
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9]">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YYYY h:mma")} </td>
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9]">{element.remark}</td>
                                        <td className='whitespace-nowrap border-4 border-[#E9E9E9]'>{element.oldAmount}</td>
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9] ">
                                            {element && element.amount > 0 ?
                                                <div className="text-[#52B5E1]">
                                                    {element.amount}
                                                </div>
                                                : 0
                                            }
                                        </td>
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9]">{element && element.amount < 0 ?
                                            <div className="text-[#FF6D6D]">
                                                {Math.abs(element.amount)}
                                            </div>
                                            : 0
                                        }</td>
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9]">{element.statementFor}</td>
                                        <td className="whitespace-nowrap border-4 border-[#E9E9E9]">{element.newAmount}</td>
                                    </tr>
                                )) : <tr className="bg-transparent black-text ">
                                    <td className="whitespace-nowrap border-4 border-[#E9E9E9] flex justify-start items-center w-full">No Record Found</td>
                                </tr>
                                }

                            </tbody> */}
                        </table>
                    </div>
                    {/* <div className='w-full overflow-x-auto scrollbar-hide'>
                        <table className="w-full border-none">
                            <thead className="rounded-t bg-[#DCDCDC]">
                                <tr className='text-gray-800 font-normal'>
                                    <th className="text-center">Date</th>
                                    <th className="text-center">Collection Name</th>
                                    <th className="text-center">Debit</th>
                                    <th className="text-center">Credit</th>
                                    <th className="text-center">Balance</th>
                                    <th className="text-center">Payment Type</th>
                                    <th className="text-center">Remark</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentItems && currentItems ?
                                    <>
                                        {currentItems.map((element) =>
                                            <tr className="bg-transparent black-text ">
                                                <td className="whitespace-nowrap">16 Sept 2023 00:18 am</td>
                                                <td className=" whitespace-nowrap">{element.eventName}</td>
                                                <td className=" whitespace-nowrap text-center">0.00</td>
                                                <td className=" whitespace-nowrap text-center">0.00</td>
                                                <td className=" whitespace-nowrap text-center">{element.balance}</td>
                                                <td className=" whitespace-nowrap">-</td>
                                                <td className=" whitespace-nowrap">{element.remark}</td>
                                            </tr>)}
                                    </> :
                                    <>
                                        <tr className="bg-transparent black-text ">
                                            <td className="whitespace-nowrap w-full">No Data Found</td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </table>
                    </div> */}
                    <Pagination
                        currentPage={this.state.currentPage}
                        totalPages={Math.ceil(ledgerData && ledgerData.length / this.state.itemsPerPage)}
                        maxVisibleButtons={1}
                        onPageChanged={this.handlePageChange}
                    />
                    <div className='pt-20'>
                        <button onClick={() => this.onClickMenu('/app/Dashboard')} className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text text-[13px] tracking-widest">back to Main Menu</button>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}
export default connect(mapStateToProps)(TotalLedger);