import React from 'react';

export default function BgSkyButtonTheme2(props) {
    const { ele, handleBackOpen, data, section1Ref } = props;

    return (
        <div onClick={() => handleBackOpen(data, section1Ref)} className="absolute top-0 even-background w-full h-10 text-center text-base py-3 font-semibold leading-4 text-white uppercase shadow shadow-gray-400 rounded cursor-pointer">
            {ele}
        </div>
    );
}