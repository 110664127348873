import React from 'react';

export default function MyBetTabBmx(props) {
  const { totalProfitLoss } = props;
  return (
    <div className="detail-header-main text-center py-1.5 px-3 text-white  uppercase text-sm font-semibold flex justify-between items-center">
      <span></span>
      <span>Casino Bet List </span>

      <span> P/L{" "} <span className={`${totalProfitLoss > 0 ? "text-green-500" : "text-red-500"}`}>{totalProfitLoss}</span> </span>
    </div>
  );
}



