import React from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import Loader from "../../components/Loader/Loader";

class Matka extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      matkaList: {},
      isFetch: false,
      errorType: "",
      resMessage: "",
      showAlert: false,
      CommingSoonModal: false,
    }
  }

  componentDidMount() {
    this.getMatkaList();
  }
  getMatkaList = async () => {
    this.setState({ isFetch: true })
    let matkaList = await httpPost('sports/getMatkaList');
    if (matkaList) {
      this.setState({ matkaList: matkaList.data ? matkaList.data : {} })
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false })
    setTimeout(() => this.setState({ showAlert: false }), 3000);
  }
  handleClose = () => {
    this.setState({ betPlaceModal: false, CommingSoonModal: false });
  };
  handleStakeOpen = () => {
    this.setState({ CommingSoonModal: true });

  };

  render() {
    let { matkaList, errorType, resMessage, showAlert } = this.state;
    let clientNotification = JSON.parse(localStorage.getItem('notification'))

    return (
      <>
        {this.state.isFetch === true ?
          <Loader />
          : null}
        {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='text-white font-bold'>{resMessage}</span></div>}
        <div className="border border-gray-600"></div>
        {this.state.CommingSoonModal === true ?
          <CommingSoon
            handleClose={this.handleClose}
            message="These Games are Comming Soon"
            image="/images/OPPS.jpeg"
          />
          : null}
        <div className={`overflow-auto h-screen lg:pb-0 pb-24 ${clientNotification && clientNotification.length > 0 ? "md:pt-[105px] pt-[100px]" : "md:pt-[82px] pt-[80px]"}`}>
          <div className='w-full'>
            <div className='px-2'>
              <div className='space-y-4'>

                {matkaList && matkaList.length > 0 ? matkaList.map((element, index) =>
                  <div key={index}>
                    <div className='flex justify-between md:text-base text-xs items-center white-text background-color md:p-3 p-2'>
                      <div>
                        OPEN {" "}{element.open_time}
                      </div>
                      <div className='md:text-xl text-lg px-2'>
                        {element.matka_name}
                      </div>
                      <div>CLOSE {element.close_time}</div>
                    </div>
                    <div className='flex justify-between items-center black-text background-white p-3 font-semibold'>
                      <div className='md:text-4xl text-2xl'>
                        <span>{element.result_array.patti_close_result}</span> -
                        <span className='md:text-5xl text-3xl text-[#555555] font-bold'>{element.result_array.patti_open_result}</span>{" "}
                        <span className='md:text-5xl text-3xl text-[#555555] font-bold'>{element.result_array.single_close_result}</span> -
                        <span>{element.result_array.single_open_result}</span>
                      </div>
                      <div>
                        {element && element.play_status === 1 ?
                          <button className='background-color py-2 md:px-4 px-2 uppercase md:text-xl text-sm white-text border-[#E1BB3A] border-2 rounded-none' onClick={() => this.handleStakeOpen()}>
                            Play Now
                          </button> :
                          <button className='change-password-button py-2 md:px-4 px-2 uppercase md:text-xl text-sm white-text border-[#E1BB3A] border-2 rounded-none'>
                            BET closed
                          </button>
                        }
                      </div>
                    </div>
                  </div>) : <div className='flex justify-center md:text-base text-lg items-center white-text background-color md:p-3 p-2'>
                  No Records Found
                </div>}
              </div>



            </div>
          </div>

        </div>
































      </>


    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Matka);
