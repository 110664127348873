import React from 'react';
import "./Loader.css";

export default function CommingSoon() {


    return (
        <>
            <div className='fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1'>
                <div className="h-full w-full  flex items-center justify-center">

                    <div className="pulse rounded-full"></div>

                </div>
            </div>
        </>
    );
}

