// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { store } from './_helpers';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
// import { userActions } from "./_actions";

const DomainSetting = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let domainUrl = {
  //         "domainUrl": "http://localhost:3000"
  //       }
  //       const storedDomainSetting = localStorage.getItem("domainSettingByDomainName");
  //       if (!storedDomainSetting) {
  //         store.dispatch(userActions.domainSettingByDomainName(domainUrl));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data: domainSettingByDomainName', error);
  //     }
  //   };
  //   fetchData();
  //   const intervalId = setInterval(fetchData, 1000 * 60 * 10);
  //   return () => clearInterval(intervalId);
  // }, []);


  return (
    <LayoutProvider>
      <ToastContainer />
      <Provider
        // @ts-ignore
        Provider store={store}>
        <App />
      </Provider>

    </LayoutProvider>
  );
};

ReactDOM.render(
  <DomainSetting />,
  document.getElementById("root"),
);

serviceWorker.unregister();