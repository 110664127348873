import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./ViewMatch.css";
import axios from "axios";
import EditStakeDesktop from '../../components/EditStakeDesktop/EditStakeDesktop';
import io from 'socket.io-client';
import moment from 'moment';
import { userActions } from "../../_actions";
import { PiTelevisionBold } from "react-icons/pi";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { betChipsData } from '../../_config';
import { GoDotFill } from "react-icons/go";
import { BsInfoCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { httpPost, httpPostBet } from '../../_helpers/http';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ViewMatchDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            betPlaceModal: false,
            matchDetails: {},
            socketPerm: true,
            socketUrl: "",
            scoreIframe: "",
            tvUrl: '',
            tvId: "",
            inplayMatchList: {},
            isTvScreen: false,
            cacheUrl: "",
            matchScoreDetails: {},
            matchDetailsForSocketNew: {},
            matchList: {},
            marketId: "",
            matchPosition: {},
            oddsBetData: [],
            getMatchDetails: {},
            loading: true,
            time: 7,
            count: 0,
            betSlipData: {
                stake: '',
            },
            fullScoreIframe: true,
            betplace: false,
            inCompleteFancy: [],
            sessionPlusMinus: "",
            showCompletedFancy: [],
            maximum_match_bet: "",
            maximum_session_bet: "",
            minimum_match_bet: "",
            minimum_session_bet: "",
            maximum_tie_coins: "",
            maximum_completed_coins: "",
            maximum_bookmaker_coins: "",
            maximum_matchOdds_coins: "",
            maximum_toss_coins: "",
            notification: "",

            matchMarketId: "",
            loadingBet: false,
            position: [],
            betChipsData: [],
            clientNotification: true,
            isBookmaker: false,
            isCompletedOdds: false,
            isFancy: false,
            isMatchOdds: false,
            isScore: false,
            isTieOdds: false,
            isToss: false,
            isTv: false,
            returnDataObject: {},
            returnDataFancyObject: {},
            lastdata: {},
            domainSettingByDomainName: null,
            commission_fancy_data: {},
            team_data: {},
            toss_data: {},
        }
        this.socket = null
        this.section1Ref = React.createRef();
        this.scrollTimeout = null;
        this.inputRef = React.createRef();

    }
    checkWindowWidth() {
        const isMobile = window.matchMedia('(max-width: 100vw)').matches;

        if (isMobile && this.inputRef.current) {
            setTimeout(() => {
                this.inputRef.current.focus();
            }, 0);
        }
    }

    filterData = () => {
        try {
            const { matchDetailsForSocketNew } = this.state;
            if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
                throw new Error("No data available to filter.");
            }

            const criteria = ['Tied Match', 'Match Odds', 'To Win the Toss'];
            const filteredData = matchDetailsForSocketNew.filter(item => criteria.includes(item.marketType));

            if (filteredData.length > 0) {
                const filteredDataObject = {};
                filteredData.forEach(item => {
                    filteredDataObject[item.marketType] = item;
                });
                this.setState({ lastdata: filteredDataObject });
            } else {
                console.error("No data matched the criteria.");
            }
        } catch (error) {
            console.error("Error filtering data:", error);
        }
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.getDomainSettingByDomainName();
        this.localStoragedata(this.props.match.params.marketId);

        try {
            const marketId = {
                "marketId": this.props.match.params.marketId
            };
            // this.getMarketCacheUrl(`https://oddsapi.winx777.com/v2/api/oddsData?market_id=${this.props.match.params.marketId}`)
            // this.getOtherMarketCacheUrl(`https://allexchsocket.winx777.com/v2/api/dataByEventId?eventId=${this.props.match.params.eventId}`)
            this.setupAsyncActions(marketId);
        } catch (error) {
            console.error("Error in componentDidMount:", error);
        }
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('touchstart', this.handleTouchStart);
        }
        if (window.innerWidth <= 768 && this.inputRef.current) {
            this.inputRef.current.focus();
            this.inputRef.current.dispatchEvent(new Event('touchstart'));
        }
    }

    async setupAsyncActions(marketId) {
        try {
            await this.getDidMountData(marketId);
            await this.fetchBetLists();
            await this.betChipData();
            // await this.fetchNotification();
            await this.matchDetails();
            await this.userPositionByMarketId(marketId);
        } catch (error) {
            console.error("Error in setupAsyncActions:", error);
        }
    }



    getDidMountData = async (marketId) => {
        try {
            marketId = marketId.marketId
            let getMatchDetails = await httpPost(`sports/sportByMarketId`, { marketId: marketId })
            if (!getMatchDetails.error) {
                let matchDetails = getMatchDetails.data
                this.setState({
                    matchDetails: matchDetails,
                    socketPerm: matchDetails && matchDetails.socketPerm ? matchDetails.socketPerm : {},
                    socketUrl: matchDetails && matchDetails.socketUrl ? matchDetails.socketUrl : {},
                    tvUrl: matchDetails && matchDetails.tvUrl ? matchDetails.tvUrl : "",
                    cacheUrl: matchDetails && matchDetails.cacheUrl ? matchDetails.cacheUrl : {},
                    scoreIframe: matchDetails && matchDetails.scoreIframe ? matchDetails.scoreIframe : "",
                    notification: matchDetails && matchDetails.notification ? matchDetails.notification : "",
                    matchMarketId: matchDetails && matchDetails.marketId ? matchDetails.marketId : "",
                    isBookmaker: matchDetails && matchDetails.isBookmaker ? matchDetails.isBookmaker : false,
                    isCompletedOdds: matchDetails && matchDetails.isCompletedOdds ? matchDetails.isCompletedOdds : false,
                    isFancy: matchDetails && matchDetails.isFancy ? matchDetails.isFancy : false,
                    isMatchOdds: matchDetails && matchDetails.isMatchOdds ? matchDetails.isMatchOdds : false,
                    isScore: matchDetails && matchDetails.isScore ? matchDetails.isScore : false,
                    isTieOdds: matchDetails && matchDetails.isTieOdds ? matchDetails.isTieOdds : false,
                    isToss: matchDetails && matchDetails.isToss ? matchDetails.isToss : false,
                    isTv: matchDetails && matchDetails.isTv ? matchDetails.isTv : false,
                })


                let socketPerm = false
                let socketUrl = ""
                let cacheUrl = ""
                if (matchDetails.socketPerm) {
                    socketPerm = matchDetails.socketPerm
                }
                if (matchDetails.socketUrl) {
                    socketUrl = matchDetails.socketUrl
                }
                if (matchDetails.cacheUrl) {
                    cacheUrl = matchDetails.cacheUrl
                }

                if (socketPerm == true) {
                    await this.connectSocket(socketUrl)
                } else {
                    await this.callCache(cacheUrl);
                }

                const minMaxData = JSON.parse(matchDetails?.maxMinCoins || "100");
                if (minMaxData) {
                    this.setState({
                        maximum_match_bet: minMaxData.maximum_match_bet,
                        maximum_session_bet: minMaxData.maximum_session_bet,
                        minimum_match_bet: minMaxData.minimum_match_bet,
                        minimum_session_bet: minMaxData.minimum_session_bet,
                        maximum_tie_coins: minMaxData.maximum_tie_coins,
                        maximum_completed_coins: minMaxData.maximum_completed_coins,
                        maximum_bookmaker_coins: minMaxData.maximum_bookmaker_coins,
                        maximum_matchOdds_coins: minMaxData.maximum_matchOdds_coins,
                        maximum_toss_coins: minMaxData.maximum_toss_coins,
                    });
                }

            } else {

            }
        } catch (error) {
            console.error('Error in getDidMountData:', error);
        }
    }

    localStoragedata = async (marketId) => {
        try {
            let adminMatchList = localStorage.getItem('matchList');
            if (adminMatchList) {
                const parsedAdminMatchListData = JSON.parse(adminMatchList);
                if (Array.isArray(parsedAdminMatchListData) && parsedAdminMatchListData.length > 0) {
                    let inplayMatchList = parsedAdminMatchListData.filter(element => element.marketId === marketId);
                    let tvUrl = "";
                    let scoreIframe = "";
                    let isTv = "";
                    let isScore = "";

                    if (inplayMatchList.length > 0) {
                        let cacheUrl = inplayMatchList[0]?.cacheUrl
                        tvUrl = inplayMatchList[0]?.tvUrl
                        scoreIframe = inplayMatchList[0]?.scoreIframe
                        isTv = inplayMatchList[0]?.isTv
                        isScore = inplayMatchList[0]?.isScore
                        this.getMarketCacheUrl(cacheUrl)
                    }
                    this.setState({ inplayMatchList: parsedAdminMatchListData, tvUrl, scoreIframe, isTv, isScore });
                } else {
                    await this.fetchAndStoreMatchList();
                }
            } else {
                await this.fetchAndStoreMatchList();
            }
        } catch (error) {
            console.error('Error in localStoragedata:', error);
        }
    }

    fetchAndStoreMatchList = async () => {
        try {
            let matchlistItems = await httpPost('sports/matchList');
            if (matchlistItems && matchlistItems.data && Array.isArray(matchlistItems.data) && matchlistItems.data.length > 0) {
                localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
                this.setState({ inplayMatchList: matchlistItems.data });
            } else {
                console.error('Match list is empty or invalid');
                this.setState({ inplayMatchList: [] });
            }
        } catch (error) {
            this.setState({ inplayMatchList: [] });
        }
    };

    matchDetails = async () => {
        try {
            await this.fetchAndStoreMatchList();
        } catch (error) {
            console.error('Error in getInplayData:', error);
        }
    }

    fetchBetLists = async () => {
        try {
            const BetListData = {
                fancyBet: true,
                oddsBet: true,
                isDeleted: 0,
                marketId: this.props.match.params.marketId,
            };

            const userBetHistory = await httpPost('sports/betsList', BetListData);

            if (userBetHistory && userBetHistory.data) {
                const { fancyBetData, oddsBetData } = userBetHistory.data;

                const sortedOddsBetData = oddsBetData ? oddsBetData.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                const filteredFancyBetData = fancyBetData ? fancyBetData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                let completeFancy = filteredFancyBetData && filteredFancyBetData.length > 0 ? filteredFancyBetData.filter(element => element.isDeclare === 1) : []
                let inCompleteFancy = filteredFancyBetData && filteredFancyBetData.length > 0 ? filteredFancyBetData.filter(element => element.isDeclare === 0) : []

                let showCompletedFancy = []
                let sessionPlusMinus = 0
                completeFancy.map((data) => {
                    let pos = 0;
                    if (data.decisionRun >= data.run && data.type === "Y") {
                        pos = Math.round(data.amount * data.odds);
                    } else if (data.decisionRun >= data.run && data.type === "N") {
                        pos = Math.round(-1 * data.amount * data.odds);
                    } else if (data.decisionRun < data.run && data.type === "Y") {
                        pos = Math.round(-1 * data.amount);
                    } else if (data.decisionRun < data.run && data.type === "N") {
                        pos = Math.round(data.amount);
                    }
                    sessionPlusMinus += pos
                    data.pos = pos
                    showCompletedFancy.push(data)
                })

                this.setState({ oddsBetData: sortedOddsBetData, inCompleteFancy: inCompleteFancy, showCompletedFancy: showCompletedFancy, sessionPlusMinus: sessionPlusMinus });
            }
        } catch (error) {
            console.error('Error fetching bet lists:', error);
        }
    };

    userPositionByMarketId = async (marketId) => {
        try {
            const userPositionData = await httpPost('sports/userPositionByMarketId', marketId);
            if (userPositionData) {
                const getUserPositionItem = userPositionData.data;
                let oddsPos = [];
                let sessPos = [];
                let returnDataObject = {}
                let returnDataFancyObject = {}
                if (getUserPositionItem?.oddsPosition) {
                    oddsPos = getUserPositionItem.oddsPosition;
                    oddsPos.forEach(data => {
                        const hasKey = returnDataObject.hasOwnProperty(data._id);
                        if (!hasKey) {
                            returnDataObject[data._id] = data.totalPosition.toFixed(2);
                            this.setState({ returnDataObject });
                        }
                    });
                }

                if (getUserPositionItem?.sessionPosition) {
                    sessPos = getUserPositionItem.sessionPosition;
                    sessPos.forEach(data => {
                        const hasKey = returnDataObject.hasOwnProperty(data._id);
                        if (!hasKey) {
                            returnDataFancyObject[data._id] = data.position;
                            this.setState({ returnDataFancyObject });
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching user position:', error);
        }
    }


    connectSocket = async (socketUrl, socketPerm) => {
        if (!socketUrl) {
            socketUrl = this.state.socketUrl
        }
        if (!socketPerm) {
            socketPerm = this.state.socketPerm
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            return;
        }
        if (socketPerm === true) {
            this.socket = io.connect(socketUrl, {
                transports: ["websocket"],
                reconnection: false,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99,
            });

            this.socket.emit('JoinRoom', this.props.match.params.marketId);
            this.socket.on(this.props.match.params.marketId, data => {
                data = JSON.parse(data);
                if (data) {
                    this.matchDataDestructure(data.result)
                }
                // this.setState({ matchScoreDetails: data.result });
            });
        }
    }

    callCache = async (cacheUrl) => {
        try {
            this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
        } catch (error) {
            console.error('Error calling cache:', error);
        }
    }

    getMarketCacheUrl = async (cacheUrl) => {
        try {
            const response = await axios.get(cacheUrl);
            if (response) {
                this.matchDataDestructure(response.data?.result)
            }
            // this.setState({ matchScoreDetails: response.data?.result });
        } catch (error) {
            console.error('Error fetching cache data:', error);
        }
    }


    matchDataDestructure = async (matchScoreDetails) => {
        try {
            let { commission_fancy_data, team_data, toss_data } = matchScoreDetails || {};

            if (!commission_fancy_data) {
                this.setState({ commission_fancy_data: {}, team_data, toss_data });
            }

            const sortedSessions = commission_fancy_data?.sort((a, b) => {
                // Helper functions
                const getInitialChar = (str) => str.trim().charAt(0);
                const startsWithDigit = (str) => /^\d/.test(str);
                const includesOver = (str) => /OVER/.test(str);
                const getNumericPart = (str) => parseInt(str.match(/^\d+/)?.[0] || '0', 10);
                const containsFirst = (str) => /\b1ST\b|\bFIRST\b/.test(str.toUpperCase());

                // Extract session names
                const nameA = a.session_name;
                const nameB = b.session_name;

                // Check properties of the session names
                const isDigitA = startsWithDigit(nameA);
                const isDigitB = startsWithDigit(nameB);
                const includesOverA = includesOver(nameA);
                const includesOverB = includesOver(nameB);
                const containsFirstA = containsFirst(nameA);
                const containsFirstB = containsFirst(nameB);

                // Handle cases where either session contains "1ST" or "FIRST"
                if (containsFirstA && !containsFirstB) return 1; // "1ST"/"FIRST" comes last
                if (!containsFirstA && containsFirstB) return -1; // "1ST"/"FIRST" comes last

                // First priority: Digits with "OVER" come before digits without "OVER"
                if (isDigitA && isDigitB) {
                    if (includesOverA && !includesOverB) return -1; // "OVER" comes before non-"OVER"
                    if (!includesOverA && includesOverB) return 1;  // "OVER" comes after non-"OVER"

                    // If both have or don't have "OVER", sort numerically
                    const numA = getNumericPart(nameA);
                    const numB = getNumericPart(nameB);
                    return numA - numB;
                }

                // Second priority: Digits come before letters
                if (isDigitA && !isDigitB) return -1; // Digits come before letters
                if (!isDigitA && isDigitB) return 1;  // Letters come after digits

                // Third priority: Sort alphabetically for letters
                return nameA.localeCompare(nameB);
            });

            this.setState({ commission_fancy_data: sortedSessions, team_data, toss_data });
        } catch (error) {
            console.error("Error in matchDataDestructure:", error);
            // Handle the error as appropriate, e.g., set an error state, show a notification, etc.
        }
    };


    getOtherMarketCacheUrl = async (cacheUrl) => {
        try {
            const response = await axios.get(cacheUrl);
            this.setState({ matchDetailsForSocketNew: response?.data?.data });
            this.filterData();
        } catch (error) {
            console.error('Error fetching cache data:', error);
        }
    }


    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.inputRef.current) {
            this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
        }
        this.cleanupWebSocket();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }


    cleanupWebSocket = () => {
        if (this.socket) {
            this.socket.close();
        }
    };

    getDomainSettingByDomainName = () => {
        try {
            let domainSetting = localStorage.getItem('domainSettingByDomainName');
            if (domainSetting) {
                const parsedDomainSetting = JSON.parse(domainSetting);
                this.setState({ domainSettingByDomainName: parsedDomainSetting });
            } else {
                this.setState({ domainSettingByDomainName: null });
            }
        } catch {
            console.error('Error parsing domainSettingByDomainName:');
            this.setState({ domainSettingByDomainName: null });
        }
    };

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !this.state.isConnected) {
            this.connectSocket()
        } else if (document.visibilityState === 'hidden') {
            this.cleanupWebSocket();
        }
    };

    betChipData = async () => {
        try {
            let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
            const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
            this.setState({ betChipsData: myArray });
        } catch (error) {
            console.error('Error parsing JSON from localStorage: betChipsDatas', error);
            this.setState({ betChipsData: betChipsData });
        }
    };

    // fetchNotification = async () => {
    //     try {
    //         const clientNotification = JSON.parse(localStorage.getItem('notification'));
    //         this.setState({ clientNotification: clientNotification });
    //     } catch (error) {
    //         console.error('Error parsing JSON from localStorage:', error);
    //         this.setState({ clientNotification: null });
    //     }
    // };

    handleTvScreen = () => {
        this.setState({ isTvScreen: !this.state.isTvScreen });
    }

    handleScoreIframe = () => {
        this.setState({ fullScoreIframe: !this.state.fullScoreIframe });
    }

    handleBackOpen = (data, sectionRef) => {
        try {
            this.setState({
                betPlaceModal: false,
            });

            if (this.scrollTimeout) {
                clearInterval(this.scrollTimeout);
            }

            this.setState({
                betPlaceModal: true,
                betSlipData: { ...data, stake: "0" },
                count: data.odds,
                teamname: data.name,
                time: 7,
            });

            this.scrollTimeout = setInterval(() => {
                this.setState(prevState => ({ time: prevState.time - 1 }), () => {
                    if (this.state.time === 0) {
                        clearInterval(this.scrollTimeout);
                        this.setState({ betPlaceModal: false });
                    }
                });
            }, 1000);

            setTimeout(() => {
                if (sectionRef && sectionRef.current) {
                    sectionRef.current.scrollIntoView({ behavior: 'smooth' });

                    if (window.innerWidth <= 768 && this.inputRef.current && sectionRef && sectionRef.current) {
                        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
                        setTimeout(() => {
                            this.inputRef.current.focus();
                        }, 0);
                    }
                }
            }, 0);
        } catch (error) {
            console.error('Error handling back open:', error);
        }
    };

    updateStake = (event) => {
        try {
            let { value } = event.target;
            let { betSlipData } = this.state;
            if (value.startsWith('0') && value.length > 1) {
                value = value.slice(1);
            }
            betSlipData.stake = value;
            this.setState({
                betSlipData: betSlipData,
            });
        } catch (error) {
            console.error('Error updating stake:', error);
        }
    };

    handleClose = () => {
        this.setState({ betPlaceModal: false, EditStakeModal: false });
    };


    updateStackOnclick = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };



    inputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            betSlipData: {
                ...this.state.betSlipData,
                [name]: Number(value),
            },
        });
    };


    placeBet = async () => {
        try {
            const { matchDetails, betSlipData } = this.state;
            const betObject = {
                "odds": this.state.count + "",
                "amount": this.state.betSlipData.stake,
                "selectionId": this.state.betSlipData.selectionId + "",
                "marketId": matchDetails.marketId + "",
                "eventId": matchDetails.eventId,
                "betFor": this.state.betSlipData.betFor + "",
                "run": this.state.betSlipData.run ? this.state.betSlipData.run + "" : "0",
                "oddsType": this.state.betSlipData.oddsType === "Match Odds" ? "matchOdds" : this.state.betSlipData.oddsType === "Tied Match" ? "tiedMatch" : this.state.betSlipData.oddsType + "",
                "type": this.state.betSlipData.betType + "",
            };
            if (betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "fancy") {
                // Do something if needed
            } else {
                betObject["betfairMarketId"] = betSlipData.betfairMarketId + "";
            }
            this.setState({ loadingBet: true, betPlaceModal: false })
            if (betSlipData.oddsType === "fancy") {
                if (this.state.minimum_session_bet <= this.state.betSlipData.stake) {
                    let saveBetOdds = await httpPostBet("sports/sessionBetPlaced", betObject);
                    this.setState({ loadingBet: false })
                    if (!saveBetOdds.error) {
                        toast.success(saveBetOdds.message, {
                            autoClose: 1000,
                        });
                        this.props.dispatch(userActions.getUserBalance());
                        await this.userPositionByMarketId({ marketId: matchDetails.marketId })
                        await this.fetchBetLists()
                    } else {
                        toast.error(saveBetOdds.message, {
                            autoClose: 1000,
                        });
                    }
                } else {
                    this.setState({ loadingBet: false, })
                    toast.error(`Please Enter Minimun Amount ${this.state.minimum_session_bet}`, {
                        autoClose: 1000,
                    });
                }
            } else {
                if (this.state.minimum_match_bet <= this.state.betSlipData.stake) {
                    let saveBetOdds = await httpPostBet("sports/oddBetPlaced", betObject);
                    this.setState({ loadingBet: false })
                    if (!saveBetOdds.error) {
                        toast.success(saveBetOdds.message, {
                            autoClose: 1000,
                        });
                        this.props.dispatch(userActions.getUserBalance());
                        await this.userPositionByMarketId({ marketId: matchDetails.marketId })
                        await this.fetchBetLists()
                    } else {
                        toast.error(saveBetOdds.message, {
                            autoClose: 1000,
                        });
                    }
                } else {
                    this.setState({ loadingBet: false, })
                    toast.error(`Please Enter Minimun Amount ${this.state.minimum_match_bet}`, {
                        autoClose: 1000,
                    });
                }
            }

        } catch (error) {
            console.error('Error placing bet:', error);
            toast.error('Failed to place bet. Please try again later.');
        }
    };

    render() {

        let { scoreIframe, tvUrl, matchScoreDetails, isTvScreen, inCompleteFancy, oddsBetData, showCompletedFancy, sessionPlusMinus, maximum_match_bet, maximum_session_bet, minimum_match_bet, notification, inplayMatchList, matchMarketId, loadingBet, betChipsData, clientNotification, fullScoreIframe, time, betPlaceModal, isBookmaker, isFancy, isMatchOdds, isScore, isTieOdds, isToss, isTv, lastdata, returnDataObject, returnDataFancyObject, domainSettingByDomainName, commission_fancy_data, team_data, toss_data } = this.state
        // let { commission_fancy_data, team_data, toss_data } = matchScoreDetails ? matchScoreDetails : {};


        isMatchOdds = false
        isToss = false
        isTieOdds = false
        return (
            <div className={`overflow-auto h-screen scroll-smooth ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[95px] pt-[95px]" : "md:pt-[70px] pt-[70px]"}`}>
                {
                    this.state.EditStakeModal === true ?
                        <EditStakeDesktop
                            handleClose={this.handleClose}
                            stakehandleClose={this.handleClose}
                        />
                        : null
                }

                {/* {betMessageModal ?
                    <div>
                        {betStatus === true ?
                            <div className='fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1'>
                                <div className='flex justify-center items-center my-auto'>
                                    <div className="h-52 w-full bg-white px-2 mx-auto" onClick={() => this.handleCloseModal()}>
                                        <div className='flex justify-center items-center'>
                                            <IoCheckmarkCircle className='text-green-600 ' size={80} />
                                        </div>
                                        <div className=" rounded-full black-text text-lg text-center pt-4 md:font-bold font-medium">{errorData}</div>
                                        <div className='flex justify-center items-center'>
                                            <button className="uppercase bg-green-600 p-2 white-text rounded-full" onClick={() => this.handleCloseModal()}>ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1'>
                                <div className='flex justify-center items-center my-auto'>
                                    <div className="h-52 w-full bg-white px-2 mx-auto" onClick={() => this.handleCloseModal()}>
                                        <div className='flex justify-center items-center'>
                                            <IoCloseCircle className='red-text ' size={80} />
                                        </div>
                                        <div className=" rounded-full black-text text-lg text-center pt-4 md:font-bold font-medium">{errorData}</div>
                                        <div className='flex justify-center items-center'>
                                            <button className="uppercase change-password-button p-2 white-text rounded-full" onClick={() => this.handleCloseModal()}>ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                    : null} */}


                <div className="flex flex-col pb-10 mainclass">
                    <main className="relative w-full">
                        {notification && notification !== "" ?
                            <div className='background-color'>
                                <marquee className="w-full lg:font-extrabold font-medium lg:text-lg text-sm text-white">
                                    {notification && notification ? notification : ""}
                                </marquee>
                            </div>
                            : null
                        }
                        <div className='background-color md:py-2 py-0'>
                            <div className='flex justify-between items-center text-[13px] py-1 '>
                                <span onClick={this.handleTvScreen} className='px-2 md:font-bold font-medium white-text text-center whitespace-nowrap  cursor-pointer flex justify-center items-center space-x-1'><PiTelevisionBold /> <span>TV</span></span>
                                <span onClick={this.handleScoreIframe} className='px-2 md:font-bold font-medium white-text text-center whitespace-nowrap  cursor-pointer blinking'>FS</span>
                            </div>
                        </div>
                        <section className="w-full mx-auto rounded-md pb-24">
                            {isTv ?
                                <>
                                    {isTvScreen ?
                                        <div className="details bg-[#000000c6]">
                                            <div className={`xl:w-1/4 lg:w-1/2 w-full mx-auto relative md:text-sm text-[10px] bg-[#000000c6] h-[250px]`}>
                                                <iframe src={tvUrl ? tvUrl : ""} title=" " className="w-[100%] h-[250px]" />
                                            </div>
                                        </div> : null}
                                </> : null
                            }

                            <div className="details pt-0.5">
                                {isScore ?
                                    <div className={`lg:w-[60%] md:w-full mx-auto md:text-sm text-[10px] relative`} >
                                        {scoreIframe && scoreIframe === null ?

                                            <div className='bg-[#000000c6] h-[200px]'>

                                            </div> :

                                            <div>
                                                <iframe src={scoreIframe && scoreIframe ? scoreIframe : ""} title=" " loading='lazy'

                                                    className={`w-[100%] bg-[#000000c6] ${fullScoreIframe ? "h-[125px]" : "h-[210px]"}`}

                                                />
                                            </div>
                                        }
                                    </div> :
                                    null
                                }
                            </div>

                            <div className='lg:w-[60%] md:w-full mx-auto divide-y divide-[#3D8282] '>


                                {isBookmaker ?
                                    <>
                                        {team_data && team_data.length > 0 ?
                                            <div className="min-w-full border-[1px] border-[#3D8282] md:text-base text-sm">
                                                <div className='grid grid-cols-5 white-text border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]'>
                                                    <div className="col-span-3 text-center background-color p-[2px]">
                                                        <span className='font-bold text-sm  '>Min: {minimum_match_bet ? minimum_match_bet : "100"} Max: {maximum_match_bet ? maximum_match_bet : "100000"}</span>
                                                    </div>
                                                    <div className='col-span-2 grid grid-cols-2  divide-x divide-[#3D8282]'>
                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">LAGAI</div>
                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">KHAI</div>
                                                    </div>
                                                </div>
                                                {team_data && team_data.length > 0 ? team_data.map((element, index) => (
                                                    <div key={index} className="background-white grid grid-cols-5 text-[#49494A] border-b-[1px] border-gray-500 divide-x-[1px] background-white divide-gray-500">
                                                        <div className="col-span-3 flex justify-between items-center p-2">
                                                            <span className='font-semibold text-sm md:h-6 h-5 truncate' style={{ fontWeight: 500 }}>{element.team_name}</span>
                                                            <div key={index} className=' flex justify-center items-center text-center '>

                                                                <span key={index} className={`${returnDataObject[element.selectionid] > 0 ? 'text-green-800' : returnDataObject[element.selectionid] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-base text-center  `} >
                                                                    {returnDataObject[element.selectionid] !== undefined && returnDataObject[element.selectionid] !== null && returnDataObject[element.selectionid] !== "" ? returnDataObject[element.selectionid] : "0"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className='w-full h-full grid grid-cols-2  divide-x divide-[#3D8282] text-base'>
                                                                {element.lgaai === "0.00" ?
                                                                    <div className="w-full h-full font-bold text-[#5957FF] text-center p-2 text-sm"
                                                                    >0</div> : <div className="w-full h-full font-bold  text-[#5957FF] text-center p-2 cursor-pointer text-base"
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.lgaai, name: element.team_name, betFor: "odds", oddsType: "bookmaker", betType: "L", selectionId: element.selectionid }, this.section1Ref)}>{Math.round(element.lgaai * 100)}</div>
                                                                }
                                                                {element.khaai === "0.00" ?
                                                                    <div className="w-full h-full font-bold text-[#FF5C72] text-center p-2 text-sm"
                                                                    >0</div> : <div className="w-full h-full font-bold  text-[#FF5C72] text-center p-2 cursor-pointer text-base"
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.khaai, name: element.team_name, betFor: "odds", oddsType: "bookmaker", betType: "k", selectionId: element.selectionid }, this.section1Ref)}>{Math.round(element.khaai * 100)}</div>
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : null}

                                            </div>
                                            : null}
                                    </> : null}

                                {isMatchOdds ?
                                    <>
                                        {Object.values(lastdata).map((element, index) => element.marketType === "Match Odds" && (
                                            <div className="min-w-full border-[1px] border-[#3D8282]" key={index}>
                                                <>
                                                    <div className='grid grid-cols-5  white-text border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]'>
                                                        <div className="col-span-3 flex md:flex-row flex-col justify-between items-center text-center background-color p-[2px] font-semibold px-2">
                                                            <span className='font-semibold text-sm truncate'>{element && element.marketType
                                                                ? element.marketType
                                                                : "NA"}</span>
                                                            <span className='font-bold text-sm  '>Min: {minimum_match_bet ? minimum_match_bet : "100"} Max: {maximum_match_bet ? maximum_match_bet : "100000"}</span>
                                                            <span></span>
                                                        </div>
                                                        <div className='col-span-2 grid grid-cols-2 divide-x divide-[#3D8282]'>
                                                            <div className="background-color text-center white-text font-bold text-sm p-[2px]">Back</div>
                                                            <div className="background-color text-center white-text font-bold text-sm p-[2px]">Lay</div>
                                                        </div>
                                                    </div>

                                                    {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (


                                                        <div key={index} className="background-white grid grid-cols-5 text-[#49494A] border-b-[1px] border-gray-500 divide-x-[1px] background-white divide-gray-500">
                                                            <div className="col-span-3 flex justify-between items-center p-2">
                                                                <span className='font-semibold text-sm md:h-6 h-5 truncate'>{elementtemp.selectionName}</span>
                                                                <div key={index} className=' flex justify-center items-center text-center'>
                                                                    <span key={index} className={`${returnDataObject[elementtemp.selectionId] > 0 ? 'text-green-800' : returnDataObject[elementtemp.selectionId] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-base text-center  `} >
                                                                        {returnDataObject[elementtemp.selectionId] !== undefined && returnDataObject[elementtemp.selectionId] !== null && returnDataObject[elementtemp.selectionId] !== "" ? returnDataObject[elementtemp.selectionId] : "0"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-span-2'>
                                                                <div className='w-full h-full grid grid-cols-2 divide-x divide-[#3D8282] text-base'>
                                                                    <div>
                                                                        {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                                            <>
                                                                                {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {tempData.price === 0 ?
                                                                                            <div className="w-full text-[#5957FF] text-center px-2"
                                                                                            >  <div className='text-base font-bold'>0</div>
                                                                                                <div className='text-[10px] font-bold pb-1'> 0.00</div></div> : <div className="w-full text-center px-2 cursor-pointer text-[#5957FF]"
                                                                                                    onClick={() => this.handleBackOpen({ data: tempData, type: "Yes", odds: tempData.price, name: elementtemp.selectionName, betFor: "matchOdds", oddsType: element.marketType, betType: "L", selectionId: elementtemp.selectionId, betfairMarketId: element.marketId }, this.section1Ref)}>
                                                                                                <div className='text-base font-bold'> {tempData.price}</div>
                                                                                                <div className='text-[10px] font-bold'> {Math.floor(tempData.size)}</div>
                                                                                            </div>
                                                                                        }

                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <div className="bg-white bg-opacity-5 ">
                                                                                <div className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {elementtemp &&
                                                                            elementtemp.ex &&
                                                                            elementtemp.ex.availableToLay &&
                                                                            elementtemp.ex.availableToLay.length >
                                                                            0 ? (
                                                                            elementtemp.ex.availableToLay.map(
                                                                                (tempData, index) => (
                                                                                    <React.Fragment>
                                                                                        {index === 0 ? (
                                                                                            <>
                                                                                                {tempData.price === 0 ?
                                                                                                    <div className="w-full text-[#FF5C72] text-center px-2"
                                                                                                    >  <div className='text-base font-bold'>0</div>
                                                                                                        <div className='text-[10px] font-bold pb-1'> 0.00</div></div> : <div className="w-full text-center px-2 cursor-pointer text-[#FF5C72]"
                                                                                                            onClick={() => this.handleBackOpen({ data: tempData, type: "No", odds: tempData.price, name: elementtemp.selectionName, betFor: "matchOdds", oddsType: element.marketType, betType: "K", selectionId: elementtemp.selectionId, betfairMarketId: element.marketId }, this.section1Ref)}>
                                                                                                        <div className='text-base font-bold'> {tempData.price}</div>
                                                                                                        <div className='text-[10px] font-bold'> {Math.floor(tempData.size)}</div>
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            null
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            )
                                                                        ) : (
                                                                            <div className="bg-white bg-opacity-5 ">
                                                                                <div className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                                                    Not cound
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : null}
                                                </>

                                            </div>
                                        ))}
                                    </> : null
                                }

                                {isToss ?
                                    <>
                                        {toss_data && toss_data.length > 0 ?
                                            <div className="min-w-full border-[1px] border-[#3D8282] text-[13px]">
                                                <div className='grid grid-cols-5 white-text border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]'>
                                                    <div className="col-span-3 font-semibold flex md:flex-row flex-col justify-between items-center text-center background-color p-[2px] ">
                                                        <span className='uppercase'>To Win The Toss</span>
                                                        <span className='font-bold text-sm  '>Min: {minimum_match_bet ? minimum_match_bet : "100"} Max: {maximum_match_bet ? maximum_match_bet : "100000"}</span>
                                                        <span></span>
                                                    </div>
                                                    <div className='col-span-2 grid grid-cols-2  divide-x divide-[#3D8282]'>
                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">LAGAI</div>
                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">KHAI</div>
                                                    </div>
                                                </div>
                                                {toss_data && toss_data.length > 0 ? toss_data.map((element, index) => (
                                                    <div key={index} className="background-white grid grid-cols-5 text-[#49494A] border-b-[1px] border-gray-500 divide-x-[1px] background-white divide-gray-500">
                                                        <div className="col-span-3 flex justify-between items-center p-2">
                                                            <span className='font-semibold text-sm md:h-6 h-5 truncate' style={{ fontWeight: 500 }}>{element.team_name}</span>
                                                            <div key={index} className=' flex justify-center items-center text-center'>
                                                                <span key={index} className={`${returnDataObject[element.selectionid] > 0 ? 'text-green-800' : returnDataObject[element.selectionid] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-base text-center  `} >
                                                                    {returnDataObject[element.selectionid] !== undefined && returnDataObject[element.selectionid] !== null && returnDataObject[element.selectionid] !== "" ? returnDataObject[element.selectionid] : "0"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className='w-full h-full grid grid-cols-2  divide-x divide-[#3D8282] text-base'>
                                                                {element.lgaai === "0.00" ?
                                                                    <div className="w-full h-full font-bold text-[#5957FF] text-center p-2 text-sm"
                                                                    >0</div> : <div className="w-full h-full font-bold  text-[#5957FF] text-center p-2 cursor-pointer text-base"
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.lgaai, name: element.team_name, betFor: "toss", oddsType: "toss", betType: "L", selectionId: element.selectionid, betfairMarketId: this.props.match.params.marketId }, this.section1Ref)}>{Math.round(element.lgaai * 100)}</div>
                                                                }
                                                                {element.khaai === "0.00" ?
                                                                    <div className="w-full h-full font-bold text-[#FF5C72] text-center p-2 text-sm"
                                                                    >0</div> : <div className="w-full h-full font-bold  text-[#FF5C72] text-center p-2 cursor-pointer text-base"
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.khaai, name: element.team_name, betFor: "toss", oddsType: "toss", betType: "k", selectionId: element.selectionid, betfairMarketId: this.props.match.params.marketId }, this.section1Ref)}>{Math.round(element.khaai * 100)}</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : null}

                                            </div>
                                            : null}
                                    </> : null}

                                {isFancy ?
                                    <>
                                        {commission_fancy_data && commission_fancy_data.length > 0 ?
                                            <div className="min-w-full border-[1px] border-[#3D8282] text-[13px]">
                                                <div className='grid grid-cols-5 background-color white-text w-full border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]'>
                                                    <div className="col-span-3 font-semibold flex justify-between items-center p-[2px] text-center"> <span></span>
                                                        <span className='uppercase'>Session</span>
                                                        <Link to='/app/login_auth' ><BsInfoCircleFill size={16} /></Link></div>
                                                    <div className="text-center white-text font-semibold p-[2px]">NOT</div>
                                                    <div className="text-center white-text font-semibold p-[2px]">YES</div>
                                                </div>
                                                {commission_fancy_data && commission_fancy_data.length > 0 ? (
                                                    commission_fancy_data.map((element, index) => (
                                                        <div key={index} className="background-white grid grid-cols-5 text-[#49494A] border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]">
                                                            <div className="col-span-3 px-2 font-bold">
                                                                <div className=' w-full font-semibold capitalize' style={{ fontWeight: 800 }}>{element.session_name}</div>
                                                                <div className='text-[10px]'>Session Limit : {element && element.max ? element.max : maximum_session_bet}</div>
                                                            </div>
                                                            <div className='col-span-2 flex items-center relative divide-x-[1px] divide-[#3D8282] '>
                                                                {element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ?
                                                                    <div className="w-full h-full text-[#FF5C72] text-center px-2 flex justify-center items-center"
                                                                    >
                                                                        <div><div className='text-base font-bold'>0</div>
                                                                            <div className='text-[10px] font-bold pb-1'> 0.00</div></div></div> : <div className="w-full h-full text-center px-2 cursor-pointer text-[#FF5C72] flex justify-center items-center"
                                                                                onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.oddsNo, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "N", run: element.runsNo, selectionId: element.session_id, time: 20 }, this.section1Ref)}>
                                                                        <div>
                                                                            <div className='text-base font-bold'> {element.runsNo}</div>
                                                                            <div className='text-[10px] font-bold'>{Math.floor(element.oddsNo * 100)}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ?
                                                                    <div className="w-full h-full text-[#5957FF] text-center px-2 flex justify-center items-center"
                                                                    > <div> <div className='text-base font-bold'>0</div>
                                                                            <div className='text-[10px] font-bold pb-1'> 0.00</div></div></div> :
                                                                    <div className="w-full h-full text-center px-2 cursor-pointer text-[#5957FF] flex justify-center items-center"
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.oddsYes, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: element.runsYes, selectionId: element.session_id, time: 30 }, this.section1Ref)}>
                                                                        <div>
                                                                            <div className='text-base font-bold'> {element.runsYes}</div>
                                                                            <div className='text-[10px] font-bold'> {Math.floor(element.oddsYes * 100)}</div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <marquee className="w-full col-span-5 border-t font-bold red-text  border-[#3D8282]">
                                                                {element.remark}
                                                            </marquee>
                                                        </div>
                                                    ))) : null}
                                            </div>
                                            : null}
                                    </> : null}

                                {isTieOdds ?
                                    <>
                                        {Object.values(lastdata).map((elementMatchOdds) => elementMatchOdds.marketType === "Match Odds" && (
                                            elementMatchOdds && elementMatchOdds.runners && elementMatchOdds.runners.length <= 2 ?
                                                <>
                                                    {Object.values(lastdata).map((element, index) => element.marketType === "Tied Match" && (
                                                        <div className="min-w-full border-[1px] border-[#3D8282] md:text-base text-sm" key={index}>
                                                            <>
                                                                <div className='grid grid-cols-5 white-text border-b-[1px] border-[#3D8282] divide-x-[1px] divide-[#3D8282]'>
                                                                    <div className="col-span-3 flex md:flex-row flex-col justify-between items-center text-center background-color p-[2px] text-xs font-semibold px-2">
                                                                        <span className='uppercase'>{element && element.marketType
                                                                            ? element.marketType
                                                                            : "NA"}</span>
                                                                        <span className='font-bold text-sm  '>Min: {minimum_match_bet ? minimum_match_bet : "100"} Max: {maximum_match_bet ? maximum_match_bet : "100000"}</span>
                                                                        <span></span>
                                                                    </div>
                                                                    <div className='col-span-2 grid grid-cols-2  divide-x divide-[#3D8282]'>
                                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">Back</div>
                                                                        <div className="background-color text-center white-text font-bold text-sm p-[2px]">Lay</div>
                                                                    </div>
                                                                </div>

                                                                {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (


                                                                    <div key={index} className="background-white grid md:grid-cols-5 grid-cols-4 text-[#49494A] border-b-[1px] border-gray-500 divide-x-[1px] background-white divide-gray-500">
                                                                        <div className="col-span-3 flex justify-between items-center p-2">
                                                                            <span className='font-semibold text-sm md:h-6 h-5 truncate'>{elementtemp.selectionName}</span>
                                                                            <div key={index} className=' flex justify-center items-center text-center '>
                                                                                <span key={index} className={`${returnDataObject[elementtemp.selectionId] > 0 ? 'text-green-800' : returnDataObject[elementtemp.selectionId] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-base text-center  `} >
                                                                                    {returnDataObject[elementtemp.selectionId] !== undefined && returnDataObject[elementtemp.selectionId] !== null && returnDataObject[elementtemp.selectionId] !== "" ? returnDataObject[elementtemp.selectionId] : "0"}
                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-span-2'>
                                                                            <div className='w-full grid grid-cols-2 divide-x divide-[#3D8282] text-base'>
                                                                                <div>
                                                                                    {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                                                        <>
                                                                                            {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    {tempData.price === 0 ?
                                                                                                        <div className="w-full text-[#5957FF] text-center px-2"
                                                                                                        >  <div className='text-base font-bold'>0</div>
                                                                                                            <div className='text-[10px] font-bold pb-1'> 0.00</div></div> : <div className="w-full text-center px-2 cursor-pointer text-[#5957FF]"
                                                                                                                onClick={() => this.handleBackOpen({ data: tempData, type: "Yes", odds: tempData.price, name: elementtemp.selectionName, betFor: "tiedMatch", oddsType: element.marketType, betType: "L", selectionId: elementtemp.selectionId, betfairMarketId: element.marketId }, this.section1Ref)}>
                                                                                                            <div className='text-base font-bold'> {tempData.price}</div>
                                                                                                            <div className='text-[10px] font-bold'> {Math.floor(tempData.size)}</div>
                                                                                                        </div>
                                                                                                    }

                                                                                                </React.Fragment>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="bg-white bg-opacity-5 ">
                                                                                            <div className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <div>
                                                                                    {elementtemp &&
                                                                                        elementtemp.ex &&
                                                                                        elementtemp.ex.availableToLay &&
                                                                                        elementtemp.ex.availableToLay.length >
                                                                                        0 ? (
                                                                                        elementtemp.ex.availableToLay.map(
                                                                                            (tempData, index) => (
                                                                                                <React.Fragment>
                                                                                                    {index === 0 ? (
                                                                                                        <>
                                                                                                            {tempData.price === 0 ?
                                                                                                                <div className="w-full text-[#FF5C72] text-center px-2"
                                                                                                                >  <div className='text-base font-bold'>0</div>
                                                                                                                    <div className='text-[10px] font-bold pb-1'> 0.00</div></div> : <div className="w-full text-center px-2 cursor-pointer text-[#FF5C72]"
                                                                                                                        onClick={() => this.handleBackOpen({ data: tempData, type: "No", odds: tempData.price, name: elementtemp.selectionName, betFor: "tiedMatch", oddsType: element.marketType, betType: "K", selectionId: elementtemp.selectionId, betfairMarketId: element.marketId }, this.section1Ref)}>
                                                                                                                    <div className='text-base font-bold'> {tempData.price}</div>
                                                                                                                    <div className='text-[10px] font-bold'> {Math.floor(tempData.size)}</div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            ),
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="bg-white bg-opacity-5 ">
                                                                                            <div className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                                                                Not cound
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )) : null}
                                                            </>

                                                        </div>
                                                    ))}
                                                </> : null))
                                        }
                                    </> : null
                                }

                                {loadingBet === true ?
                                    <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                                        <div className="">
                                            <div className='flex justify-between items-center'>
                                                <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.name ? this.state.betSlipData.name : null} :</div>
                                                <span className='w-full text-left'>
                                                    RATE : {this.state.betSlipData.odds} ({this.state.betSlipData.type})
                                                </span>
                                                <div className='bg-black rounded-full white-text p-2'>
                                                    <CountdownCircleTimer
                                                        isPlaying
                                                        duration={7}
                                                        colors={['#000000']}
                                                        colorsTime={[7]}
                                                        size={20}
                                                        strokeWidth={0}
                                                        className="bg-white p-0"
                                                    >
                                                        {({ remainingTime }) => remainingTime === 7 ? time : time}

                                                    </CountdownCircleTimer>
                                                </div>
                                            </div>

                                            <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                                                <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" />
                                                <div className=' flex justify-center'>
                                                    <button className="bg-[#3A61A2] px-3 py-1 uppercase rounded-full text-white text-[13px] tracking-widest flex justify-center items-center">Done
                                                        <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                                            <div className=" flex items-center justify-center absolute bg-transparent">
                                                                <div className="flex items-center justify-center bg-transparent w-full">
                                                                    <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <div className='row '>
                                                {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                                    <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                                        <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                                    </div>
                                                )) : null}

                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center space-x-1'>
                                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" >Clear Input</button>
                                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {betPlaceModal === true ?
                                            <div className='py-1' ref={this.section1Ref} id="section1" >
                                                <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                                                    <div className="">
                                                        <div className='flex justify-between items-center'>
                                                            <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.name ? this.state.betSlipData.name : null} :</div>
                                                            <span className='w-full text-left'>
                                                                RATE : {this.state.betSlipData.odds} ({this.state.betSlipData.type})
                                                            </span>
                                                            <div className='bg-black rounded-full white-text p-2'>
                                                                <CountdownCircleTimer
                                                                    isPlaying
                                                                    duration={7}
                                                                    colors={['#000000']}
                                                                    colorsTime={[7]}
                                                                    size={20}
                                                                    strokeWidth={0}
                                                                    className="bg-white p-0"
                                                                >
                                                                    {({ remainingTime }) => remainingTime === 7 ? 7 : time}

                                                                </CountdownCircleTimer>
                                                            </div>
                                                        </div>

                                                        <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                                                            <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" value={this.state.betSlipData.stake} onChange={this.updateStake} />
                                                            <div className=' flex justify-center'>
                                                                {this.state.betSlipData.stake > 0 ?
                                                                    <button onClick={() => this.placeBet()} className="bg-[#3A61A2] px-5 py-1 uppercase rounded-full text-white text-[13px] tracking-widest">Done
                                                                    </button> :
                                                                    <button className="bg-[#3A61A2] px-5 py-1 uppercase rounded-full text-white text-[13px] tracking-widest">Done
                                                                    </button>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='flex justify-center items-center'>
                                                        <div className='row '>
                                                            {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                                                <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                                                    <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                                                </div>
                                                            )) : null}

                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between items-center space-x-1'>
                                                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.updateStackOnclick("0")}>Clear Input</button>
                                                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </>}

                            </div>

                            {loadingBet === true ?
                                <>
                                    <div className='block lg:hidden bg-[#E9E9E9]'>
                                        <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                            <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                                            <div className='border-[1px] border-gray-400 w-1/2'>
                                                <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                                            </div>
                                            <div className='flex justify-end items-center'>
                                                <div className='bg-black white-text px-2'>
                                                    <CountdownCircleTimer
                                                        isPlaying
                                                        duration={7}
                                                        colors={['#000000']}
                                                        colorsTime={[7]}
                                                        size={20}
                                                        strokeWidth={0}
                                                        className="bg-white p-0"

                                                    >
                                                        {({ remainingTime }) => remainingTime === 7 ? time : time}
                                                    </CountdownCircleTimer>
                                                </div>
                                                <div className=' flex justify-center px-2'>
                                                    <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold flex justify-center items-center">Done
                                                        <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                                            <div className=" flex items-center justify-center absolute bg-transparent">
                                                                <div className="flex items-center justify-center bg-transparent w-full">
                                                                    <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {betPlaceModal === true ?
                                        <div ref={this.section1Ref} id="section1">
                                            <div className='lg:hidden block bg-[#E9E9E9]' >
                                                <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                                    <div className='text-sm font-bold pl-2'>Amount</div>
                                                    <div className='border-[1px] border-gray-400 w-1/2'>
                                                        <input ref={this.inputRef} type="text" autoFocus  // or type="text"
                                                            autoCapitalize="none" className=" w-full black-text bg-[#E9E9E9] text-base focus:outline-none cursor-pointer placeholder-black px-1" placeholder="0" name="stake" onChange={this.inputChange} list="stake" inputMode="numeric" />
                                                        <datalist id="stake" className='bg-black white-text'>
                                                            {betChipsData.map((element, index) => (
                                                                <option key={index} value={element ? element : 0} />
                                                            ))}
                                                        </datalist>
                                                    </div>

                                                    <div className='flex justify-end items-center text-base'>
                                                        <div className='bg-black white-text px-2'>
                                                            <CountdownCircleTimer
                                                                isPlaying
                                                                duration={7}
                                                                colors={['#000000']}
                                                                colorsTime={[7]}
                                                                size={20}
                                                                strokeWidth={0}
                                                                className="bg-white p-0"

                                                            >
                                                                {({ remainingTime }) => remainingTime === 7 ? 7 : time}
                                                            </CountdownCircleTimer>
                                                        </div>
                                                        <div className=' flex justify-center px-2'>
                                                            {this.state.betSlipData.stake > 0 ?
                                                                <button onClick={() => this.placeBet()} className="placebet-button px-2 py-1 uppercase rounded white-text font-bold text-[13px] cursor-pointer">Done</button> :
                                                                <button className="placebet-button px-2 py-1 uppercase rounded white-text font-bold text-[13px] cursor-pointer">Done</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <div className='block lg:hidden bg-[#E9E9E9]'>
                                            <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                                <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                                                <div className='border-[1px] border-gray-400 w-1/2'>
                                                    <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                                                </div>
                                                <div className='flex justify-end items-center'>
                                                    <div className='bg-black/30 white-text text-base px-2'>0

                                                    </div>
                                                    <div className=' flex justify-center px-2'>
                                                        <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold">Done</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </>
                            }

                            <div className='lg:w-[60%] md:w-full mx-auto'>
                                {
                                    oddsBetData && oddsBetData.length > 0 ?
                                        <>
                                            <div className='table-head-color text-sm white-text text-center py-1 font-bold'>
                                                <span>MATCH BETS</span>
                                            </div>
                                            <div className='w-full overflow-x-auto scrollbar-hide'>
                                                <table className="w-full md:text-sm text-xs">
                                                    <thead className="rounded-t background-color">
                                                        <tr className='white-text text-center font-semibold '>
                                                            <th className="border-[#3D8282] border-[1px] w-[64%]">TEAM</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[12%]">AMOUNT</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[12%]">RATE</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[12%]">MODE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {oddsBetData && oddsBetData.length > 0 ? oddsBetData.map((element, index) => (
                                                            <tr key={index} className="bg-white text-black ">
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] text-start w-[64%]">{element.teamName}({element.oddsType})</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] text-center w-[12%]">{element.amount}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] w-[12%]">{Math.floor(element.odds * 100)}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] w-[12%]">{element && element.type === "L" ? "LAGAI" : "KHAI"}</td>
                                                            </tr>
                                                        )) : <tr>
                                                            <td colSpan={8} className=' text-[#49494A] font-bold bg-white w-full'>No Bets Available</td>
                                                        </tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </> : null
                                }
                                {
                                    inCompleteFancy && inCompleteFancy.length > 0 ?
                                        <>
                                            <div className='table-head-color text-sm white-text text-center py-1 font-bold'>
                                                <span> FANCY BETS</span>
                                            </div>
                                            <div className='w-full overflow-x-auto scrollbar-hide'>
                                                <table className="w-full md:text-sm text-xs">
                                                    <thead className="rounded-t background-color">
                                                        <tr className='white-text text-center font-semibold'>
                                                            <th className="border-[#3D8282] border-[1px] w-[60%]">RUNNER</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[10%]">AMOUNT</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[10%]">RUN</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[10%]">RATE</th>
                                                            <th className="border-[#3D8282] border-[1px] w-[10%]">MODE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {inCompleteFancy && inCompleteFancy.length > 0 ? inCompleteFancy.map((element, index) => (
                                                            <tr key={index} className="bg-white text-black ">
                                                                <td className="font-medium text-start border-[#3D8282] border-[1px] w-[60%]">{element.sessionName}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] text-center  w-[10%]">{element.amount}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] w-[10%]">{element.run}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] w-[10%]">{Math.floor(element.odds * 100)}</td>
                                                                <td className="font-medium whitespace-nowrap border-[#3D8282] border-[1px] w-[10%]">{element && element.type === "N" ? "NO" : "YES"}</td>
                                                            </tr>
                                                        )) : <tr>
                                                            <td colSpan={8} className=' text-[#49494A] font-bold bg-white w-full'>No Bets Available</td>
                                                        </tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </> : null}

                                <div className='table-head-color text-sm white-text text-center py-1 font-bold'>
                                    <span> COMPLETED FANCY BETS</span>
                                </div>
                                <div className='w-full overflow-x-auto scrollbar-hide'>
                                    <table className="w-full text-sm">
                                        <thead className="rounded-t background-color">
                                            <tr className='white-text text-center font-semibold'>
                                                <th className="border-[#3D8282] border-[1px] w-[51%]">RUNNER</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">AMOUNT</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">RUN</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">RATE</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">MODE</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">DATE</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">Result</th>
                                                <th className="border-[#3D8282] border-[1px] w-[7%]">P&L</th>

                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {showCompletedFancy && showCompletedFancy.length > 0 ? showCompletedFancy.map((element, index) => (
                                                <>
                                                    <tr key={index} className="bg-white text-black">
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] test-start w-[51%]">{element.sessionName}</td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] text-center w-[7%]">{element.amount}</td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%]">{element.run}</td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%]">{Math.floor(element.odds * 100)}</td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%]">{element && element.type === "N" ? "NO" : "YES"}</td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%]">
                                                            {element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss") : ''}
                                                        </td>
                                                        <td className="font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%]">{element.decisionRun}</td>
                                                        <td
                                                            className={`font-semibold whitespace-nowrap border-[#3D8282] border-[1px] w-[7%] ${(element.pos < 0)
                                                                ? 'text-red-500'
                                                                : 'text-green-800'
                                                                }`}
                                                        >
                                                            {Math.round(Math.abs(element.pos))}
                                                        </td>
                                                    </tr>
                                                </>
                                            )) : <tr>
                                                <td colSpan={8} className=' text-[#49494A] font-bold bg-white w-full'>No Bets Available</td>
                                            </tr>
                                            }
                                            {showCompletedFancy && showCompletedFancy.length > 0 ?
                                                <tr className="bg-white text-black ">
                                                    <td colSpan={6} className='font-medium border-[#3D8282] border-[1px]'>Total Plus Minus</td>
                                                    <td
                                                        className={`font-semibold whitespace-nowrap border-[#3D8282] border-[1px] ${(sessionPlusMinus < 0)
                                                            ? 'text-red-500'
                                                            : 'text-green-800'
                                                            }`}
                                                    >
                                                        {Math.round(Math.abs(sessionPlusMinus))}
                                                    </td>
                                                </tr> : null
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className='lg:w-[60%] md:w-full mx-auto'>
                                <ul className=' white-text text-[13px] uppercase'>
                                    {inplayMatchList && inplayMatchList.length > 0 ? inplayMatchList.map(
                                        (elementinner, index) => (
                                            <div key={index}>
                                                {matchMarketId !== elementinner.marketId ? (
                                                    <li className='md:px-2 px-0 flex justify-start items-center w-full text-[#2789CE] font-bold cursor-pointer border-b border-[#3D8282]'>
                                                        <a href={`/#/app/ViewMatchis/${elementinner.marketId}`} className=' w-full h-[60px] py-1'

                                                            onClick={() => {
                                                                window.location.href = `/#/app/ViewMatchis/${elementinner.marketId}`;
                                                                window.location.reload(true);
                                                            }}>
                                                            <div className='flex text-[#2789CE] justify-start items-center '> <GoDotFill className='text-green-800 blinking' size={25} /><div className='truncate'>{elementinner.matchName}</div></div>
                                                            <div className='text-[10px] text-[#777777] pl-4' style={{ fontWeight: 900 }}>
                                                                {/* {moment(elementinner.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMMM hh:mm A')} */}
                                                                {elementinner.matchDate}
                                                            </div>
                                                        </a>
                                                    </li>
                                                ) : null}
                                            </div>
                                        )
                                    ) : null}
                                </ul>

                            </div>
                        </section>
                    </main >
                </div >
            </div >
        );
    }
}
function mapStateToProps(state) {
    const { users, sport } = state;
    return {
        users,
        sport,
    };
}
export default connect(mapStateToProps)(ViewMatchDetails);