import React from "react";
import { BiWorld } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { IoSpeedometerOutline } from "react-icons/io5";


const Seetting = (props) => {

    const onClickMenu = (url) => {
        props.history.push(url)
    }

    // let user = JSON.parse(localStorage.getItem('spuser'));
    return (
        <div className="mainclass h-screen">

            <div className="flex justify-center items-center bg-[#E9E9E9] h-screen w-full text-[#464242] ">
                <div className="space-y-3">
                    <div onClick={() => onClickMenu('/app/language')} className="flex justify-start items-center divide-x divide-gray-500 bg-white space-x-4 p-3 active:text-blue-500">
                        <BiWorld className="w-10 h-10" />
                        <p className="pr-16 pl-2 text-lg">Language</p>
                    </div>
                    <div className="flex justify-start items-center divide-x divide-gray-500 bg-white space-x-4 p-3 active:text-blue-500">
                        <IoMdSettings className="w-10 h-10" />
                        <p className="pr-16 pl-2 text-lg">Others</p>
                    </div>
                    <div className="flex justify-start items-center divide-x divide-gray-500 bg-white space-x-4 p-3 active:text-blue-500">
                        <IoSpeedometerOutline className="w-10 h-10" />
                        <p className="pr-16 pl-2 text-lg">Other Settings</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Seetting;
