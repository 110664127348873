import React from "react";


const Language = () => {
    return (
        <div className="mainclass">
            <div className=''>
            </div>
        </div>
    );
};

export default Language;
