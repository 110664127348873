import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
// import "./changepassword.css";

const ChangePassword = ({ dispatch, props }) => {
  const [fieldsChangePassword, setFieldsChangePassword] = useState({});
  const [errorsChangePassword, setErrorsChangePassword] = useState({});
  const [domainSettingByDomainName, setdomainSettingByDomainName] = useState()
  const [isFetching, setIsFetching] = useState(false);

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsChangePassword((prevState) => ({ ...prevState, [name]: value }));
    setErrorsChangePassword((prevState) => ({ ...prevState, [name]: '' }));
  };

  useEffect(() => {
    const getDomainSettingByDomainName = () => {
      try {
        let domainSetting = localStorage.getItem('domainSettingByDomainName');
        if (domainSetting) {
          const parsedDomainSetting = JSON.parse(domainSetting);
          setdomainSettingByDomainName(parsedDomainSetting);
        } else {
          setdomainSettingByDomainName(null);
        }
      } catch {
        console.error('Error parsing domainSettingByDomainName:');
        setdomainSettingByDomainName(null);
      }
    };

    getDomainSettingByDomainName();
  }, []);

  const history = useHistory();

  const changePassword = () => {

    if (handleValidationChangePassword()) {
      const changePasswordData = {
        oldPassword: fieldsChangePassword.currentpassword,
        password: fieldsChangePassword.newPassword,
        // transactionPassword: fieldsChangePassword.oldPassword,
      };
      // console.log("changePasswordDatachangePasswordData", changePasswordData);
      setIsFetching(true);
      dispatch(userActions.changePassword(changePasswordData, history));
    }
    const timeoutId = setTimeout(() => {
      setIsFetching(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  };

  const handleValidationChangePassword = () => {
    let errorsChangePassword = {};
    let formIsValid = true;

    if (!fieldsChangePassword.currentpassword || fieldsChangePassword.currentpassword === '') {
      formIsValid = false;
      errorsChangePassword.currentpassword = 'Old Password Cannot Be Blank.';
    }

    if (!fieldsChangePassword.newPassword || fieldsChangePassword.newPassword === '') {
      formIsValid = false;
      errorsChangePassword.newPassword = 'New Password Cannot Be Blank.';
    }
    // confirmPassword
    if (!fieldsChangePassword["confirmnewpassword"] || fieldsChangePassword["confirmnewpassword"] === "") {
      formIsValid = false;
      errorsChangePassword["confirmnewpassword"] = "Confirm Password Cannot Be Blank";
    } else if (fieldsChangePassword["newPassword"] !== fieldsChangePassword["confirmnewpassword"]) {
      formIsValid = false;
      errorsChangePassword["confirmnewpassword"] = "Passwords and Confirm Password do not match";
    } else {
      errorsChangePassword["confirmnewpassword"] = "";
    }
    console.error("errorsChangePassworderrorsChangePassworderrorsChangePassworderrorsChangePassword:::", errorsChangePassword);
    setErrorsChangePassword(errorsChangePassword);
    return formIsValid;
  };

  let clientNotification = JSON.parse(localStorage.getItem('notification'))

  return (
    <>
      {isFetching ? <Loader /> : null}
      <div className={`w-full h-screen ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[98px] pt-[98px]" : "md:pt-[72px] pt-[70px]"}`}>

        <div className='flex justify-center px-[35px] w-full'>
          <div className='w-full md:w-[320px] background-color-cancel rounded-xl'>

            <div className='w-full background-color-cancel h-20 flex justify-center items-center rounded-xl'>
              <h1 className="text-center white-text font-semibold text-[23px] tracking-wider capitalize">change password</h1>
            </div>
            <div className='p-4'>
              <div className='space-y-4'>
                <div className="form-group">
                  <div className="flex justify-center px-2 drop-shadow-sm">
                    <input className='drop-shadow-md shadow-gray-900 w-full p-1.5 py-2 focus:outline-none placeholder:uppercase text-sm placeholder:tracking-wider md:font-bold font-medium text-[#1E1E1E] rounded-md'
                      type="password"
                      name="currentpassword"
                      id="currentpassword"
                      placeholder="Old Password"
                      value={fieldsChangePassword.currentpassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["currentpassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["currentpassword"]}
                      </div>
                    </div>
                    : null}

                </div>
                <div className="form-group">
                  <div className="flex justify-center  px-2">
                    <input className='drop-shadow-md shadow-gray-900 w-full p-1.5 py-2 focus:outline-none placeholder:uppercase text-sm placeholder:tracking-wider md:font-bold font-medium text-[#1E1E1E] rounded-md'
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      placeholder="New Password"
                      value={fieldsChangePassword.newPassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["newPassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["newPassword"]}
                      </div>
                    </div>
                    : null}
                </div>

                <div className="form-group ">
                  <div className="flex justify-center  px-2">
                    <input className='drop-shadow-md shadow-gray-900 w-full p-1.5 py-2 focus:outline-none placeholder:uppercase text-sm placeholder:tracking-wider md:font-bold font-medium text-[#1E1E1E] rounded-md'
                      type="password"
                      name="confirmnewpassword"
                      id="confirmnewpassword"
                      placeholder="Confirm Password"
                      value={fieldsChangePassword.confirmnewpassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["confirmnewpassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["confirmnewpassword"]}
                      </div>
                    </div>
                    : null}
                </div>
                <div className="form-group flex justify-center px-[72px] md:px-4 py-3">
                  <button type="button" className="change-password-button white-text text-sm font-medium p-2 rounded-full tracking-widest" onClick={changePassword}>Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <div className=' w-full min-h-screen mainclass'>
        <div className='flex justify-center'>
          <div className='w-full'>
            <div className='w-full justify-center flex pt-[4rem] rounded-[4px]'>
              <div className='border-2 border-[#007bff] rounded-lg  lg:w-[650px] md:w-[600px] password-item sm:w-72'>
                <div className='flex justify-center items-center'>
                  <img src={treeexchcom} alt="" className="w-48" />
                </div>
                <div className='px-4 space-y-2'>
                  <div className="form-group">
                    <input id="oldpassword"
                      className='w-full border-2 b py-2order-[#007bff]  p-1.5 focus:outline-none text-base md:text-[11px] font-medium text-gray-400 rounded'
                      type="password"
                      name="oldPassword"
                      placeholder='Old Password'
                      value={fieldsChangePassword.oldPassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["oldPassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["oldPassword"]}
                      </div>
                    </div>
                    : null}

                  <div className="form-group">
                    <input className='w-full  py-2border-2 border-[#007bff]  p-1.5 focus:outline-none md:text-[11px] text-base font-medium text-gray-400 rounded'
                      type="password"
                      name="password"
                      id="password"
                      placeholder='New Password'
                      value={fieldsChangePassword.password}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["password"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["password"]}
                      </div>
                    </div>
                    : null}

                  <div className="form-group ">
                    <input className='w-full border-2 b py-2order-[#007bff]  p-1.5 focus:outline-none text-base md:text-[11px] font-medium text-gray-400 rounded'
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder='Retype Password'
                      value={fieldsChangePassword.confirmPassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["confirmPassword"] ?
                    <div className="flex justify-center">
                      <div className="text-red-700 w-full ">
                        {errorsChangePassword["confirmPassword"]}
                      </div>
                    </div>
                    : null}
                  <div className="form-group space-x-2 py-3">
                    <button onClick={changePassword} type="button" className="bg-[#5CB85C] w-full border-2 border-[#007bff] white-text text-lg uppercase font-bold px-4 py-1 rounded tracking-widest">Submit</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='lg:col-span-4 col-span-0'></div>
        </div>
      </div> */}












      {/* <div className=' w-full min-h-screen mainclass '>
        <div className='flex space-x-1 p-1'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='grid grid-cols-12 pt-20'>
            <div className='lg:col-span-4 col-span-0 '></div>
            <div className='w-full lg:col-span-4 col-span-12'>
              <div className='w-full p-4 pt-8 rounded-[4px]'>
                <div className='border-2 border-[#007bff] rounded-lg lg:w-[450px] md:w-[550px] w-[300px]'>
                  <div className='flex justify-center items-center'>
                    <img src={treeexchcom} alt="" className="w-48" />
                  </div>
                  <div className='px-4 space-y-2'>
                    <div className="form-group">
                      <input id="oldpassword"
                        className='w-full border-2 b py-2order-[#007bff]  p-1.5 focus:outline-none text-base md:text-[11px] font-medium text-gray-400 rounded'
                        type="password"
                        name="oldPassword"
                        placeholder='Old Password'
                        value={fieldsChangePassword.oldPassword}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["oldPassword"] ?
                      <div className="flex justify-center">
                        <div className="text-red-700 w-full ">
                          {errorsChangePassword["oldPassword"]}
                        </div>
                      </div>
                      : null}

                    <div className="form-group">
                      <input className='w-full  py-2border-2 border-[#007bff]  p-1.5 focus:outline-none md:text-[11px] text-base font-medium text-gray-400 rounded'
                        type="password"
                        name="password"
                        id="password"
                        placeholder='New Password'
                        value={fieldsChangePassword.password}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["password"] ?
                      <div className="flex justify-center">
                        <div className="text-red-700 w-full ">
                          {errorsChangePassword["password"]}
                        </div>
                      </div>
                      : null}

                    <div className="form-group ">
                      <input className='w-full border-2 b py-2order-[#007bff]  p-1.5 focus:outline-none text-base md:text-[11px] font-medium text-gray-400 rounded'
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder='Retype Password'
                        value={fieldsChangePassword.confirmPassword}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["confirmPassword"] ?
                      <div className="flex justify-center">
                        <div className="text-red-700 w-full ">
                          {errorsChangePassword["confirmPassword"]}
                        </div>
                      </div>
                      : null}
                    <div className="form-group space-x-2 py-3">
                      <button onClick={changePassword} type="button" className="bg-[#5CB85C] w-full border-2 border-[#007bff] white-text text-lg uppercase font-bold px-4 py-1 rounded tracking-widest">Submit</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='lg:col-span-4 col-span-0'></div>
          </div>
        </div>
      </div> */}


    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(ChangePassword));