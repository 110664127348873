import React, { useState } from 'react';
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import AmarAkbarAnthony from "../../pages/AmarAkbarAnthony/amarakbaranthony";
import AccountStatement from "../../pages/AccountStatement/AccountStatement";
import Dashboard from "../../pages/Dashboard/Dashboard";
import changepassword from '../../pages/ChangePassword/changepassword';
import Footer from '../../components/Footer';
import Profile from '../../pages/Profile/Profile';
import TotalLedger from '../../pages/TotalLegder/TotalLegder';
import ViewMatch from '../../pages/ViewMatch/ViewMatch';
import Matka from '../../pages/Matka/Matka';
import login_auth from '../../pages/login_auth/login_auth';
import Matches from '../../pages/Matches/Matches';
import FreeGames from '../../pages/FreeGames/FreeGames';
import Seetting from '../../pages/Seetting/Seetting';
import Language from '../../pages/Language/Language';
import LegderDetails from '../../pages/LegderDetails/LegderDetails';
import IframeCasino from '../../pages/IframeCasino/IframeCasino';
import IframeAviator from '../../pages/IframeAviator/IframeAviator';
import Casino from '../../pages/Casino/Casino';
import DragonTiger20Theme2 from '../../pages/DragonTiger20Theme2/DragonTiger20theme2';
import AndarBahar2Theme2 from '../../pages/AndarBahar2Theme2/AndarBahar2theme2';
import TeenpattiT20Theme2 from '../../pages/TeenpattiT20Theme2/teenpattit20theme2';
import Luky7ATheme2 from '../../pages/Luky7ATheme2/Luky7Atheme2';
import InstantWorliTheme2 from '../../pages/InstantWorliTheme2/InstantWorliTheme2';
import DragonTiger202Theme2 from '../../pages/DragonTiger202Theme2/DragonTiger202Theme2';
import LegderDetailsCasino from '../../pages/LegderDetailsCasino/LegderDetailsCasino';
import TeenpattiOneDayTheme2 from '../../pages/TeenpattiOneDayTheme2/TeenpattiOneDayTheme2';

function Layout(props) {
  const [open, setOpen] = useState(false);

  const [openMobile, setOpenMobile] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="overflow-hidden h-screen" style={{ backgroundImage: "url(/images/bodybg.jpg)" }}>
      <div className="overflow-hidden">
        <Header history={props.history}
          setOpen={setOpen} open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          openModal={openModal} setOpenModal={setOpenModal}
        />
        <div>
          {/* <div className=" flex h-screen overflow-hidden"> */}
          <div className={`w-full h-[calc(100%-5rem)] `}>
            <Switch>
              <Route path="/app/accountstatement" component={withRouter(AccountStatement)} />
              <Route path="/app/matches" component={withRouter(Matches)} />
              <Route path="/app/iframe-casino" component={withRouter(IframeCasino)} />
              <Route path="/app/casino" component={withRouter(Casino)} />
              <Route path="/app/iframe-aviator" component={withRouter(IframeAviator)} />
              <Route path="/app/language" component={withRouter(Language)} />
              <Route path="/app/freegames" component={withRouter(FreeGames)} />
              <Route path="/app/setting" component={withRouter(Seetting)} />
              <Route path="/app/login_auth" component={withRouter(login_auth)} />
              <Route path="/app/ViewMatchis/:marketId?/:eventId?" component={withRouter(ViewMatch)} />
              <Route path="/app/legder-details/:marketId" component={withRouter(LegderDetails)} />
              <Route path="/app/legder-details-casino/:marketId?/:ledgerType?/:date?" component={withRouter(LegderDetailsCasino)} />


              <Route path="/app/aaa/:eventId" component={withRouter(AmarAkbarAnthony)} />
              <Route path="/app/dt20/:eventId" component={withRouter(DragonTiger20Theme2)} />
              <Route path="/app/dt202/:eventId" component={withRouter(DragonTiger202Theme2)} />
              <Route path="/app/ab2/:eventId" component={withRouter(AndarBahar2Theme2)} />
              <Route path="/app/teen20/:eventId" component={withRouter(TeenpattiT20Theme2)} />
              <Route path="/app/lucky7/:eventId" component={withRouter(Luky7ATheme2)} />
              <Route path="/app/worli2/:eventId" component={withRouter(InstantWorliTheme2)} />
              <Route path="/app/teen/:eventId" component={withRouter(TeenpattiOneDayTheme2)} />



              <Route path="/app/TotalLedger" component={withRouter(TotalLedger)} />
              <Route path="/app/Profile" component={withRouter(Profile)} />
              <Route path="/app/dashboard/:gameId?/:seriesId?" component={withRouter(Dashboard)} />
              <Route path="/app/changepassword" component={withRouter(changepassword)} />
              <Route path="/app/matka" component={withRouter(Matka)} />
            </Switch>
          </div>
        </div>
        <Footer props={props} />

      </div>
    </div>
  );
}

export default withRouter(Layout);
