import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import axios from "axios";
import BetLockedRounded from "../../components/BetLockedRounded/BetLockedRounded";
import io from 'socket.io-client';
import FlipCountdown from '@rumess/react-flip-countdown';
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import { httpPost } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../components/ErrorTost/ErrorTost';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { betChipsData } from '../../_config';


class InstantWorliTheme2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      betOpen: false,
      backBetModal: false,
      backBetModalMobile: false,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      Result: false,
      showLoader: true,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "worli",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      betChipsData: [],
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  checkWindowWidth() {
    const isMobile = window.matchMedia('(max-width: 100vw)').matches;

    if (isMobile && this.inputRef.current) {
      setTimeout(() => {
        this.inputRef.current.focus();
      }, 0);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  componentDidMount() {

    let requestData = {
      eventId: this.props.match.params.eventId,
    }
    this.getDidMountData(requestData)
    this.betChipData()
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('touchstart', this.handleTouchStart);
    }
    if (window.innerWidth <= 768 && this.inputRef.current) {
      this.inputRef.current.focus();
      this.inputRef.current.dispatchEvent(new Event('touchstart'));
    }
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "worli",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails.eventId) {
        this.betList(casinoDetails.eventId)
      }

      const socketPerm = casinoDetails.fetchData
      if (socketPerm === "socket") {
        await this.callSocket(casinoDetails.socketURL, casinoDetails.shortName)
      } else {
        await this.callCache(casinoDetails.cacheURL)
      }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }

  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  handleBackOpen = (data, sectionRef) => {
    try {
      this.setState({
        backBetModal: false,
      });

      if (this.scrollTimeout) {
        clearInterval(this.scrollTimeout);
      }

      this.setState({
        backBetModal: true,
        betSlipData: { ...data, stake: "0" },
        count: data.odds,
        teamname: data.name,
        time: 7,
      });

      this.scrollTimeout = setInterval(() => {
        this.setState(prevState => ({ time: prevState.time - 1 }), () => {
          if (this.state.time === 0) {
            clearInterval(this.scrollTimeout);
            this.setState({ backBetModal: false });
          }
        });
      }, 1000);

      setTimeout(() => {
        if (sectionRef && sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });

          if (window.innerWidth <= 768 && this.inputRef.current && sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
              this.inputRef.current.focus();
            }, 0);
          }
        }
      }, 0);
    } catch (error) {
      console.error('Error handling back open:', error);
    }
  };

  betChipData = () => {
    try {
      let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
      const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
      this.setState({ betChipsData: myArray });
    } catch (error) {
      console.error('Error parsing JSON from localStorage: betChipsDatas', error);
      this.setState({ betChipsData: betChipsData });
    }
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid + "",
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "worli",
      "eventId": this.props.match.params.eventId,
      "betFor": this.state.betSlipData.nat.split(' ')[0],
      "nat": this.state.betSlipData.nat.split(' ')[0]
    }

    const result = await httpPost("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        toast.success(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        });
        this.betList(this.props.match.params.eventId)
        this.props.dispatch(userActions.getUserBalance());
      }
      else {
        toast.error(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleClose = () => {
    this.setState({ newCasinoModal: false, betOpen: false, stakeopen: false, backBetModal: false, backBetModalMobile: false, });
  };



  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, betChipsData } = this.state;
    const { data, result } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let ENG = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    return (
      <>
        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`overflow-auto h-screen scroll-smooth ${clientNotification && clientNotification.length > 0 ? "md:pt-[105px] pt-[100px]" : "md:pt-[72px] pt-[70px]"}`}>
            {this.state &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Instant WORLI"}
                shortName={shortName ? shortName : "worli"}
                result={this.state.result}
              />
            ) : null}

            {/* {LoadingBet === true ?
              <>
                <PlaceBetMobileBmxBet
                  betSlipData={this.state.betSlipData}
                  time={time}
                  count={count}
                />
              </> :
              <>
                {backBetModal && backBetModal === true ? (
                  <PlaceBetMobileBmx
                    betSlipData={this.state.betSlipData}
                    updateStackOnclic={this.updateStackOnclic}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    section1Ref={this.section1Ref}
                    time={time}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    count={count}
                  />
                ) : null}
              </>
            } */}


            <div className=" w-full">
              <div className="lg:flex w-full h-screen p-1.5 space-x-1.5">
                <div className="lg:w-[41%] md:w-full mx-auto">
                  <PageHeaderTheme
                    PageTitle={name ? name : "Instant WORLI"}
                    ruleImage={"/rulesImage/tp-rules.webp"}
                    t1={t1}
                  />
                  <div className="bg-black flex justify-between w-full relative xl:h-[400px] md:h-[350px] h-[250px]">
                    <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                    <div className=" flex justify-between">
                      <div className=" absolute top-0 left-0">
                        <div className="flex justify-start space-x-1 p-2">
                          <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-12 w-10" />
                          <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-12 w-10" />
                          <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="h-12 w-10" />
                        </div>
                      </div>

                      <div className="flex justify-end items-end absolute md:bottom-2 bottom-4 right-0 ">
                        <FlipCountdown
                          titlePosition='hidden'
                          hideYear
                          hideMonth
                          hideDay
                          hideHour
                          hideMinute
                          size="small"
                          endAtZero
                          endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : "")).toUTCString()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="relative">
                    <div className="flex justify-between items-center text-center">
                      <p></p>
                      <p>{9.5 - oddsDifference}</p>
                      <p></p>
                    </div>
                    <div class="w-full grid grid-cols-5 divide-x divide-y p-1">
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '1 Single', sid: 1 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">1</p>
                        </div>
                        <div className={`${posArray[1] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[1] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '2 Single', sid: 2 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">2</p>
                        </div>
                        <div className={`${posArray[2] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[2] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '3 Single', sid: 3 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">3</p>
                        </div>
                        <div className={`${posArray[3] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[3] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '4 Single', sid: 4 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">4</p>
                        </div>
                        <div className={`${posArray[4] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[4] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '5 Single', sid: 5 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">5</p>
                        </div>
                        <div className={`${posArray[5] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[5] ?? 0.00}</div>
                      </div>
                    </div>
                    <div class="w-full grid grid-cols-5  divide-x divide-y p-1">

                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '6 Single', sid: 6 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">6</p>
                        </div>
                        <div className={`${posArray[6] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[6] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '7 Single', sid: 7 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">7</p>
                        </div>
                        <div className={`${posArray[7] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[7] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '8 Single', sid: 8 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">8</p>
                        </div>
                        <div className={`${posArray[8] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[8] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '9 Single', sid: 9 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">9</p>
                        </div>
                        <div className={`${posArray[9] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[9] ?? 0.00}</div>
                      </div>
                      <div>
                        <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '0 Single', sid: 0 }, this.section1Ref)} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                          <p className="text-[40px] font-semibold">0</p>
                        </div>
                        <div className={`${posArray[0] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[0] ?? 0.00}</div>
                      </div>
                    </div>
                    {ENG && ENG.gstatus === '1' ? null : <BetLockedRounded />}
                  </div>

                  {LoadingBet === true ?
                    <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                      <div className="">
                        <div className='flex justify-between items-center'>
                          <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betFor && this.state.betFor ? this.state.betFor : null} :</div>
                          <span className='w-full text-left'>
                            RATE : {this.state.count - this.state.oddsDifference}
                          </span>
                          <div className='bg-black rounded-full white-text p-2'>
                            <CountdownCircleTimer
                              isPlaying
                              duration={7}
                              colors={['#000000']}
                              colorsTime={[7]}
                              size={20}
                              strokeWidth={0}
                              className="bg-white p-0"
                            >
                              {({ remainingTime }) => remainingTime === 7 ? time : time}

                            </CountdownCircleTimer>
                          </div>
                        </div>

                        <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                          <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" />
                          <div className='relative flex justify-center'>
                            <button className="bg-[#3A61A2] px-3 py-1 uppercase rounded-full text-white text-[13px] tracking-widest flex justify-center items-center">Done
                              <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                <div className=" flex items-center justify-center absolute bg-transparent">
                                  <div className="flex items-center justify-center bg-transparent w-full">
                                    <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                  </div>
                                </div>
                              </button>
                            </button>
                          </div>
                        </div>

                      </div>
                      <div className='flex justify-center items-center'>
                        <div className='row '>
                          {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                            <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                              <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                            </div>
                          )) : null}

                        </div>
                      </div>
                      <div className='flex justify-between items-center space-x-1'>
                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" >Clear Input</button>
                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                      </div>
                    </div>
                    :
                    <>
                      {backBetModal === true ?
                        <div className='py-1' ref={this.section1Ref} id="section1" >
                          <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                            <div className="">
                              <div className='flex justify-between items-center'>
                                <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.nat ? this.state.betSlipData.nat : null} :</div>
                                <span className='w-full text-left'>
                                  RATE : {Number(this.state.betSlipData.rate) - Number(this.state.oddsDifference)}
                                </span>
                                <div className='bg-black rounded-full white-text p-2'>
                                  <CountdownCircleTimer
                                    isPlaying
                                    duration={7}
                                    colors={['#000000']}
                                    colorsTime={[7]}
                                    size={20}
                                    strokeWidth={0}
                                    className="bg-white p-0"
                                  >
                                    {({ remainingTime }) => remainingTime === 7 ? 7 : time}

                                  </CountdownCircleTimer>
                                </div>
                              </div>

                              <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                                <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" value={this.state.betSlipData.stake} onChange={this.updateStake} />
                                <div className=' flex justify-center'>
                                  <button onClick={() => this.placeBet()} className="bg-[#3A61A2] px-5 py-1 uppercase rounded-full text-white text-[13px] tracking-widest">Done
                                  </button>
                                </div>
                              </div>

                            </div>
                            <div className='flex justify-center items-center'>
                              <div className='row '>
                                {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                  <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                    <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                  </div>
                                )) : null}

                              </div>
                            </div>
                            <div className='flex justify-between items-center space-x-1'>
                              <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.updateStackOnclick("0")}>Clear Input</button>
                              <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                            </div>
                          </div>
                        </div>
                        : null}
                    </>}

                  {LoadingBet === true ?
                    <>
                      <div className='block lg:hidden bg-[#E9E9E9]'>
                        <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                          <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                          <div className='border-[1px] border-gray-400 w-1/2'>
                            <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                          </div>
                          <div className='flex justify-end items-center'>
                            <div className='bg-black white-text px-2'>
                              <CountdownCircleTimer
                                isPlaying
                                duration={7}
                                colors={['#000000']}
                                colorsTime={[7]}
                                size={20}
                                strokeWidth={0}
                                className="bg-white p-0"

                              >
                                {({ remainingTime }) => remainingTime === 7 ? time : time}
                              </CountdownCircleTimer>
                            </div>
                            <div className='relative flex justify-center px-2'>
                              <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold flex justify-center items-center">Done
                                <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                  <div className=" flex items-center justify-center absolute bg-transparent">
                                    <div className="flex items-center justify-center bg-transparent w-full">
                                      <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                    </div>
                                  </div>
                                </button>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {backBetModal === true ?
                        <div ref={this.section1Ref} id="section1">
                          <div className='lg:hidden block bg-[#E9E9E9]' >
                            <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                              <div className='text-sm font-bold pl-2'>Amount</div>
                              <div className='border-[1px] border-gray-400 w-1/2'>
                                <input ref={this.inputRef} type="text" autoFocus  // or type="text"
                                  autoCapitalize="none" className=" w-full black-text bg-[#E9E9E9] text-base focus:outline-none cursor-pointer placeholder-black px-1" placeholder="0" name="stake" onChange={this.inputChange} list="stake" inputMode="numeric" />
                                <datalist id="stake" className='bg-black white-text'>
                                  {betChipsData.map((element, index) => (
                                    <option key={index} value={element ? element : 0} />
                                  ))}
                                </datalist>
                              </div>

                              <div className='flex justify-end items-center text-base'>
                                <div className='bg-black white-text px-2'>
                                  <CountdownCircleTimer
                                    isPlaying
                                    duration={7}
                                    colors={['#000000']}
                                    colorsTime={[7]}
                                    size={20}
                                    strokeWidth={0}
                                    className="bg-white p-0"

                                  >
                                    {({ remainingTime }) => remainingTime === 7 ? 7 : time}
                                  </CountdownCircleTimer>
                                </div>
                                <div className=' flex justify-center px-2'>
                                  <button onClick={() => this.placeBet()} className="placebet-button px-2 py-1 uppercase rounded white-text font-bold text-[13px] cursor-pointer">Done</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> : <div className='block lg:hidden bg-[#E9E9E9]'>
                          <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                            <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                            <div className='border-[1px] border-gray-400 w-1/2'>
                              <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                            </div>
                            <div className='flex justify-end items-center'>
                              <div className='bg-black/30 white-text text-base px-2'>0

                              </div>
                              <div className=' flex justify-center px-2'>
                                <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold">Done</button>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </>
                  }

                  {betList && betList.length > 0 ?
                    <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                      <MyBetTabBmx
                        totalProfitLoss={totalProfitLoss} />
                      <BetListTableBmx betList={betList} />
                    </div>
                    : null}
                  <div className=" pb-36 bg-black/30">
                    <RoundedTabBmx />
                    <div className="flex space-x-1 justify-end items-center py-0.5 px-2">
                      {result && result.length > 0 ? result.map((element, index) => (
                        <div onClick={() => this.handleResultModel(element)} className="bg-[#008000] w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                          <p className="text-yellow-200 font-bold text-lg">{element && element.result && element.result === "1" ? "E" : element && element.result && element.result === "0" ? "T" : element && element.result && element.result === "2" ? "R" : "A"}</p>
                        </div>
                      )) : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(InstantWorliTheme2);