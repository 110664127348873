import React, { useEffect, useState } from 'react';
import "./Navbar.css";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RiLogoutBoxRFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaHome } from "react-icons/fa";
import { BsPlayFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { betChipsData } from '../../_config/index';
import EditStakeDesktop from '../EditStakeDesktop/EditStakeDesktop';
import RulesNotification from '../RulesNotification/RulesNotification'
import { headerConstent } from './HeaderConstent'
import { FaTrophy, FaInfo, FaArrowRight } from "react-icons/fa";
import { BsCash } from "react-icons/bs";
import { IoKeyOutline, IoLogOut, IoBook } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import CasinoCheckModal from '../../components/CasinoCheckModal/CasinoCheckModal';
import { useHistory } from 'react-router-dom';
import { userActions } from "../../_actions";
import { TbCards } from "react-icons/tb";
import { httpPost } from '../../_helpers/http';
import { initSocket, getSocket } from '../../components/SocketConnection/SocketConnection'


const Header = (props) => {
  let location = props?.history?.location

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [comingSoonModal, setComingSoonModal] = useState(false);
  const [casinoDataModal, setCasinoDataModal] = useState(false);
  const [betSlipData, setBetSlipData] = useState({});
  const handleCloseModal = () => setShow(false);
  const [stake, setStake] = useState(false);
  // const [clientNotification, setClientNotification] = useState([]);
  const stakehandleClose = () => setStake(false);

  const dispatch = useDispatch();


  useEffect(() => {

    // setTimeout(() => {
    //   let Balance = JSON.parse(localStorage.getItem('Balance'));
    //   let clientExposure = JSON.parse(localStorage.getItem('exposure'));


    //   setBalance({
    //     coins: Balance,
    //     exposure: clientExposure
    //   });
    // }, 1000);
    // updateSockte();


    // const betChipsDataItems = JSON.parse(localStorage.getItem('betChipsData'));
    // let betChips = {};

    // if (betChipsDataItems != null) {
    //   betChips = betChipsDataItems;
    // } else {
    //   betChips = betChipsData;
    // }
    // const updatedKeyValues = Object.entries(betChips).map(([key, value]) => ({
    //   key,
    //   value: parseInt(value),
    // }));

    // setKeyValues1(updatedKeyValues);
    // window.addEventListener('storage', () => {
    // })
    const intervalIdNotification = setInterval(() => {
      // getNotificationData();
      dispatch(userActions.domainSettingByDomainName());
    }, 30000);

    // const fetchNotificationData = () => {
    //   try {
    //     let notification = JSON.parse(localStorage.getItem('notification'));

    //     if (!notification || !Array.isArray(notification)) {
    //       getNotificationData();
    //     }

    //     let clientNotifications = notification && notification.length > 0 ? notification.filter(element => element.for === "cleint" || element.for === "client") : [];

    //     setClientNotification(clientNotifications);
    //   } catch (error) {
    //     console.error("Error fetching notification data:", error);
    //   }
    // };
    // fetchNotificationData();

    return () => clearInterval(intervalIdNotification);
  }, [props.history.location.pathname]);


  useEffect(() => {
    try {
      dispatch(userActions.getUserBalance());
      const intervalId = setInterval(() => {
        dispatch(userActions.getUserBalance());
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    } catch (error) {
      console.error("matchlist error in header");
    }
  }, [])


  function updateSockte() {
    let userID = JSON.parse(localStorage.getItem('spuser'))
    let token_Id = userID?.token
    let socket = getSocket()

    if (!socket || socket == null) {
      socket = initSocket(token_Id);

      const loginData = {
        userId: userID?.data?.userId,
        token: token_Id
      }

      socket.emit(`login`, loginData);
    }
    socket.on('coinUpdate', (data) => {
      // console.log(data, "sockket");
      dispatch(userActions.getUserBalance());
    });

    socket.on('userLoggedOut', (data) => {
      localStorage.removeItem('spuser')
      history.push('/login')
      window.location.reload();
    })

  }

  let logoutTimeout;
  const onClickMenutwo = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
    }
    logoutTimeout = setTimeout(() => {
      localStorage.removeItem('spuser');
      history.push('/login');
      window.location.reload();
    }, 500);
  };

  const handleClose = () => {
    setCasinoDataModal(false);
    setComingSoonModal(false);
    setOpen(false);
  };

  let domainSettingByDomainName = JSON.parse(localStorage.getItem('domainSettingByDomainName'))
  let user = JSON.parse(localStorage.getItem('spuser')) || {};
  let Balance = JSON.parse(localStorage.getItem('Balance')) || '';
  let exposure = JSON.parse(localStorage.getItem('exposure')) || '';

  // const handleCasinoOpen = (data) => {
  //   setCasinoDataModal(true);
  //   setBetSlipData({ ...data });
  //   setOpen(false);
  // };
  let clientNotification = domainSettingByDomainName && domainSettingByDomainName.clientNotification ? domainSettingByDomainName.clientNotification : ''


  // const getNotificationData = async () => {
  //   try {
  //     let dataFor = {};

  //     let notificationListResponse = await httpPost('user/notificationList', dataFor);
  //     localStorage.setItem('notification', JSON.stringify(notificationListResponse?.data));
  //   } catch (error) {
  //     console.error("An error occurred while fetching notification data:", error);
  //   }
  // };


  const onClickMenuMobile = (url) => {
    props.history.push(url)
    setOpen(false)
  }

  // let loginData = user&&user.data.balance

  // async function updateCoin() {
  //   async function init() {
  //     // const loginDataObj = JSON.parse(loginData);
  //     // const afterApiDataObj = JSON.parse(afterApiData);
  //     let finalBalance = JSON.parse(localStorage.getItem('finalBalance'))
  //     let user = JSON.parse(localStorage.getItem('spuser'));
  //     // setCart({
  //     //   ...loginDataObj,
  //     //   ...afterApiDataObj,
  //     // });
  //     setCart(prevState => ({
  //       ...prevState,  // Spread the previous state to retain its properties
  //       ...anotherObject,  // Spread the properties from anotherObject
  //     }));
  //   }

  //   await init();
  // }


  return (

    <>
      <div className="w-full fixed top-0 z-20">
        <div className='navbar-main md:h-12 h-12 background-color w-full'>
          <div className="navbar-left w-[50%]">
            <Link to="/">
              <img src="/images/ant-pro-logo.png" alt="logo" className="vanky-logo object-contain h-10 md:w-36 w-20" />
              {/* <img src="/images/pbx-999-logo.png" alt="logo" className="vanky-logo h-12" />  */}
            </Link>
          </div>

          <div className="navbar-right w-[50%] -ms-[15px] !lg:ms-0 text-[13px]">
            <div className='white-text' style={{ display: "flex", flexDirection: "column" }}>
              <span className='white-text font-bold'>
                {user && user.data && user.data.username ? user.data.username : ""}({user && user.data && user.data.name ? user.data.name : "user name"})
              </span>
              <span className='white-text lg:block hidden'>
                Chips : {Balance && Balance ? parseFloat(Balance).toFixed(2) : '0.00'}
              </span>
            </div>
          </div>
          <div className='lg:flex justify-end space-x-4 hidden w-full'>
            <div className='w-full'>
              {
                location && location.pathname === "/app/Profile" ?
                  <div className=" w-full">
                    <div className="flex justify-start ">
                      <NavLink exact to="/app/Profile" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Profile</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/login_auth" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/login_auth" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Rules</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/matches" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/matches" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Matches</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/ChangePassword" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/ChangePassword" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Change Password</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/accountstatement" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/accountstatement" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Statement</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/TotalLedger" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/TotalLedger" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Ledgers</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/freegames" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/freegames" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Free Games</NavLink>
                    </div>
                  </div>
                  : null
              }
              {
                location && location.pathname === "/app/matka" ?
                  <div className=" w-full">
                    <div className="flex justify-start " >
                      <NavLink exact to="/app/matka" className="block lg:text-base text-center text-xs tracking-widest uppercase  py-3 leading-none bg-white w-52 text-[#3962A5] font-bold cursor-pointer">Matka</NavLink>
                    </div>
                  </div>
                  : null
              }
            </div>
            <div className="w-full">
              <div className=" w-full">
                <div className="flex justify-start items-center px-3 space-x-2 ">
                  <IoHomeOutline className='white-text' size={24} />
                  <NavLink exact to="/app/dashboard" activeClassName="active" className="block lg:text-base  text-xs tracking-widest uppercase  py-3 leading-none bg-transparent w-24 white-text font-bold cursor-pointer">HOME</NavLink>
                </div>
              </div>

            </div>

            <div className="w-full">
              <div className=" w-full">
                <div className="flex justify-start items-center px-3 space-x-2 " onClick={() => onClickMenutwo()}>
                  <RiLogoutBoxRFill className='white-text' size={24} />
                  <NavLink exact to="schedule" activeClassName="active" className="block lg:text-base  text-xs tracking-widest uppercase  py-3 leading-none bg-transparent w-24 white-text font-bold cursor-pointer">Logout</NavLink>
                </div>
              </div>

            </div>
          </div>
          <button className="px-2 lg:hidden block" onClick={() => setOpen(!open)}>
            {open ? (
              <div className='w-full h-20 background-color left-0 top-0 absolute'>
                <div className='flex justify-end w-full p-3'>
                  <div> <AiOutlineClose className='bg-[#DC143C] white-text' size={20} /></div>
                </div>
              </div>
            ) : (
              <div className='right-5'>
                <GiHamburgerMenu className='white-text' size={18} />
              </div>
            )}
          </button>
        </div>
        <div className='background-white text-center text-sm'>

          {clientNotification && clientNotification !== "" && (
            <div>
              <marquee className="text-black bgred-500 font-bold uppercase h[26px] border-b-[1px] border-black">
                {React.createElement('span', { dangerouslySetInnerHTML: { __html: clientNotification ? clientNotification : "" } })}
              </marquee>
            </div>
          )}
          <div className={`flex justify-center items-center bgpink-300 font-medium text-[13px] space-x-1 pb-0.5  ${clientNotification ? "leading-[10px]" : null}`}>
            <span className=''>Chips : {Balance && Balance ? parseFloat(Balance).toFixed(2) : '0.00'} </span><span className=''> Expo : <span className='red-text font-semibold'>{Math.abs(exposure && exposure ? parseFloat(exposure).toFixed(2) : '0.00')} </span></span>
          </div>
        </div>
      </div>


      {open ?
        <div className="nav-menu-mobile background-color white-text z-20">
          <ul className='linehight uppercase text-[13px]'>
            <li onClick={() => onClickMenuMobile('/')} className='flex justify-between items-center px-2'> <Link to="/" className="link-css">  <FaHome size={20} /> <span >{headerConstent.Dashoard}</span>  </Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/matches')} className='flex justify-between items-center px-2'> <Link to="/app/matches" className="link-css"> <BsPlayFill size={20} /> <span >{headerConstent.All_Market_Book}</span> </Link> <FaArrowRight size={18} /></li>
            {/* {user && user.data && user.data.casinoStatus === true ?
              <li onClick={() => onClickMenuMobile('/app/iframe-casino')} className='flex justify-between items-center px-2'><p className="link-css"><TbCards size={20} />{headerConstent.Edit_Stake}</p> <FaArrowRight size={18} className='white-text' /></li>
              : <li onClick={() => handleOpenCommingSoonModal()} className='flex justify-between items-center px-2'><p className="link-css"><TbCards size={20} />{headerConstent.Edit_Stake}</p> <FaArrowRight size={18} className='white-text' /></li>} */}
            <li onClick={() => onClickMenuMobile('/app/casino')} className='flex justify-between items-center px-2'><p className="link-css"><TbCards size={20} /> Casino</p> <FaArrowRight size={18} className='white-text' /></li>
            {/* {user && user.data && user.data.intCasinoStatus === true ?
              <li onClick={() => handleCasinoOpen({ image: "/images/aviator.png", name: "Aviator", gameId: "201206", nameHindi: "एविएटर" })} className='flex justify-between items-center px-2'><p className="link-css"><BiSolidPlaneAlt size={20} />{headerConstent.Aviator}</p> <FaArrowRight size={18} className='white-text' /></li>
              : <li onClick={() => handleOpenCommingSoonModal()} className='flex justify-between items-center px-2'><p className="link-css"><TbCards size={20} />{headerConstent.Aviator}</p> <FaArrowRight size={18} className='white-text' /></li>} */}

            {/* <li onClick={() => onClickMenuMobile('/app/matka')} className='flex justify-between items-center px-2'><p className="link-css"><GiPaintedPottery size={20} />{headerConstent.Matka}</p> <FaArrowRight size={18} className='white-text' /></li> */}
            <li onClick={() => onClickMenuMobile('/app/freegames')} className='flex justify-between items-center px-2'><Link to="/app/freegames" className="link-css "><FaTrophy size={20} />{headerConstent.Profile}</Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/TotalLedger')} className='flex justify-between items-center px-2'><Link to="/app/TotalLedger" className="link-css "><BsCash size={18} />{headerConstent.Rules}</Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/accountstatement')} className='flex justify-between items-center px-2'><Link to="/app/accountstatement" className="link-css "><IoBook size={17} />Account Statement</Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/Profile')} className='flex justify-between items-center px-2'> <Link to="/app/Profile" className="link-css"> <CgProfile size={20} /> <span >{headerConstent.In_play}</span> </Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/login_auth')} className='flex justify-between items-center pr-2 pl-3'><Link to="/app/login_auth" className=" link-css link-css-rules"><FaInfo size={6} />{headerConstent.Password}</Link> <FaArrowRight size={18} /></li>
            <li onClick={() => onClickMenuMobile('/app/ChangePassword')} className='flex justify-between items-center px-2'><Link to="/app/ChangePassword" className="link-css "><IoKeyOutline size={18} />{headerConstent.My_Commision}</Link> <FaArrowRight size={18} className='white-text' /></li>
            <li onClick={() => onClickMenuMobile('/app/setting')} className='flex justify-between items-center px-2'><Link to="/app/setting" className="link-css "><IoMdSettings size={20} />{headerConstent.IPL_Winner}</Link> <FaArrowRight size={18} className='white-text' /></li>
            <li onClick={() => onClickMenutwo()} className='flex justify-between items-center px-2'><p className='link-css'>
              <IoLogOut size={20} />{headerConstent.Logout} </p>  <FaArrowRight size={18} className='white-text' /></li>
          </ul>

        </div> : ""
      }

      {show ? <RulesNotification handleClose={handleCloseModal} /> : null}
      {stake ? <EditStakeDesktop stakehandleClose={stakehandleClose} handleClose={stakehandleClose} /> : null}
      {comingSoonModal === true ?
        <CommingSoon
          handleClose={handleClose}
          message="You Are Not Authorized to Play, Please Connect With Your Upline"
          image="/images/OPPS.jpeg"
        />
        : null}
      {casinoDataModal && (
        <CasinoCheckModal
          handleClose={handleClose}
          betSlipData={betSlipData}
        />
      )}
    </>
  );

}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Header);