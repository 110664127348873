import React, { useEffect, useState } from "react";
import "./Profile.css";
import Table from "react-bootstrap/Table";
import { httpPatch } from "../../_helpers/http"
import { message } from 'antd';

const Profile = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [rateDiff, setRateDiff] = useState(1)
    const [domainSettingByDomainName, setdomainSettingByDomainName] = useState()
    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const getDomainSettingByDomainName = () => {
            try {
                let domainSetting = localStorage.getItem('domainSettingByDomainName');
                if (domainSetting) {
                    const parsedDomainSetting = JSON.parse(domainSetting);
                    setdomainSettingByDomainName(parsedDomainSetting);
                } else {
                    setdomainSettingByDomainName(null);
                }
            } catch {
                console.error('Error parsing domainSettingByDomainName:');
                setdomainSettingByDomainName(null);
            }
        };

        getDomainSettingByDomainName();
    }, []);

    const getResultByApi = async () => {
        let resultBody = {
            "rate": Number(selectedOption)
        }
        let resultData = await httpPatch('user/updateRateReffrenece', resultBody);
        setRateDiff(resultData && resultData.data ? resultData.data : {})


        if (resultData) {
            localStorage.setItem('rateDiff', JSON.stringify(resultData.data.rateReffrenec))
            message.success(resultData.message);
        } else {
            message.error("User Rate is not Updated");
        }
    };

    const handleButtonClick = () => {
        getResultByApi()
    };


    const onClickMenu = (url) => {
        props.history.push(url)
    }

    let user = JSON.parse(localStorage.getItem('spuser'));
    let rateDiffrence = JSON.parse(localStorage.getItem('rateDiff'));
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    return (
        <div className={`mainclass ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[95px] pt-[95px]" : "md:pt-[70px] pt-[70px]"}`}>
            <div className="flex ">
                <div className="profile-main md:w-3/5 w-11/12 mx-auto border border-[#3D8282]">
                    <div className="rate-info background-color text-sm white-text md:py-2 py-0.5">RATE INFORMATION</div>
                    <div className="grid grid-cols-5 divide-x divide-[#3D8282] w-full background-white ">
                        <div className="col-span-2 black-text text-[13px] md:font-bold font-semibold md:p-3 p-2 w-full px-2">RATE DIFFRENCE :</div>
                        <div className="col-span-2 flex justify-center items-center md:p-3 p-2 w-full">
                            <select onChange={handleDropdownChange} value={selectedOption} className="rounded-sm border-[1px] border-[#3D8282]">
                                <option style={{ display: 'none' }} selected value={rateDiffrence}>{rateDiffrence ? (rateDiffrence * 100) : 1}</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            {/* <select onChange={handleDropdownChange} value={selectedOption} className=" rounded-sm border-[1px] border-[#3D8282]">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select> */}
                        </div>
                        <div className="col-span-1 md:p-2 p-1 w-full">
                            <button onClick={handleButtonClick} className="upt-btn update-button text-[13px] leading-normal flex justify-center items-center font-extrabold uppercase white-text">Update</button>
                        </div>
                    </div>
                    <div className="per-info background-color text-sm white-text md:py-2 py-0.5">PERSONAL INFORMATION</div>

                    <Table>
                        <tbody className="table-name text-start font-bold text-[13px]">
                            <tr className="h-2">
                                <td>Client Name :</td>
                                <td>{user && user.data && user.data.name ? user.data.name : "userName"}</td>
                            </tr>
                            <tr>
                                <td>Client Code :</td>
                                <td>{user && user.data && user.data.username ? user.data.username : "user"}</td>
                            </tr>
                            <tr>
                                <td>Chips :</td>
                                <td>{user && user.data && user.data.balance ? parseFloat(user.data.balance).toFixed(2) : "0.00"}</td>
                            </tr>
                            <tr>
                                <td>Contact No. :</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>Date Of Joining :</td>
                                <td>12-08-2023</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>INDIA</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="com-info background-color text-sm white-text md:py-2 py-0.5 font-bold">COMPANY INFORMATION</div>
                    <Table striped bordered hover className="mb-0">
                        <tbody className="company-contact table-name text-start font-bold text-[13px] uppercase">
                            <tr>
                                <td>Help Line No.</td>
                                <td>+91 1234567890</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='md:pt-3 pt-2'>
                        <button onClick={() => onClickMenu('/app/Dashboard')} className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text text-[13px] tracking-widest">Back To Main Menu</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
