import React from 'react';
import BetLockedRoundedBmx from '../BetLockedRoundedBmx/BetLockedRoundedBmx';
import { BsSuitSpadeFill, BsSuitClubFill, BsSuitDiamondFill, BsFillSuitHeartFill } from "react-icons/bs";

export default function GameCard2(props) {
    const { Data, select, handleBackOpen, section1Ref, posArray } = props;

    return (
        <div className="w-full">
            <div className="flex justify-center items-center ">
                <p className="uppercase text-[16px] px-2 font-semibold">{Data && Data.rate ? (Data.rate) : 0}</p>
            </div>
            <div className="relative">
                <div className={`even-background flex justify-center items-center w-full h-10 text-center py-3 text-sm font-medium leading-4 ${select === "Red" ? "text-red-500" : "text-black"}  shadow shadow-gray-400 rounded cursor-pointer`}>
                    {select === "Red" ? <>
                        <BsSuitDiamondFill />
                        <BsFillSuitHeartFill />
                    </> : <>
                        <BsSuitClubFill />
                        <BsSuitSpadeFill />
                    </>}

                </div>
                {Data.gstatus === "1" ?
                    <div onClick={() => handleBackOpen(Data, section1Ref)} className={`absolute top-0 even-background flex justify-center items-center w-full h-10 py-3 ${select === "Red" ? "text-red-500" : "text-black"} shadow shadow-gray-400 rounded cursor-pointer`}>
                        {select === "Red" ? <>
                            <BsSuitDiamondFill />
                            <BsFillSuitHeartFill />
                        </> : <>
                            <BsSuitClubFill />
                            <BsSuitSpadeFill />
                        </>}
                    </div>
                    : <BetLockedRoundedBmx />
                }
            </div>
            <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center font-bold pt-1`}>
                {posArray[Data.sid] ?? 0}
            </div>
        </div>
    );
}