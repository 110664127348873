import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import moment from 'moment';
import { message } from 'antd';

//import { history } from '../_helpers';


message.config({
    top: 10,
    duration: 1,

});
export const userActions = {
    login,
    logout,
    getUserBalance,
    changePassword,
    MatchList,
    casinoGameList,
    save_bet,
    saveBetCasino,
    save_bet_fancy,
    getMatchList,
    userUpdate,
    getMatchListAdd,
    getMatchListAddFancy,
    getMatchListAddCasino,
    domainSettingByDomainName,
    getCasinoByEventId,
    getBetList,
    saveCasinoBet,
};

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    // dispatch(userService.notification);
                    dispatch(userService.domainSettingByDomainName);
                    // dispatch(userService.MatchList);

                    // if (user && user.userinfo && user.userinfo.data === "") {
                    //     this.socket = SocketIOClient("https://client-rest-api.vercel.app");
                    //     this.socket.connect("key", data => {
                    //         this.setState({ matchDetailsForSocket: data })
                    //     });
                    // } else {
                    //     this.interval = setInterval(() => this.getMarketCacheUrl(), 2000);
                    // }
                    if (user && user.userinfo && user.userinfo.data && user.userinfo.data.userType === "client") {
                        // if (user && user.userinfo && user.userinfo.data && user.userinfo.data.isPasswordChanged === false) {
                        //     props.history.push(`app/changepassword`)
                        // } else {

                        props.history.push(`/app/login_auth`)
                        // }
                        // dispatch(alertActions.success("Login Successfully."));
                        // message.success('Login Successfully.');
                    } else {
                        props.history.push(`/login`)
                        // dispatch(alertActions.error("Usertype is not Correct for login"));
                        message.error("Usertype is not Correct for login");
                    }

                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                    message.error(error);
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getUserBalance
    (data) {
    return dispatch => {
        dispatch(request());
        userService.getUserBalance
            (data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.USER_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_BALANCE_FAILURE, error } }
}


function userUpdate
    (data) {
    return dispatch => {
        dispatch(request());
        userService.userUpdate
            (data)
            .then(
                users => {
                    dispatch(alertActions.success("Your Referance is updated."));
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_UPDATE_REQUEST } }
    function success(users) { return { type: userConstants.USER_UPDATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_UPDATE_FAILURE, error } }
}

function saveCasinoBet(betObject) {
    return dispatch => {
        dispatch(request());
        userService.saveCasinoBet(betObject)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(this.list_bt_ssn_mk(data2))
                    let data1 = {
                        casinoType: betObject.casinoType,
                        fromDate: moment().format('YYYY-MM-DD'),
                        toDate: moment().format('YYYY-MM-DD'),
                    };
                    dispatch(userActions.getBetList(data1));
                    // dispatch(userActions.getBalanceByToken());
                    dispatch(alertActions.success("Bet has been saved successfully."));
                    // window.reload();
                },
                error => {
                    // let data1 = {
                    //     casinoType: betObject.casinoType,
                    // };
                    // dispatch(userActions.getBetList(data1));
                    dispatch(alertActions.error(error))
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_CSN_BET_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_CSN_BET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_CSN_BET_FAILURE, error } }
}


function changePassword(data, history) {
    return dispatch => {
        dispatch(request());
        userService.changePassword(data)
            .then(
                users => {
                    let message = users && users.changePassword ? users.changePassword : null;
                    dispatch(alertActions.success(message));
                    dispatch(success(users));
                    history.push("/login")
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function getBetList(data) {
    return dispatch => {
        dispatch(request());
        userService.getBetList(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.BET_LIST_REQUEST } }
    function success(users) { return { type: userConstants.BET_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.BET_LIST_FAILURE, error } }
}

function getCasinoByEventId(data) {
    return dispatch => {
        dispatch(request());
        userService.getCasinoByEventId(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.EVENT_REQUEST } }
    function success(users) { return { type: userConstants.EVENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.EVENT_FAILURE, error } }
}


function getMatchList(data) {
    return dispatch => {
        dispatch(request());
        userService.getMatchList(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MATCH_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_MATCH_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MATCH_LIST_FAILURE, error } }
}


function getMatchListAddCasino(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(users) { return { type: userConstants.GET_MATCH_LIST_ADD_CASINO_SUCCESS, users } }
}
function getMatchListAdd(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(users) { return { type: userConstants.GET_MATCH_LIST_ADD_SUCCESS, users } }
}
function getMatchListAddFancy(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(users) { return { type: userConstants.GET_MATCH_LIST_ADD_FANCY_SUCCESS, users } }
}


function MatchList(data) {
    return dispatch => {
        dispatch(request());
        userService.MatchList(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.MATCH_LIST_REQUEST } }
    function success(users) { return { type: userConstants.MATCH_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.MATCH_LIST_FAILURE, error } }
}

function casinoGameList(data) {
    return dispatch => {
        dispatch(request());
        userService.casinoGameList(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CASINO_GAME_LIST_REQUEST } }
    function success(users) { return { type: userConstants.CASINO_GAME_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CASINO_GAME_LIST_FAILURE, error } }
}


function saveBetCasino(data, secondObject, props) {
    return dispatch => {
        dispatch(request());
        userService.saveBetCasino(data)
            .then(
                users1 => {
                    dispatch(success(users1));

                    let { users } = props;
                    let { casinogamelistItems } = users;
                    users['casinogamelistItems'] = [...casinogamelistItems, secondObject]
                    dispatch(this.getMatchListAddCasino(users));
                    dispatch(alertActions.success("Bet has been saved successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_BET_CASINO_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_BET_CASINO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_BET_CASINO_FAILURE, error } }
}

function save_bet(data, secondObject, props) {
    return dispatch => {
        dispatch(request());
        userService.save_bet(data)
            .then(
                users1 => {
                    dispatch(success(users1));

                    let { users } = props;
                    let { oddsBetData } = users;
                    users['oddsBetData'] = [secondObject, ...oddsBetData]
                    dispatch(this.getMatchListAdd(users));
                    dispatch(alertActions.success("Bet has been saved successfully."));
                    localStorage.setItem('betMessageModal', JSON.stringify(true));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                    localStorage.setItem('betMessageModal', JSON.stringify(true));
                }
            );
    };
    function request() { return { type: userConstants.SAVE_BET_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_BET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_BET_FAILURE, error } }
}

function save_bet_fancy(data, FancyObject, props) {
    return dispatch => {
        dispatch(request());
        userService.save_bet_fancy(data)
            .then(
                users1 => {
                    dispatch(success(users1));
                    let { users } = props;
                    let { fancyBetData } = users;
                    users['fancyBetData'] = [FancyObject, ...fancyBetData]
                    dispatch(this.getMatchListAddFancy(users));
                    dispatch(alertActions.success("Bet has been saved successfully."));
                    localStorage.setItem('betMessageModal', JSON.stringify(true));
                },
                error => {
                    dispatch(alertActions.error(error));
                    localStorage.setItem('betMessageModal', JSON.stringify(true));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_BET_FANCY_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_BET_FANCY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_BET_FANCY_FAILURE, error } }
}

function domainSettingByDomainName(data) {
    return dispatch => {
        dispatch(request());
        userService.domainSettingByDomainName(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST } }
    function success(users) { return { type: userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE, error } }
}















