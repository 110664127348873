import React from 'react';
import { IoClose } from "react-icons/io5";
import { DOMAIN } from '../../_config';


export default function Rules(props) {
    const { handleCloseNotification } = props
    return (
        <div className='h-screen overflow-y-hidden absolute top-0 left-0 z-50 w-full bg-black/50 md:pt-4 pt-1 p-1'>
            <div className="background-white md:w-[500px] w-full mx-auto rounded-md">
                <div className="background-color text-start px-2 py-2 cursor-pointer w-full flex justify-between items-center">
                    <span className="text-[13px] text-white">Welcome to {DOMAIN.NAME}</span>
                    <span onClick={handleCloseNotification}><IoClose className='black-text' size={15} /></span>
                </div>
                <div className='overflow-y-auto md:h-[200px] h-[220px] black-text px-2 text-[13px]'>
                    <h2 className='text-xl font-medium pt-2'>प्रिय ग्राहक,</h2>
                    <p className='pt-1'>आपसे अनुरोध है हमारी कोई डुप्लीकेट साइट नही है हमारी आधिकारिक साइट <b>'{DOMAIN.NAME}'</b> से लॉगिन करें। लॉगइन करने से पहले साइट का नाम जरूर देख लें। आपके समर्थन के लिए धन्यवाद। टीम {DOMAIN.NAME}</p>
                    <h2 className='text-xl font-medium pt-2'>Dear Client,</h2>
                    <p className='font-medium pt-1'>We don't have any duplicate site , You are requested to login with our official site '{DOMAIN.NAME}'. I only, Please check the site name before you login. Thanks for your support. Team {DOMAIN.NAME}</p>
                </div>
                <div className="py-2 px-3 w-full  flex justify-end items-end border-t border-gray-300">
                    <div className='w-1/6'>
                        <span onClick={handleCloseNotification} className="flex justify-center items-center background-color-cancel white-text py-1 px-2 text-[12px] rounded cursor-pointer">Cancel</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

