import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import CasinoCheckModal from '../../components/CasinoCheckModal/CasinoCheckModal';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      CommingSoonModal: false,
      casinoDataModal: false,
      betSlipData: {},
      domainSettingByDomainName: null,
    }
  }

  componentDidMount() {
    this.getDomainSettingByDomainName();
  }

  getDomainSettingByDomainName = () => {
    try {
      let domainSetting = localStorage.getItem('domainSettingByDomainName');
      if (domainSetting) {
        const parsedDomainSetting = JSON.parse(domainSetting);
        this.setState({ domainSettingByDomainName: parsedDomainSetting });
      } else {
        this.setState({ domainSettingByDomainName: null });
      }
    } catch {
      console.error('Error parsing domainSettingByDomainName:');
      this.setState({ domainSettingByDomainName: null });
    }
  };

  handleStakeOpen = () => {
    this.setState({ CommingSoonModal: true });

  };
  handleClose = () => {
    this.setState({ CommingSoonModal: false });
    this.setState({ casinoDataModal: false });
  };
  handleCasinoOpen = (data) => {
    this.setState({ casinoDataModal: true, betSlipData: { ...data } });
  };

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  render() {

    const { casinoDataModal, domainSettingByDomainName } = this.state;
    // let clientNotification = JSON.parse(localStorage.getItem('notification'))
    return (
      <>
        {casinoDataModal ? (
          <CasinoCheckModal
            handleClose={this.handleClose}
            betSlipData={this.state.betSlipData}
            history={this.props.history}
          />
        ) :
          <div className={` overflow-y-auto w-full h-[100vh] lg:pb-0  ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[85px] pt-[80px]" : "md:pt-[58px] pt-[55px]"}`}>
            {this.state.CommingSoonModal === true ?
              <CommingSoon
                handleClose={this.handleClose}
                message="You Are Not Authorized to Play, Please Connect With Your Upline"
                image="/images/OPPS.jpeg"
              />
              : null}
            <div className='md:space-y-24 space-y-20 md:px-4 px-0 pt-8'>
              <div className='flex justify-center'>

                <div className='w-7/8 mx-auto grid grid-cols-2 gap-10 md:space-x-14 space-x-5 lg:space-x-36'>
                  {/* <div onClick={() => this.onClickMenu('/app/matches')} className='cursor-pointer md:h-[36px] h-[24px]  white-text '> */}
                  <a href="#/app/matches" className='cursor-pointer md:h-[36px] h-[24px]  white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/antpro-inplay.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Inplay</h2>
                    </div>
                  </a>
                  <a href='#/app/Profile' className='cursor-pointer h-[36px] white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/profile.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Profile</h2>
                    </div>
                  </a>


                </div>
              </div>

              <div className='flex justify-center'>
                <div className='w-7/8 mx-auto grid grid-cols-2 gap-10 md:space-x-14 space-x-5 lg:space-x-36'>
                  {/* {user && user.data && user.data.intCasinoStatus === true ?
                  <div onClick={() => this.handleCasinoOpen({ image: "/images/aviator.png", name: "Aviator", gameId: "201206", nameHindi: "एविएटर" })} className='cursor-pointer h-[36px] white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/aviator.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Aviator</h2>
                    </div>
                  </div> :
                  <div onClick={() => this.handleStakeOpen()} className='cursor-pointer h-[36px]   white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/aviator.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Aviator</h2>
                    </div>
                  </div>
                } */}
                  {/* <a href='#/app/matka' className='cursor-pointer h-[36px] white-text '>
                  <div className='w-full flex flex-col justify-center items-center'>
                    <img src='/images/matka.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                    <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Matka</h2>
                  </div>
                </a> */}
                  <a href='#/app/accountstatement' className='cursor-pointer  h-[36px]  white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/statement.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Statement</h2>
                    </div>
                  </a>
                  <a href='#/app/ChangePassword' className='cursor-pointer h-[36px] white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/change-password.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Change<br /> Password</h2>
                    </div>
                  </a>
                </div>
              </div>

              <div className='flex justify-center '>
                <div className='w-7/8 mx-auto grid grid-cols-2 gap-10 md:space-x-10 space-x-5 lg:space-x-36'>
                  <a href='#/app/freegames' className='cursor-pointer  h-[36px]  white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/free-games.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Free Games</h2>
                    </div>
                  </a>
                  <a href='#/app/login_auth' className='cursor-pointer h-[36px] white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/rules.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Rules</h2>
                    </div>
                  </a>
                </div>
              </div>

              <div className='flex justify-center'>
                <div className='w-7/8 mx-auto grid grid-cols-2 gap-10 md:space-x-14 space-x-5 lg:space-x-36'>
                  <a href='#/app/TotalLedger' className='cursor-pointer  h-[36px]  white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/ledger-bmx.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>My Ledger</h2>
                    </div>
                  </a>
                  <div className='flex justify-center'>
                    <a href='#/app/casino' className='cursor-pointer md:h-[36px] h-[24px]   white-text '>
                      <div className='w-full flex flex-col justify-center items-center'>
                        <img src='/images/casino-9pro.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                        <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '> Casino</h2>
                      </div>
                    </a>
                  </div>

                  {/* {user && user.data && user.data.casinoStatus === true ?
                  <a href='#/app/iframe-casino' className='cursor-pointer md:h-[36px] h-[24px]   white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/casino-9pro.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Casino</h2>
                    </div>
                  </a> :
                  <div onClick={() => this.handleStakeOpen()} className='cursor-pointer md:h-[36px] h-[24px]   white-text '>
                    <div className='w-full flex flex-col justify-center items-center'>
                      <img src='/images/casino.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                      <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Casino</h2>
                    </div>
                  </div>
                } */}
                </div>
              </div>

              {/* <div className='flex justify-center'>
              <div className='w-7/8 mx-auto grid grid-cols-2 gap-10 md:space-x-14 space-x-5 lg:space-x-36'>
                <a href='#/app/login_auth' className='cursor-pointer h-[36px] white-text '>
                  <div className='w-full flex flex-col justify-center items-center'>
                    <img src='/images/rules.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                    <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Rules</h2>
                  </div>
                </a>
                <a href='#/app/casino' className='cursor-pointer md:h-[36px] h-[24px]   white-text '>
                  <div className='w-full flex flex-col justify-center items-center'>
                    <img src='/images/casino-9pro.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                    <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Intregated Casino</h2>
                  </div>
                </a>
              </div>
            </div> */}

              {/* <div className='flex justify-center'>
              <a href='#/app/casino' className='cursor-pointer md:h-[36px] h-[24px]   white-text '>
                <div className='w-full flex flex-col justify-center items-center'>
                  <img src='/images/casino-9pro.png' alt='' className='m:w-28 m:h-28 w-20 h-20' />
                  <h2 className='uppercase  text-center text-sm black-text whitespace-nowrap font-bold '>Intregated Casino</h2>
                </div>
              </a>
            </div> */}
              {/* </div> */}

            </div>

          </div>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default withRouter(connect(mapStateToProps)(Dashboard));
