import React from "react";
import logo from "./logo.svg";
export default function Error() {
  var classes = {}

  return (
    <div className="pt-24 text-5xl bg-red-500 h-96 w-full">
      Page not found.
    </div>
  );
}
