import React, { Component } from "react";
import moment from 'moment';
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";

class LegderDetailsCasino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLedgerDetails: {},
      currentPage: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      betList: {},
      domainSettingByDomainName: null,
    }
  }


  componentDidMount() {
    this.getUserLedger();
    this.betList(this.props.match.params.marketId)
    this.getDomainSettingByDomainName();
  }

  getDomainSettingByDomainName = () => {
    try {
      let domainSetting = localStorage.getItem('domainSettingByDomainName');
      if (domainSetting) {
        const parsedDomainSetting = JSON.parse(domainSetting);
        this.setState({ domainSettingByDomainName: parsedDomainSetting });
      } else {
        this.setState({ domainSettingByDomainName: null });
      }
    } catch {
      console.error('Error parsing domainSettingByDomainName:');
      this.setState({ domainSettingByDomainName: null });
    }
  };

  getUserLedger = async () => {
    let reqData = {
      eventId: this.props.match.params.marketId + "",
      fromDate: moment(parseInt(this.props.match.params.date, 10)).format("yyyy-MM-DD"),
      toDate: moment(parseInt(this.props.match.params.date, 10)).format("yyyy-MM-DD"),
      casinoType: this.props.match.params.ledgerType + "",
    }
    this.setState({ isFetch: true })
    let userLedgerDetails = await httpPost('reports/casinoTransactionReport', reqData);
    if (userLedgerDetails) {
      this.setState({ userLedgerDetails: userLedgerDetails.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }

  betList = async (eventId) => {
    let betReq = {
      eventId: eventId,
      isDeleted: false,
      fromDate: moment(parseInt(this.props.match.params.date, 10)).format(
        "yyyy-MM-DD",
      ),
      toDate: moment(parseInt(this.props.match.params.date, 10)).format(
        "yyyy-MM-DD",
      ),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)

    let betList = getCasinoDetails?.data?.casinoBetData
    if (getCasinoDetails) {
      this.setState({ betList: betList })
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  }

  render() {
    let { userLedgerDetails, resMessage, errorType, showAlert, isFetch, betList, domainSettingByDomainName } = this.state;
    // let clientNotification = JSON.parse(localStorage.getItem('notification'))

    return (
      <div
        className={` overflow-auto h-screen md:w-full mx-auto lg:pb-0 pb-24 ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[97px] pt-[97px]" : "md:pt-[72px] pt-[72px]"}`}
      >
        {isFetch === true ? <Loader /> : null}
        {showAlert && (
          <div
            className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"
              }  rounded`}
          >
            <span className="white-text font-bold">{resMessage}</span>
          </div>
        )}

        <div className="lg:w-[61%] w-full  mx-auto">
          {betList && betList.length > 0 ? (
            <>
              <div className="bg-[#DC143C] text-sm white-text text-center py-1 font-extrabold flex justify-between items-center">
                <span className="w-full"></span>
                <span className="w-full">CASINO BETS</span>
                <span className="text-xs w-full text-right"></span>
              </div>
              <div className="w-full overflow-x-auto scrollbar-hide">
                <table className="w-full text-sm">
                  <thead className="rounded-t background-color">
                    <tr className="white-text text-center font-bold ">
                      <th className="p-0.5 w-[30%]">TEAM</th>
                      <th className="p-0.5 w-[25%]">DATE</th>
                      <th className="p-0.5 px-2 w-[10%]">RATE</th>
                      <th className="p-0.5 w-[15%]">AMOUNT</th>
                      <th className="p-0.5 w-[10%]">MODE</th>
                      <th className="p-0.5 w-[10%]">Show Result</th>
                      <th className="p-0.5 w-[10%]">P/L</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {betList && betList ? (
                      <>
                        {betList.map((element, index) => (
                          <tr
                            key={index}
                            className=" background-white text-[14px] font-medium text-[#49494A]"
                          >
                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-start w-[30%]">
                              {element.playerName}({element.roundId})
                            </td>
                            <td className="truncate text-start border-[1px] border-[#3d8282]  w-[25%] ">
                              {element && element.createdAt
                                ? moment(element.createdAt)
                                  .utcOffset("+05:30")
                                  .format("DD-MM-YYYY hh:mm A")
                                : ""}
                            </td>
                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282]  w-[10%] px-2">
                              {element.odds}
                            </td>
                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[15%]">
                              {Number.parseFloat(Math.abs(element.amount))
                                .toFixed(2)
                                .replace(/\.?0+$/, "")}
                            </td>
                            <td className=" whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[10%]">
                              {element.betType === "L" ? "LAGAI" : "KHAI"}
                            </td>
                            <td
                              className={`whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[10%] `}
                            >
                              {element.showResult !== ""
                                ? element.showResult
                                : "Not Declared"}
                            </td>
                            <td
                              className={`whitespace-nowrap border-[1px] border-[#3d8282] text-center w-[10%] `}
                            >
                              {element.profitLoss > 0 ? (
                                <div className="background-white text-[#5199E6] text-sm text-center py-1 font-semibold">
                                  <span>
                                    {element.profitLoss
                                      ? Number.parseFloat(
                                        Math.abs(element.profitLoss),
                                      )
                                        .toFixed(2)
                                        .replace(/\.?0+$/, "")
                                      : 0}
                                  </span>
                                </div>
                              ) : (
                                <div className="background-white text-[#DC143C] text-sm text-center py-1 font-semibold">
                                  <span>
                                    {element.profitLoss
                                      ? Number.parseFloat(
                                        Math.abs(element.profitLoss),
                                      )
                                        .toFixed(2)
                                        .replace(/\.?0+$/, "")
                                      : 0}
                                  </span>
                                </div>
                              )}
                              {/* {element.profitLoss} */}
                            </td>
                            <marquee className="w-full font-bold red-text  border-[#3D8282]">
                              {element.deletedRemark}
                            </marquee>
                          </tr>
                        ))}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {userLedgerDetails &&
            userLedgerDetails.length > 0 &&
            userLedgerDetails.map((element, index) => (
              <>
                <div className="pt-5 px-3 sm:px-0">
                  <div className=" background-color text-sm white-text text-center p-1 font-bold">
                    <span>Casino Plus Minus</span>
                  </div>
                  {element.totalAmount > 0 ? (
                    <div className="background-white text-[#5199E6] text-sm text-center py-1 font-semibold">
                      <span>
                        You Won{" "}
                        {element.amount
                          ? Number.parseFloat(Math.abs(element.amount))
                            .toFixed(2)
                            .replace(/\.?0+$/, "")
                          : 0}
                        /- Coins.
                      </span>
                    </div>
                  ) : (
                    <div className="background-white text-[#DC143C] text-sm text-center py-1 font-semibold">
                      <span>
                        You Lost{" "}
                        {element.amount
                          ? Number.parseFloat(Math.abs(element.amount))
                            .toFixed(2)
                            .replace(/\.?0+$/, "")
                          : 0}
                        /- Coins.
                      </span>
                    </div>
                  )}
                </div>

                <div className="px-3 sm:px-0">
                  <div className=" background-color text-sm white-text text-center p-1 font-bold">
                    <span>Total Commission</span>
                  </div>
                  {element.totalComm > 0 ? (
                    <div className="background-white text-[#5199E6] text-sm text-center py-1 font-semibold">
                      <span>
                        You Won{" "}
                        {element.totalComm
                          ? Number.parseFloat(Math.abs(element.totalComm))
                            .toFixed(2)
                            .replace(/\.?0+$/, "")
                          : 0}
                        /- Coins.
                      </span>
                    </div>
                  ) : (
                    <div className="background-white text-[#DC143C] text-sm text-center py-1 font-semibold">
                      <span>
                        You Lost{" "}
                        {element.totalComm
                          ? Number.parseFloat(Math.abs(element.totalComm))
                            .toFixed(2)
                            .replace(/\.?0+$/, "")
                          : 0}{" "}
                        /- Coins.
                      </span>
                    </div>
                  )}
                </div>
                <div className="px-3 sm:px-0">
                  <div className=" background-color text-sm white-text text-center p-1 font-bold">
                    <span>Net Plus Minus</span>
                  </div>
                  {element.totalComm + element.totalAmount > 0 ? (
                    <div className="background-white text-[#5199E6] text-sm text-center py-1 font-semibold">
                      <span>
                        You Won{" "}
                        {Number.parseFloat(element.totalAmount)
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}{" "}
                        /- Coins.
                      </span>
                    </div>
                  ) : (
                    <div className="background-white text-[#DC143C] text-sm text-center py-1 font-semibold">
                      <span>
                        You Lost
                        {Number.parseFloat(element.totalAmount)
                          .toFixed(2)
                          .replace(/\.?0+$/, "")}
                        /- Coins.
                      </span>
                    </div>
                  )}
                </div>
              </>
            ))}

          <div className="px-3 sm:px-0">
            <button
              onClick={() => this.onClickMenu("/app/TotalLedger")}
              className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text text-[13px] tracking-widest"
            >
              BACK TO LIST
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default LegderDetailsCasino;
