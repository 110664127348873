import React, { useRef } from 'react';
import { IoMdClose } from 'react-icons/io';

export default function CommingSoon(props) {
    const { handleClose, message,image } = props;
    const modalRef = useRef(null);
    
    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose(); // Close the modal only if the click is outside the modal content
        }
    };

    return (
        <>
            <div className='fixed w-full h-screen  top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1' onClick={handleOutsideClick}>
                <div ref={modalRef} className="background-white xl:w-[40rem]  md:w-[30rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto xl:mt-44 mt-14 top-2">
                    {/*modal header*/}
                    <div> 
                        <div className="flex items-center justify-between p-6 py-2 background-color border-b">
                            <div className="text-[16px] font-medium  white-text uppercase">Important Messages</div>
                            <IoMdClose onClick={handleClose} size={30} className='curser white-text' />
                        </div>

                        <div className='text-center space-y-5 py-10'>
                            <div className='md:text-xl text-3xl  flex justify-center items-center '>
                                <img src={image} alt='OPPS Something Want Wrong' className='w-52 h-52' />
                            </div>
                            <div className='md:text-2xl text-lg font-bold text-[#4C0000] px-2'>
                                {message}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

