import React from 'react';
import { connect } from 'react-redux';
import CommingSoon from '../../components/CommingSoon/CommingSoon';
// import Sidebar from "../../components/Sidebar/Sidebar";

class Casino extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gameOne: '',
            gameTwo: '',
            gameThree: '',
            gameFour: '',
            gameItem: {},
            CommingSoonModal: false,
            domainSettingByDomainName: null,
        }
    }
    handleResponseCasino = (data) => {
        if (data === 3056) {
            this.props.history.push('/app/aaa/' + data)
        }
        else if (data === 3031) {
            this.props.history.push('/app/teen/' + data)
        }
        else if (data === 3030) {
            this.props.history.push('/app/teen20/' + data)
        }
        else if (data === 3032) {
            this.props.history.push('/app/lucky7/' + data)
        }
        else if (data === 3059) {
            this.props.history.push('/app/dt202/' + data)
        }
        else if (data === 3035) {
            this.props.history.push('/app/dt20/' + data)
        }
        else if (data === 3043) {
            this.props.history.push('/app/ab2/' + data)
        }
        else if (data === 3054) {
            this.props.history.push('/app/worli2/' + data)
        }
        else if (data === 3046) {
            this.props.history.push('/app/cmeter/' + data)
        }
    }

    handleSidebar = (data) => {
        this.setState({ gameItem: data });
    }

    componentDidMount() {
        this.getDomainSettingByDomainName();
        this.timer = setTimeout(() => {
            this.setState({ showLoader: false });
        }, 1000);
    }

    getDomainSettingByDomainName = () => {
        try {
          let domainSetting = localStorage.getItem('domainSettingByDomainName');
          if (domainSetting) {
            const parsedDomainSetting = JSON.parse(domainSetting);
            this.setState({ domainSettingByDomainName: parsedDomainSetting });
          } else {
            this.setState({ domainSettingByDomainName: null });
          }
        } catch {
          console.error('Error parsing domainSettingByDomainName:');
          this.setState({ domainSettingByDomainName: null });
        }
      };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    handleClose = () => {
        this.setState({ CommingSoonModal: false });
    };
    handleModalOpen = () => {
        this.setState({ CommingSoonModal: true });
    };

    render() {
        // let clientNotification = JSON.parse(localStorage.getItem('notification'))
        let {domainSettingByDomainName } = this.state
        return (
            <>
                <div className={` overflow-auto h-screen ${domainSettingByDomainName && domainSettingByDomainName.clientNotification  ? "md:pt-[105px] pt-[100px]" : "md:pt-[82px] pt-[80px]"}`}>
                    {this.state.CommingSoonModal === true ?
                        <CommingSoon
                            handleClose={this.handleClose}
                            message=""
                            image="/casino-images/comming-soon.jpeg"
                        />
                        : null}
                    {/*<div>
                        <div className='bg-[#7B7C7F] text-white my-2.5 py-0.5 text-center font-medium'>
                            Virtual Casino
                        </div>
                        <div className={`lg:flex block justify-center items-center lg:space-x-3 lg:space-y-0 space-x-0 space-y-3 md:px-10 px-4`}>
                            <div className='flex justify-center items-center space-x-3 '>
                                <div onClick={() => this.handleModalOpen()}><img src='/casino-images/dus-ka-dam.jpeg' alt='dus-ka-dam' className='rounded-md cursor-pointer w-44 h-44' /></div>
                                <div onClick={() => this.handleModalOpen()}><img src='/casino-images/roultee.jpeg' alt='roultee' className='rounded-md cursor-pointer w-44 h-44' /></div>
                            </div>
                            <div className='flex justify-center items-center space-x-3 '>
                                <div onClick={() => this.handleModalOpen()}><img src='/casino-images/andar-bahar.jpeg' alt='andar-bahar' className='rounded-md cursor-pointer w-44 h-44' /></div>
                            </div>
                        </div>
                    </div>*/}


                    <div>
                        <div className='bg-[#7B7C7F] text-white my-2.5 text-center font-medium py-0.5'>
                            Live Casino
                        </div>
                        <div className={`lg:flex block justify-center items-center lg:space-x-3 lg:space-y-0 space-x-0 space-y-3 md:px-10 px-4`}>
                            <div className='flex justify-center items-center space-x-3 '>
                                <div onClick={() => this.handleResponseCasino(3056)}><img src='/casino-images/amar-akbar-anthony.jpeg' alt='amar-akbar-anthony' className='rounded-md cursor-pointer w-44 h-44' /></div>
                                <div onClick={() => this.handleResponseCasino(3032)}><img src='/casino-images/lucky-7.jpeg' alt='lucky-7' className='rounded-md cursor-pointer w-44 h-44' /></div>
                            </div>
                            <div className='flex justify-center items-center space-x-3 '>
                                <div onClick={() => this.handleResponseCasino(3030)}><img src='/casino-images/teenpatti-20.jpeg' alt='teenpatti-20' className='rounded-md cursor-pointer w-44 h-44' /></div>
                                <div onClick={() => this.handleResponseCasino(3035)}><img src='/casino-images/dragon-tiger.jpeg' alt='dragon-tiger' className='rounded-md cursor-pointer w-44 h-44' /></div>

                            </div>
                            <div className='flex justify-center items-center space-x-3'>
                                {/* <div className='relative'>
                                    <div onClick={() => this.handleResponseCasino(3043)}><img src='/casino-images/andar-bahar2.jpeg' alt='andar-bahar2' className='rounded-md cursor-pointer w-44 h-44' />
                                    </div>

                                </div> */}

                                <div className='relative'>
                                    <div onClick={() => this.handleResponseCasino(3059)}><img src='/casino-images/dragen-tiger202.jpeg' alt='Dragen Tiger 202' className='rounded-md cursor-pointer w-44 h-44' /></div>
                                    <div class="blink flex justify-center items-center top-0">
                                        <span class="text-white text-[9px] font-bold">New Launch</span>
                                    </div>
                                </div>
                                <div className='relative'>
                                    <div onClick={() => this.handleResponseCasino(3031)}><img src='/casino-images/teenpatti-oneday.png' alt='teen-patti oneday' className='rounded-md cursor-pointer w-44 h-44' />
                                    </div>
                                    <div class="blink flex justify-center items-center top-0">
                                        <span class="text-white text-[9px] font-bold">New Launch</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center items-center space-x-3'>

                                {/* <div className='relative'>
                                    <div onClick={() => this.handleResponseCasino(3043)}><img src='/casino-images/andar-bahar2.jpeg' alt='andar-bahar2' className='rounded-md cursor-pointer w-44 h-44' />
                                    </div>

                                </div> */}
                            </div>
                        </div>

























                        <div className='flex justify-center items-center space-x-3'>
                            <div className='relative'>
                                {/* <div onClick={() => this.handleResponseCasino(3054)}><img src='/casino-images/live-instant-worli.webp' alt='Live Instant Worli' className='rounded-md cursor-pointer w-44 h-44 ' />
                                    <div class="blink flex justify-center items-center top-0">
                                        <span class="text-white text-[9px] font-bold">New Launch</span>
                                    </div>
                                </div> */}
                                {/* <div onClick={() => this.handleResponseCasino(3043)}><img src='/casino-images/andar-bahar2.jpeg' alt='andar-bahar2' className='rounded-md cursor-pointer w-44 h-44' />
                        </div> */}
                            </div>
                            {/* <div className='flex justify-center items-center space-x-3 '>
              <div onClick={() => this.handleResponseCasino(3046)}><img src='/images/dashboard2/live-casino-meter.webp' alt='Live Casino Meter' className='rounded-md cursor-pointer w-44 h-44' /></div>
            </div> */}
                        </div>
                    </div>
                </div>












            </>

        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users
    };
}

export default connect(mapStateToProps)(Casino);
