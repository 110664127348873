import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import "./Login.css";
import { FaSignInAlt } from "react-icons/fa";
import { DOMAIN } from '../../_config/index';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      showMe: false,
      passtype: "password",
    };
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('spuser'));
    if (userData) {
      this.props.history.push(`app/dashboard`)
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }
  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let loginData = {
        username: this.state.fieldslogin.username.toUpperCase().trim(),
        password: this.state.fieldslogin.password.trim(),
        isClient: true,
        host: window.location.host,
      };
      this.props.dispatch(userActions.login(loginData, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"]) {
      formIsValid = false;
      errorslogin["username"] = "User Name Cannot Be Blank.";
    }
    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Password Cannot Be Blank.";
    }
    console.error("errorsloginerrorsloginerrorsloginerrorslogin:::", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  show = () => {
    this.setState({ showMe: true, passtype: "text" });
  };
  hide = () => {
    this.setState({ showMe: false, passtype: "password" });
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loginSubmit(e);
    }
  };
  render() {
    let { loading } = this.props;
    return (
      <>
        {/* {this.state.isFetching === false ?
          <Loader />
          : null} */}
        <div className="background-color-login h-screen w-full flex justify-center items-center" >
          <div className="md:flex block justify-center items-center space-y-0 md:space-x-5 w-full space-x-0 ">
            <div className="flex justify-center items-center md:w-[42%] m-auto w-[90%]">
              {/* <img src="/images/pbx-999-logo.png" alt={DOMAIN.NAME} className=" w-full h-full " /> */}
              <img src="/images/ant-pro-logo.png" alt={DOMAIN.NAME} className=" w-full h-full mb-24 md:mb-0 " />
            </div>
            <div className="flex justify-center items-center xl:px-24 md:px-4 px-0 md:w-1/3 mx-auto w-full">
              <div className=" w-full mx-auto h-full overflow-hidden pt-4 md:px-5 px-4">
                <div className=" space-y-3 h-full ">
                  <div className="text-center font-extrabold white-text text-lg">Please Login To Continue</div>
                  <div className="w-full pt-2">
                    <div className="flex justify-start items-center">
                      <input className={`py-2.5 px-3 text-sm w-full text-gray-700 placeholder:text-gray-500 rounded-none capitalize ${this.state.errorslogin && this.state.errorslogin["username"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:bg-[#fff] focus:drop-shadow-lg bg-[#fff]`} placeholder="Username" onKeyDown={this.handleKeyDown} onChange={this.inputChange} type="username" name="username" id="username"
                        value={this.state.fieldslogin.username} required />
                    </div>
                  </div>
                  <div className="w-full ">
                    <span className="flex">
                      <input className={`py-2.5 px-3 text-sm w-full text-gray-700 placeholder:text-gray-500 rounded-none ${this.state.errorslogin && this.state.errorslogin["password"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:bg-[#fff] focus:drop-shadow-lg bg-[#fff]`} placeholder="Password" onKeyDown={this.handleKeyDown} onChange={this.inputChange} type="password" name="password" id="password"
                        value={this.state.fieldslogin.password} required />
                    </span>
                  </div>

                  <div>

                    {loading === true ?
                      <button className="w-full white-text px-3 py-2 h-10 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] login-button-color rounded-none font-extrabold text-sm" type="submit">
                        <div className=" flex items-center justify-center absolute bg-transparent">
                          <div className="flex items-center justify-center p-6 py-2 bg-transparent w-full">
                            <div className="w-8 h-8 rounded-full animate-spin 
                          border-[5px] border-solid border-[#6c757d] border-t-transparent"></div>
                          </div>
                        </div>
                      </button>
                      : <button onClick={this.loginSubmit} className="w-full white-text px-3 py-2 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] login-button-color rounded-none font-extrabold text-sm" type="submit">
                        <span>Login</span>
                        <FaSignInAlt className="white-text h-3 w-3" />
                      </button>}
                  </div>
                  <div className="text-center text-base md:w-2/3 w-3/4 mx-auto font-medium space-x-2 black-text">
                    <a href="#" className="underline hover:no-underline underline-black">Privacy Policy</a>
                    <a href="#" className="underline hover:no-underline underline-black"> Terms & Conditions</a>
                    <a href="#" className="underline hover:no-underline underline-black">  Rules & Regulations</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div >
        {/* <div className="flex items-center justify-center min-h-screen px-3 md:py-5 py-2 bg-gradient-to-b from-[#E30909] to-[#0E1C64] min-w-screen">
          <div className="w-full overflow-hidden bg-gradient-to-b from-[#651833] to-[#2D1D4B] rounded shadow-xl" style={{ maxWidth: '700px' }}>
            <div className="w-full md:flex">
              <div className="w-full px-4 py-3 bg-gradient-to-b from-[#461D39] to-[#1F2149] md:w-[40%]">
                <div className="px-2 py-2 space-y-5">
                  <h2 className="text-[28px] text-white">Sign In</h2>
                  <p className="text-sm text-white">Please Login To Continue</p>
                  <img src="/images/9-pro-login-logo2.png" className="w-[100px]" alt="logo.png" />
                </div>
              </div>
              <div className="w-full px-3 py-5 md:w-[60%]">
                <div className="space-y-5">
                  <div className="w-full ">
                    <div className="flex justify-start items-center">
                      <input className={`p-2 w-full text-gray-700 placeholder:text-gray-500 rounded-sm ${this.state.errorslogin && this.state.errorslogin["username"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:background-white focus:drop-shadow-lg bg-gray-50`} placeholder="Username" onChange={this.inputChange} type="username" name="username" id="username"
                        value={this.state.fieldslogin.username} required />
                    </div>
                  </div>



                  <div className="w-full ">
                    <span className="flex">
                      <input className={`w-full p-2 rounded-sm text-gray-700 placeholder:text-gray-500 ${this.state.errorslogin && this.state.errorslogin["password"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:background-white focus:drop-shadow-lg bg-gray-50`} placeholder="Password" onChange={this.inputChange} type="password" name="password" id="password"
                        value={this.state.fieldslogin.password} required />
                    </span>
                  </div>
                  {loading === true ?
                    <button className="w-full white-text px-3 py-2 h-10 flex justify-center items-center space-x-1 uppercase cursor-pointer border-[#d4d3e8] login-button-color rounded-none font-bold text-base" type="submit">
                      <div className=" flex items-center justify-center absolute bg-transparent">
                        <div className="flex items-center justify-center p-6 py-2 bg-transparent w-full">
                          <div className="w-8 h-8 rounded-full animate-spin 
                          border-[5px] border-solid border-[#0E1C64] border-t-transparent"></div>
                        </div>
                      </div>
                    </button>
                    : <button onClick={this.loginSubmit} className="w-full white-text px-3 py-2 flex justify-center items-center space-x-1 uppercase cursor-pointer border-[#d4d3e8] login-button-color rounded-none font-bold text-base" type="submit">
                      <span>Login</span>
                      <FiLogIn className="white-text h-5 w-5" />
                    </button>}

                </div>
              </div>
            </div>
          </div>
        </div > */}
        {/* <div className="bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url("/images/bg.webp")` }} >
          <div className="bg-black/40 flex items-center justify-center min-h-screen px-5 py-5 min-w-screen" >
            <div className="w-[360px] h-[425px] overflow-hidden background-white/80 shadow-[-0px_0px_0px_6px_rgb(120,117,181)] rounded-3xl pt-4 px-5">
              <div className="space-y-6 h-full">
                <div className="flex justify-center items-center">
                  <img src="/images/khelo.png" alt="" />
                </div>
                <div className="w-full">
                  <div className="flex justify-start items-center">
                    <FaUser className="text-[#7875B5] h-9 w-9" />
                    <input className={`py-2.5 px-2 text-xl text-gray-700 placeholder:text-gray-500 ${this.state.errorslogin && this.state.errorslogin["username"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:outline-none bg-transparent`} placeholder="Enter User Id..." onChange={this.inputChange} type="username" name="username" id="username"
                      value={this.state.fieldslogin.username} required />
                  </div>
                </div>
                <div className="w-full ">
                  <span className="flex">
                    <FaLock className="text-[#7875B5] h-9 w-9" />
                    <input className={`py-2.5 px-2 text-xl text-gray-700 placeholder:text-gray-500 ${this.state.errorslogin && this.state.errorslogin["password"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:outline-none bg-transparent`} placeholder="Password" onChange={this.inputChange} type="password" name="password" id="password"
                      value={this.state.fieldslogin.password} required />
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <div>
                    <button onClick={this.loginSubmit} className="w-full text-white px-3 py-2.5 flex justify-between items-center uppercase cursor-pointer border-[#d4d3e8] bg-black font-semibold rounded-[10px] text-base shadow-md shadow-[#5c5696]" type="submit">
                      <span>log in now</span>
                      <HiChevronRight className="text-[#7875B5] h-9 w-9" />
                    </button>
                    <span className="font-bold text-xs text-black">
                      Note - This Website is not for Indian Territory.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </>

    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent, loading } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users,
    loading,
  };
}
export default connect(mapStateToProps)(Login);
