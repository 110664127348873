import React, { useState } from 'react';
import RulesModelBmx from "../../casino_components/RulesModelBmx/RulesModelBmx";
import { IoInformationCircle } from "react-icons/io5";


export default function PageHeaderTheme(props) {
    const { PageTitle, t1, ruleImage } = props;
    const [rulesModal, setRulesModal] = useState(false);

    const handleOpenRules = () => {
        setRulesModal(!rulesModal)
    };

    return (
        <div className='w-full '>
            {rulesModal ?
                <RulesModelBmx ruleImage={ruleImage} PageTitle={PageTitle} setRulesModal={handleOpenRules} />
                : null}
            <div className="detail-header-main  flex justify-between items-center p-[6px]">
                <span className="flex space-x-1 text-white w-full whitespace-nowrap">
                    <span className="uppercase font-bold text-center text-xs lg:text-base">
                        {PageTitle}
                    </span>
                </span>
                <span className="text-white text-xs lg:text-[16px] whitespace-nowrap flex font-bold justify-end items-center space-x-1">
                    <span>
                        Round ID : {1 && t1.mid ? t1.mid : null}
                    </span>
                    <span onClick={() => handleOpenRules()}><IoInformationCircle className='information-button text-[#1e0508] cursor-pointer' size={22} /></span>
                </span>
            </div>

        </div>
    );
}