
import "./AccountStartment.css";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { IoArrowForwardSharp } from "react-icons/io5";

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsTransction: {},
      errors: {},
      AccountType: "",
      userAccountDetails: {},
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      currentPagePl: 1,
      currentPageAccount: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      activeTab: 1,
      domainSettingByDomainName: null,
    }
  }

  componentDidMount() {
    this.getUserAccountDetails();
    this.getDomainSettingByDomainName();
  }

  getDomainSettingByDomainName = () => {
    try {
      let domainSetting = localStorage.getItem('domainSettingByDomainName');
      if (domainSetting) {
        const parsedDomainSetting = JSON.parse(domainSetting);
        this.setState({ domainSettingByDomainName: parsedDomainSetting });
      } else {
        this.setState({ domainSettingByDomainName: null });
      }
    } catch {
      console.error('Error parsing domainSettingByDomainName:');
      this.setState({ domainSettingByDomainName: null });
    }
  };


  SubmitAccountDetailssearch = () => {
    this.getUserAccountDetails();
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  getUserAccountDetails = async () => {
    try {
      let reqData = {
        // "fromDate": moment(this.state.startDate).startOf('day').unix(),
        // "toDate": moment(this.state.endDate).endOf('day').unix(),
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,

        // "AccountType": { filterType }
      }
      this.setState({ isFetch: true })
      let userAccountResponse = await httpPost('user/userStatement', reqData);
      this.setState({ userAccountDetails: userAccountResponse && userAccountResponse.data ? userAccountResponse.data : {}, });
      if (userAccountResponse) {
        // this.setState({ showAlert: true, errorType: userAccountDetails.code, resMessage: userAccountDetails.message, userAccountDetails: userAccountDetails.data });
      } else {
        this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
      }
    }
    catch (error) {
      console.error("Error fetching user account details:", error);
    }

    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePageChangeProfitLoss = (page) => {
    this.setState({ currentPagePl: page });
  };
  handlePageChangeAccount = (page) => {
    this.setState({ currentPageAccount: page });
  };
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    let { userAccountDetails, isFetch, activeTab, domainSettingByDomainName } = this.state;

    const { currentPage, itemsPerPage, currentPagePl, currentPageAccount } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const startIndexPl = (currentPagePl - 1) * itemsPerPage;
    const endIndexPl = startIndexPl + itemsPerPage;
    const startIndexAccount = (currentPageAccount - 1) * itemsPerPage;
    const endIndexAccount = startIndexAccount + itemsPerPage;
    const userAccountDetailsArray = Object.values(userAccountDetails);

    // AccountStatement filter
    let filteredData = userAccountDetailsArray.filter(item => item.statementFor === 'ACCOUNT_STATEMENT');
    let totalAmount = 0;
    filteredData.map((data, key) => {
      totalAmount += data.amount
    })
    let balance = 0;
    let showAmount = 0;

    let finalData = filteredData.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedData = finalData.slice(startIndexAccount, endIndexAccount);
    const totalPages = Math.ceil((finalData && finalData.length) / itemsPerPage);


    // P&L filter
    let filteredDataProfitLoss = userAccountDetailsArray.filter(item => item.statementFor !== 'ACCOUNT_STATEMENT');
    filteredDataProfitLoss.map((data, key) => {
      totalAmount += data.amount
    })

    let finalDataProfitLoss = filteredDataProfitLoss.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedDataProfitLoss = finalDataProfitLoss.slice(startIndexPl, endIndexPl);
    const totalPagesProfitLoss = Math.ceil((finalDataProfitLoss && finalDataProfitLoss.length) / itemsPerPage);

    // AllData filter
    let filteredDataAll = userAccountDetailsArray.filter(item => item.statementFor !== 'ALL');
    filteredDataAll.map((data, key) => {
      totalAmount += data.amount
    })

    let finalDataAll = filteredDataAll.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedDataAll = finalDataAll.slice(startIndex, endIndex);
    const totalPagesAll = Math.ceil((finalDataAll && finalDataAll.length) / itemsPerPage);

    // this.setState({ profitLossData: filteredData, });
    // console.log("profitLossDataprofitLossDataprofitLossData", displayedDataProfitLoss);
    // let domainSettingByDomainName = JSON.parse(localStorage.getItem('domainSettingByDomainName'))

    return (
      <div className={`main-bodyAccount overflow-auto h-screen pb-24 ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[97px] pt-[95px]" : "md:pt-[72px] pt-[70px]"}`}>
        {isFetch === true ?
          <Loader />
          : null}
        <div className="slider-main-divTotal lg:w-[60%] w-full mx-auto">
          <div className='pb-2'>
            <button onClick={() => this.onClickMenu('/app/Dashboard')} className="background-color w-full rounded-none md:py-2 py-1 uppercase font-bold white-text text-[13px] tracking-widest">back to Main Menu</button>
          </div>
          <div className="account-slip white-text text-center md:py-[13px] py-1 text-[13px] font-bold">
            <span>MY ACCOUNT STATEMENT ({userAccountDetailsArray.length ? userAccountDetailsArray.length : 0})</span>
          </div>
          <div className="xl:flex lg:block md:flex block justify-between items-center">
            <div className="flex justify-center items-center md:p-4 p-0 py-2 md:py-0 space-x-2">
              <div className="flex justify-between items-center md:pl-6 pl-0 ">
                <input type="date" className="md:px-4 md:w-full w-28 px-0 py-[13px] text-base background-white  rounded-none display-none  focus:outline-none text-gray-600 placeholder:text-gray-500 "
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate}
                  onChange={this.inputChangeStartDate}
                />
                <div className="background-white py-2.5">
                  <IoArrowForwardSharp size={25} />
                </div>
                <input type="date" className="md:px-4 px-0 py-[13px] text-base background-white  rounded-none  md:w-full w-28 focus:outline-none text-gray-600 placeholder:text-gray-500 "
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
              <div className="flex justify-center items-center md:pt-0 pt-2">
                <button onClick={this.getUserAccountDetails} className="background-color px-3 py-2 rounded-lg font-bold white-text">Submit</button>
              </div>
            </div>
            <div className="grid grid-cols-3 white-text font-bold px-24 md:text-base text-[10px] rounded-sm">
              <span onClick={() => this.handleTabClick(1)} className={`${activeTab === 1 ? "black-background" : "account-statement-button"}  hover:bg-black lg:py-1 py-2 cursor-pointer text-center`}>ALL</span>
              <span onClick={() => this.handleTabClick(2)} className={`${activeTab === 2 ? "black-background" : "account-statement-button"}  hover:bg-black lg:py-1 py-2 cursor-pointer text-center`}>P&L</span>
              {/* <span className="account-statement-button lg:py-1 py-2 text-center>PDC</span> */}
              <span onClick={() => this.handleTabClick(3)} className={`${activeTab === 3 ? "black-background" : "account-statement-button"}  hover:bg-black lg:py-1 py-2 cursor-pointer px-2 text-center`}>Account</span>
            </div>

          </div>
          <hr />

          {activeTab === 1 ? (<>
            <div className='w-full overflow-x-auto scrollbar-hide py-1 text-[13px]'>
              <table className="w-full border-[8px] border-[#E9E9E9]">
                <thead className="rounded-t table-head-color ">
                  <tr className='white-text font-bold divide-x-8 divide-[#E9E9E9]'>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Date</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/2">Description</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">CREDIT</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">DEBIT</th>
                    {/* <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">Comm+</th> */}
                    <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Balance</th>
                  </tr>
                </thead>
                <tbody className=" border-[8px] border-[#E9E9E9] background-white text-[#49494A] font-semibold">


                  {displayedDataAll && displayedDataAll.length > 0 ? displayedDataAll.map((element, index) => (

                    <tr key={index} className="bg-transparent divide-x-8 divide-[#E9E9E9] ">
                      <td className=" border-4 whitespace-nowrap border-[#E9E9E9] py-[13px] w-1/8">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YY")} </td>
                      <td className="truncate border-4 border-[#E9E9E9] py-[13px] text-left w-1/2">{element.remark}</td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">
                        {element && element.amount > 0 ?
                          <div className="text-[#52B5E1]">
                            {element.amount}
                          </div>
                          : 0
                        }
                      </td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element && element.amount < 0 ?
                        <div className="red-text">
                          {Math.abs(element.amount)}
                        </div>
                        : 0
                      }</td>
                      {/* <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">{element.statementFor}</td> */}
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element.balance}</td>
                    </tr>
                  )) : <tr className="bg-transparent black-text w-full">
                    <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] flex justify-start items-center w-full">No Record Found</td>
                  </tr>
                  }



                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPagesAll}
              maxVisibleButtons={1}
              onPageChanged={this.handlePageChange}
            />
          </>) : null}
          {activeTab === 2 ? (<>
            <div className='w-full overflow-x-auto scrollbar-hide py-1 text-[13px]'>
              <table className="w-full border-[8px] border-[#E9E9E9]">
                <thead className="rounded-t table-head-color ">
                  <tr className='white-text font-bold divide-x-8 divide-[#E9E9E9]'>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Date</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/2">Description</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">CREDIT</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">DEBIT</th>
                    {/* <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">Comm+</th> */}
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Balance</th>
                  </tr>
                </thead>
                <tbody className=" border-[8px] border-[#E9E9E9] background-white text-[#49494A] font-semibold">


                  {displayedDataProfitLoss && displayedDataProfitLoss.length > 0 ? displayedDataProfitLoss.map((element, index) => (

                    <tr key={index} className="bg-transparent divide-x-8 divide-[#E9E9E9] ">
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YY")} </td>
                      <td className="truncate text-start border-4 border-[#E9E9E9] py-[13px] w-1/2">{element.remark}</td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">
                        {element && element.amount > 0 ?
                          <div className="text-[#52B5E1]">
                            {element.amount}
                          </div>
                          : 0
                        }
                      </td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element && element.amount < 0 ?
                        <div className="red-text">
                          {Math.abs(element.amount)}
                        </div>
                        : 0
                      }</td>
                      {/* <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">{element.statementFor}</td> */}
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element.balance}</td>
                    </tr>
                  )) : <tr className="bg-transparent black-text w-full">
                    <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] flex justify-start items-center w-full">No Record Found</td>
                  </tr>
                  }



                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPagePl}
              totalPages={totalPagesProfitLoss}
              maxVisibleButtons={1}
              onPageChanged={this.handlePageChangeProfitLoss}
            />
          </>) : null}
          {activeTab === 3 ? (<>
            <div className='w-full overflow-x-auto scrollbar-hide py-1 text-[13px]'>
              <table className="w-full border-[8px] border-[#E9E9E9]">
                <thead className="rounded-t table-head-color ">
                  <tr className='white-text font-bold divide-x-8 divide-[#E9E9E9]'>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Date</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/2">Description</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">CREDIT</th>
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">DEBIT</th>
                    {/* <th className="text-center whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">Comm+</th> */}
                    <th className="text-center uppercase whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">Balance</th>
                  </tr>
                </thead>
                <tbody className=" border-[8px] border-[#E9E9E9] background-white text-[#49494A] font-semibold">


                  {displayedData && displayedData.length > 0 ? displayedData.map((element, index) => (

                    <tr key={index} className="bg-transparent divide-x-8 divide-[#E9E9E9] ">
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YY")} </td>
                      <td className="truncate text-start border-4 border-[#E9E9E9] py-[13px] w-1/2">{element.remark}</td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">
                        {element && element.amount > 0 ?
                          <div className="text-[#52B5E1]">
                            {element.amount}
                          </div>
                          : 0
                        }
                      </td>
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element && element.amount < 0 ?
                        <div className="red-text">
                          {Math.abs(element.amount)}
                        </div>
                        : 0
                      }</td>
                      {/* <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px]">{element.statementFor}</td> */}
                      <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] w-1/8">{element.balance}</td>
                    </tr>
                  )) : <tr className="bg-transparent black-text">
                    <td className="whitespace-nowrap border-4 border-[#E9E9E9] py-[13px] flex justify-start items-center w-full">No Record Found</td>
                  </tr>
                  }



                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPageAccount}
              totalPages={totalPages}
              maxVisibleButtons={1}
              onPageChanged={this.handlePageChangeAccount}
            />
          </>) : null}
          <div className='pt-20'>
            <button onClick={() => this.onClickMenu('/app/Dashboard')} className="background-color w-full rounded-none py-1 uppercase font-bold white-text text-[13px] tracking-widest">back to Main Menu</button>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}
export default connect(mapStateToProps)(AccountStatement);